import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import ButtonOrder from '../Buttons/ButtonOrder';
import ButtonDelete from '../Buttons/ButtonDelete';
import Container from '../Shared/Container';
import ContainerRow from '../Shared/ContainerRow';
import FormSelect from '../Form/FormSelect';
import FormTextField from '../Form/FormTextField';
import JobRecordRowParameters from './JobRecordRowParameters';
import JobRecordRowAttributes from '../Job/JobRecordRowAttributes';

const JobRecordRow = props => {
    const [item, setItem] = useState(props.item)
    const [names, setNames] = useState(props.names || [])
    const [params, setParams] = useState(props.params[props.item.object || ''] || [])
    const [width, setWidth] = useState(0)

    const hasParams = props.node === "source"
    const colsName = 3;

    useEffect(() => {
        setWidth((12 - colsName) / ((props.node === "source" ? 1 : 0) + props.attributes.length))
        setItem(props.item)
        setNames(props.names || [])
        setParams(props.params[props.item.object || ''] || [])
    }, [props])

    return (
        <ContainerRow>
            <Grid>
                <ButtonOrder
                    order={item.column_order}
                    handler={props.handler}
                    name="record_order"
                />
            </Grid>
            <Grid item xs>
                <Container>
                    <Grid item xs={3}>
                        {hasParams ?
                            <FormSelect
                                name='object'
                                label="Record"
                                value={item.object}
                                options={names}
                                disableUnderline
                                handler={props.handler}
                            />
                            :
                            <FormTextField
                                name='object'
                                label="Record"
                                value={item.object || ''}
                                handler={props.handler}
                                header={props.header}
                                disableUnderline
                            />
                        }
                    </Grid>
                    {hasParams &&
                        <Grid item xs={width}>
                            <JobRecordRowParameters
                                width={width}
                                item={item}
                                options={params}
                                handler={props.handler}
                            />
                        </Grid>
                    }
                    {props.attributes && props.attributes.length > 0 &&
                        <Grid item xs={width * props.attributes.length}>
                            <JobRecordRowAttributes
                                attributes={props.attributes}
                                width={12 / props.attributes.length}
                                item={item}
                                handler={props.handler}
                            />
                        </Grid>
                    }
                </Container>
            </Grid >
            <Grid>
                <ButtonDelete small delete={() => props.handler({ target: { name: "delete" } })} />
            </Grid>

            </ContainerRow>
    )
}
export default JobRecordRow;