import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { setSchemas } from '../components/Cache/schemasSlice';

import Grid from '@mui/material/Grid';
import Loading from '../components/Shared/Loading';
import Container from '../components/Shared/Container';
import Header from '../components/Shared/Header';
import Filters from '../components/Shared/Filters';

import RowSchema from '../components/Rows/RowSchema';
import ControlPanel from '../components/Shared/ControlPanel';


const Schemas = () => {
    const schemas = useSelector(state => (state.schemas || {}).value)
    const jobs = useSelector(state => (state.jobs || {}).value)
    const [data, setData] = useState(schemas || []);

    const [system, setSystem] = useState();
    const [filter, setFilter] = useState({});

    // filter
    useEffect(() => {
        handleFilter()
    }, [filter])

    useEffect(() => {
        setData(schemas || [])
    }, [schemas])

    // handlers
    const handleFilter = () => {
        console.log(filter)
        let newData = (schemas || []).filter(x => (
            (filter.search && filter.search.length > 0 ? (x.Name || '').toLowerCase().includes(filter.search.toLowerCase()) : true) &&
            (filter.type !== undefined ? x.SchemaType === filter.type : true) &&
            (filter.system && filter.system.length > 0 ? filter.system.some(system => x.SystemKey === system) : true)
        ))
        setData(newData)
    }
    console.log(schemas)
    return (
        <Container>
            <Header
                type="Schema"
                setData={setSchemas}
                data={data}
            />
            <ControlPanel
                page={"Schemas"}
                filter={filter}
                filterItems={Filters.Schemas.type}
                handler={f => setFilter(f)}
            />

            {/* Results */}
            {schemas === null
                ?
                <Loading />
                :
                <Grid item xs={12}>
                    <Container>
                        {data && data.map((item, key) => {
                            return (
                                <RowSchema
                                    key={key}
                                    row={item}
                                    matched={{
                                        jobs: jobs.filter(x => x.Input.records && x.Input.records.map(y => x.Input.source.system).includes(item.SystemKey) && x.Input.records.find(y => y.object === item.Name))
                                    }}
                                />
                            )
                        })
                        }
                    </Container>
                </Grid>
            }
        </Container>
    )
}

export default Schemas;