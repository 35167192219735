import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setSchemas } from '../components/Cache/schemasSlice';
import { setSnackbar } from '../components/Cache/snackbarSlice';
import { setModal } from '../components/Cache/modalSlice';
import { useUser } from '../components/Auth/AuthContext';
import { API } from "aws-amplify";
import _ from 'lodash';

import Filters from '../components/Shared/Filters';

import { jobIcons, executionStepPayload } from '../components/Shared/Functions';
import ActionButtons from '../components/Buttons/ActionButtons';
import Container from '../components/Shared/Container';
import ExecutionDetails from '../components/Execution/ExecutionDetails';
import Title from '../components/Shared/Title';
import Subtitle from '../components/Shared/Subtitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import FormSelect from '../components/Form/FormSelect';
import SchemaField from '../components/Schema/SchemaField';
import SelectSystem from '../components/Select/SelectSystem';
import Loading from '../components/Shared/Loading';
import ButtonLaunch from '../components/Buttons/ButtonLaunch';


const fieldsToArray = fields => {
    return Object.entries(fields).map(([name, col]) => ({ ...{ name: name }, ...col })).sort((a, b) => a.column_order - b.column_order) || []
}

const arrayToFields = fields => {
    let newFields = {}
    fields.map(x => newFields[x.name] = { ...{ type: x.type, column_order: x.column_order }, ...x.primary_key ? { primary_key: true } : {} })
    return newFields
}

const Execution = () => {
    const createNew = (window.location.pathname.split('/').pop() === "new")
    const { user, setUser } = useUser();
    const params = useParams();
    const dispatch = useDispatch();

    const [data, setData] = useState();

    // Schema-specific
    const first = data && data[0]
    const last = data && data.slice(-1)[0]
    const input = data && executionStepPayload(first)
    const output = data && executionStepPayload(last)
    const systems = data && jobIcons(input.payload)

    // console.log(input, output)

    useEffect(() => {
        user.org && getData()
    }, [user])

    const getData = () => {
        if (createNew) { setData({}); return }
        const id = `arn:aws:states:us-east-1:690904019624:execution:${params.id}`
        API.get("ApiDash", `${user.org}/executiondetails/${id}`).then(
            (response) => {
                setData(response);
            }
        ).catch((error) => {
            dispatch(setSnackbar({ severity: 'error', message: error.message }))
        });
    }

    // console.log(data)

    // Schema-specific 

    return (
        <Container>
            <Grid item xs={12} sx={{ mb: 3 }}>
                <Container>
                    <Title
                        page="Connections"
                        value={input && input.payload.jobName || ''}
                        systems={systems}
                        href={`/jobs/${input && input.payload.jobId}`}
                        disabled
                    />
                    <ButtonLaunch
                        href={`https://us-east-1.console.aws.amazon.com/states/home?region=us-east-1#/executions/details/arn:aws:states:us-east-1:690904019624:execution:${params.id}`}
                        color="neutral"
                        label="AWS"
                    />
                </Container>
            </Grid>
            {data
                ?
                <Grid item xs={12}>
                    <ExecutionDetails
                        data={data}
                        output={output}
                    />
                </Grid>
                : 
                <Loading />
            }

        </Container >
    )
}

export default Execution;