import React, { useState, useEffect } from "react";
import { Grid, TextField } from '@mui/material';
import FormSelect from '../Form/FormSelect';
import FormTextField from '../Form/FormTextField';
import Container from '../Shared/Container';
import ScheduleIcon from '@mui/icons-material/Schedule';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const JobSchedule = props => {
    const [cron, setCron] = useState(props.schedule || ' ')
    const [time, setTime] = useState('00:00')
    const [frequency, setFrequency] = useState('daily')
    const [custom, setCustom] = useState(false)
    
    const opts = [
        { value: 'daily', label: 'Daily', icon: <ScheduleIcon /> },
        { value: 'hourly', label: 'Hourly', icon: <MoreTimeIcon /> },
        { value: 'custom', label: 'Custom', icon: <AutoAwesomeIcon /> }
    ]

    useEffect(() => {
        const [mm, hr, dom, mo, dow, yy] = cron.replace(/[cron()]/g, '').split(' ')
        const hh = hr.split("/")[0]
        const fr = hr.split("/")[1]
        const date = new Date()
        date.setUTCHours(parseInt(hh, 10));
        date.setUTCMinutes(parseInt(mm, 10));

        const newTime = date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0')
        const newFrequency = fr === "1" ? 'hourly' : fr === undefined ? 'daily' : 'custom'
        setTime(newTime)
        setFrequency(newFrequency)
        newFrequency === "custom" && setCustom(true)
    }, [props])

    const handleChange = e => {
        const node = e.target.name
        const t = node=== 'Time' ? e.target.value : time
        const f = node === 'Frequency' ? e.target.value : frequency      
        let newCron
        if (node === "Time" || node === "Frequency") {
            const date = new Date()
            date.setHours(parseInt(t.split(':')[0], 10));
            date.setMinutes(parseInt(t.split(':')[1], 10));
            let mm = date.getUTCMinutes().toString()
            let hh = date.getUTCHours().toString() + ((f === 'hourly') ? '/1' : '')
            newCron = 'cron(' + mm + ' ' + hh + ' * * ? *)'
            setCustom(f == 'custom')
        } else {
            newCron = e.target.value
        }
        setCron(newCron)
        if (node === "Frequency" && e.target.value === "custom") {
            setFrequency('custom')
            console.log(newCron)
        } else {
            props.handler({ target: { name: 'Schedule', value: newCron } })
        }
        
        
    }
    return (
        <Container>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    name='Time'
                    type='time'
                    label='Start Time'
                    variant='standard'
                    value={time}
                    disabled={custom}
                    onChange={handleChange}
                    inputProps={{
                        step: 300, // 5 min
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <FormSelect
                    name='Frequency'
                    label='Frequency'
                    value={frequency}
                    options={opts}
                    handler={handleChange}
                />
                {custom &&
                    <FormTextField
                        name="Cron"
                        value={cron}
                        handler={handleChange}
                    />
                }
            </Grid>
        </Container>
    )
}
export default JobSchedule;