import Grid from '@mui/material/Grid';
import FormSelect from '../Form/FormSelect';
import FormTextField from '../Form/FormTextField';
import SelectQuery from '../Select/SelectQuery';
import Pod from '../Shared/Pod';
import Tooltip from '@mui/material/Tooltip';

const JobRecordRowAttribute = props => {
    const attr = props.attr
    const item = props.item
    const value = attr.type === "sqleditor" ? item.queryId : item[attr.name] || ''

    return (
        <Tooltip title={attr.label}>
            <Grid item xs={props.width} sx={{ '& .MuiBox-root': { width: 1 } }}>
                <Pod
                    icon={attr.icon}
                    launch={attr.launch && attr.launch + value}
                >
                    {
                        attr.type === 'select' &&
                        <FormSelect
                            name={attr.name}
                            value={value}
                            options={attr.options}
                            handler={props.handler}
                            disableUnderline
                        />
                    }
                    {
                        attr.type === 'input' &&
                        <FormTextField
                            name={attr.name}
                            value={item[attr.name] || ''}
                            disableUnderline
                            handler={props.handler}
                        />
                    }
                    {
                        attr.type === 'sqleditor' &&
                        <SelectQuery
                            name="queryId"
                            value={value}
                            handler={props.handler}
                            disableUnderline
                        />
                    }

                </Pod>
            </Grid>
        </Tooltip>
    )
}
export default JobRecordRowAttribute;