import React, { useState, useEffect } from 'react';
import { Button, Grid, InputLabel } from '@mui/material'
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FormTextField from './FormTextField';
import { v4 as uuidv4 } from 'uuid';

const cssButtonRow = { display: 'flex', justifyContent: 'flex-end', '& .MuiButton-root': { ml: 1 } }
const FormApiKeys = props => {
    const [keys, setKeys] = useState(props.keys)
    const [secrets, setSecrets] = useState(props.secrets)
    useEffect(() => {
        console.log(props)
        setKeys(props.keys)
        setSecrets(props.secrets)
    }, [props]);
    const handleApiKeyChange = (e, i) => {
        if (e.target.name === 'value') {
            let newSecrets = { ...secrets }
            // newSecrets.apikeys[Object.keys(newSecrets.apikeys)[i]] = e.target.value
            newSecrets[Object.keys(newSecrets)[i]] = e.target.value
            console.log('handleApiKeyChange value', newSecrets)
            setSecrets(newSecrets)
            // console.log(Object.keys(newSecrets.apikeys)[i])
            props.handler({
                target: {
                    // name: Object.keys(newSecrets.apikeys)[i],
                    name: Object.keys(newSecrets)[i],
                    value: e.target.value
                }
            })
        } else if (e.target.name === 'key') {
            // there is a bug with reduce where if the key is a number
            // like you type '2' into a new api key, the new key-value pair will
            // shift to the top of the list bc reduce computes integers
            let newKeys = Object.entries(keys).map(([k, v], j) => {
                let key = (j === i) ? e.target.value : k
                return ({ [key]: v })
            }).reduce((obj, item) => (obj[Object.keys(item)[0]] = Object.values(item)[0], obj), {})
            console.log('handleApiKeyChange keys', newKeys)
            setKeys(newKeys)
            props.handler({
                target: {
                    name: 'apikeys',
                    value: newKeys
                }
            })
        }
    }
    return (
        <Grid container spacing={2} sx={{ p: 2 }}>
            <InputLabel shrink>{props.label}</InputLabel>
            {Object.entries(keys).map(([k, v], i) => {
                return (
                    <Grid item xs={12} key={i} >
                        <Grid container spacing={2} >
                            <Grid item xs={3}>
                                <FormTextField
                                    name="key"
                                    label="Key"
                                    value={k}
                                    handler={e => handleApiKeyChange(e, i)}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <FormTextField
                                    name="value"
                                    label="Value"
                                    type="password"
                                    // value={secrets.apikeys[k]}
                                    // value={secrets.apikeys[v]}
                                    value={secrets[v]}
                                    handler={e => handleApiKeyChange(e, i)}
                                />
                            </Grid>
                            <Grid item xs={1} sx={{ mt: 2.5, '& .MuiButton-root': { p: 0 } }}>
                                <Button
                                    color='error'
                                    disabled
                                >
                                    <DeleteIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            })}
            <Grid item xs={12} sx={cssButtonRow}>
                <Grid container>
                    <Button
                        variant='outlined'
                        color='neutral'
                        disableElevation
                        startIcon={<AddIcon />}
                        onClick={props.add}
                    >
                        Add Key
                    </Button>
                </Grid>
            </Grid>
            
        </Grid>
    )
}

export default FormApiKeys;