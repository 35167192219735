import { useState, useEffect } from "react";
import { useUser } from '../Auth/AuthContext';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setExecutions } from '../Cache/executionsSlice';
import { API } from "aws-amplify";

import Grid from '@mui/material/Grid';
import Container from '../Shared/Container';
import ContainerRow from '../Shared/ContainerRow';
import ContainerSub from '../Shared/ContainerSub';
import Loading from '../Shared/Loading';
import ExecutionBlock from '../Execution/ExecutionBlock';
import ButtonRefresh from '../Buttons/ButtonRefresh';

import { useTheme } from '@mui/material/styles';
import Subtitle from '../Shared/Subtitle';

const JobExecutions = props => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const params = useParams()
    const { user, setUser } = useUser();

    // const jobId = props.jobId || params.id
    const maxExecutions = 100
    const jobs = useSelector(state => state.jobs.value) || []
    const executions = useSelector(state => state.executions.value) || []
    const [jobId, setJobId] = useState(props.jobId || params.id)
    const [data, setData] = useState(executions.filter(x => x.JobId === jobId))

    useEffect(() => {
        const newJobId = props.jobId || params.id
        setJobId(newJobId)
        setData(executions.filter(x => x.JobId === newJobId))
    }, [props])

    useEffect(() => {
        executions && executions.length > 0 && setData(executions.filter(x => x.JobId === jobId) || [])
    }, [executions])


    const getAllExecutions = () => {
        setData(null)
        API.get('ApiDash', `${user.org}/executions/`, {
            queryStringParameters: { jobId: jobId || '-1' }
        }).then((response) => {
            // add this Job's Executions to cached executions if they aren't theyre already
            // add Name, JobId to values first
            // (they should align but those two fields are missing for some reason that is probably dumb)
            response = response.map(x => ({ ...x, ...{ Name: x.JobName, JobId: jobId } }))
            const newExecutions = [
                ...executions,
                ...response.splice(0, maxExecutions).filter(
                    x => !executions.map(y => y.ExecutionID).includes(x.ExecutionID)
                )
            ].sort((a, b) => { return b.SK - a.SK })

            dispatch(setExecutions(newExecutions))
        }).catch(
            error => {
                console.log("There's an error: ", error)
            }
        )
    }
    return (
        <ContainerSub>
            <Subtitle
                type="Executions"
                refresh
                handler={() => getAllExecutions()}
            />

            <Grid item xs={12} sx={{
                maxHeight: 160,
                ml: 1,
                // overflow: props.row ? 'hidden' : 'auto',
                overflowX: 'hidden',
                overflowY: 'auto',
            }}>
                {data ? data.map((item, key) => {
                    const maxWidth = Math.max(...data.map(x => x.Duration))
                    const adds = (({ Name, Input }) => ({ Name, Input }))((jobs || []).find(x => x.Id === item.JobId) || { Name: item.Name, Input: {} })
                    const row = { ...item, ...adds }
                    return (
                        <ContainerRow subsection key={key}>
                            <Grid item xs={12} 
                            // sx={{'& .MuiGrid-container': { my: 'auto'}}}
                            >
                                <ExecutionBlock
                                    date
                                    name={row.Name}
                                    duration={row.Duration * 1000}
                                    input={row.Input}
                                    start={row.StartTime}
                                    status={row.Status === 'Succeeded' ? 'success' : 'error'}
                                    width={row.Duration / maxWidth}
                                    dtBuffer={2}
                                    href={`/executions/${row.StateMachine}:${row.ExecutionID}`}
                                />
                            </Grid>
                        </ContainerRow>
                    )
                })
                    :
                    <Loading />
                }
            </Grid>
        </ContainerSub>
    )
}
export default JobExecutions;