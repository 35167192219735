import Badge from '@mui/material/Badge';
import Icon from './Icon';
import { useTheme } from '@mui/material/styles';
import { iconType } from '../Shared/Functions';

const IconSystem = props => {
    const theme = useTheme();
    const isNum = typeof (props.type) === "number"
    const type = iconType(props.page, props.type)
    const color = theme.palette[type.color || "neutral"].main
    const size = props.size || {
        type: 'small',
        system: 'medium'
    }
    const anchorOrigin = {
        vertical: isNum ? "top" : "bottom",
        horizontal: "right"
    }

    const badgeContent = isNum ? props.type : <Icon icon={type.icon} size={size.type} color={type.color} />
    const sx = {
        mr: 1,
        '& .MuiAvatar-root': isNum ? {} : {
            // border: `1px solid ${color}`,
            borderRadius: '2rem',
        },
        '& .MuiBadge-badge': isNum ? {} : {
            p: 0,
            background: theme.palette[type.color || "neutral"].main,
            maxHeight: 12,
            minWidth: 12,
            background: 'red',
            '& .MuiAvatar-root': {
                border: 'none'
            },
        }
    }
    return (
        <Badge
            overlap="circular"
            anchorOrigin={anchorOrigin}
            color="primary"
            badgeContent={badgeContent}
            sx={sx}
        >
            <Icon system={props.system} icon={props.icon} size={size.system} />
        </Badge >
    )
}

export default IconSystem