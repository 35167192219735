import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setQueries } from '../Cache/queriesSlice';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import ActionButtons from "../Buttons/ActionButtons";
import ButtonNew from "../Buttons/ButtonNew";
import ButtonLaunch from "../Buttons/ButtonLaunch";
import FormSelect from '../Form/FormSelect';
import Container from '../Shared/Container';
import JoinLeftIcon from '@mui/icons-material/JoinLeft';
import { useTheme } from '@mui/material/styles';


const SelectQuery = props => {
    const theme = useTheme();
    const data = useSelector(state => state.queries.value);
    const [value, setValue] = useState(props.value);

    const matched = props.limit ?
        Object.entries(props.limit).map(([k, v]) => data.filter(y => y[k] === v)).flat() :
        data || []
    const options = matched.map(x => ({
        value: x.Id,
        connection: x.connection,
        label: x.Name,
        // icon: <JoinLeftIcon />,
        link: 'queries'
    }))

    useEffect(() => {
        setValue(props.value)
    }, [props])
    const sx = {
        my: 0.5,
        color: theme.palette.primary.main,
        background: theme.palette.offwhite.main,
        borderRadius: '1rem',
        overflow: 'auto',
        width: '100%',
        '& .MuiGrid-root': {pt: 0}
    }
    return (
        <FormSelect
                        name={props.name || "query"}
                        options={options}
                        value={value || ''}
                        handler={props.handler}
                        disableUnderline={props.disableUnderline}
                    />
    )
    return (
        <Container>
            {/* <Grid item xs={12} sx={{ height: 8, mt: 1 }}><InputLabel shrink sx={{ ml: -1.5, mt: 0.75 }}>Query</InputLabel> </Grid> */}
            {/* <Container sx={sx}> */}
            {/* <Container> */}
                {/* <Grid item xs={8}> */}
                    <FormSelect
                        name={props.name || "query"}
                        options={options}
                        value={value || ''}
                        handler={props.handler}
                        disableUnderline={props.disableUnderline}
                    />
                {/* </Grid> */}
                {/* <Grid item xs sx={{justifyContent: "flex-end", pt: 0}}>
                    <ButtonNew small mini href={'/queries/new'} />
                    {value && <ButtonLaunch small mini href={`/queries/${value}`} />}
                </Grid> */}
            {/* </Container> */}
        </Container>
    )
}

export default SelectQuery;