import { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import ButtonNew from '../Buttons/ButtonNew';
import JobRecordRowParameter from './JobRecordRowParameter';
import Container from '../Shared/Container';

const JobRecordRowParameters = props => {
    const [item, setItem] = useState(props.item)
    const [options, setOptions] = useState(props.options)

    useEffect(() => {
        setItem(props.item)
        setOptions(props.options)
    }, [props])

    const handleParamChange = (e, key) => {
        console.log(item)
        const newItem = { ...item }
        if (e.target.name === "add") {
            newItem.params = [...newItem.params || [], ...[{ field: "", operator: "", value: "" }]]
        } else if (e.target.name === "delete") {
            const newParams = [...newItem.params]
            newParams.splice(key, 1)
            newItem.params = newParams
        } else {
            const newParams = [...newItem.params]
            newParams[key] = {
                ...newParams[key] || {},
                ...{ [e.target.name]: e.target.value }
            }
            newItem.params = newParams
        }
        props.handler({ target: { name: "params", value: newItem.params.length > 0 && newItem.params } })
    }

    return (
        <Container
            sx={{
                '& .MuiGrid-root': { py: 0, my: 'auto' }
            }}
        >
            <Tooltip title={"Parameters"}>
                <Grid item>
                    <ButtonNew small mini
                        color="primary"
                        handler={() => handleParamChange({ target: { name: "add" } })}
                    />
                </Grid>
            </Tooltip>
            <Grid item xs>
                {item.params && item.params.map((param, key) => {
                    return (
                        param &&
                        <JobRecordRowParameter
                            key={key}
                            {...item}
                            {...param}
                            params={options}
                            handler={e => handleParamChange(e, key)}
                        />
                    )
                })}
            </Grid>
        </Container>
    )
}
export default JobRecordRowParameters;