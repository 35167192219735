import { createSlice } from '@reduxjs/toolkit'


const getTables = row => {
  return [...new Set((row.Config || []).map(x => [
    ...(x.query || '').matchAll(/(?:(?:from|join)(?:\s|\()+((?:\[*\w*\.\w*)+)(?:\s|\)|$))/gi)
  ].map(x => x[1].toLowerCase().split('.').slice(-2).join('.'))).flat())].sort()
}

const addTables = data => {
  if (data === null) { return null }
  return (data || []).map((x, i) => ({
    ...x,
    ...{
      Config: (x.Config || []).map(y => ({
        ...y,
        ...{ tables: getTables(data[i]) }
      }))
    }
  }))
}

export const slice = createSlice({
  name: 'jobs',
  initialState: {
    value: [],
  },
  reducers: {
    setJobs: (state, data) => {
      // state.value = addTables(data.payload)
      state.value = data.payload
      state.lastUpdated = new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString()
    },
  },
})

export const { setJobs } = slice.actions
export default slice.reducer