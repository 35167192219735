import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';

const ButtonJobs = props => (
    <Button
        onClick={props.handler}
        size="small"
        disabled={props.disabled}
        sx={{ textAlign: 'left' }}
    >
        <WorkHistoryOutlinedIcon />
        <Typography sx={{ minWidth: '24px', textAlign: 'center', fontSize: '0.825rem', fontWeight: 500 }}>{props.value}</Typography>
    </Button>
)

export default ButtonJobs