import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../components/Auth/AuthContext';
import { useSelector, useDispatch } from 'react-redux'
import { setConnections } from '../components/Cache/connectionsSlice';
import { setSnackbar } from '../components/Cache/snackbarSlice'
import { setModal } from '../components/Cache/modalSlice';
import { API } from 'aws-amplify';
import _ from 'lodash';
import { updateCache } from '../components/Shared/Functions';

import { SystemConfig } from '../components/Config/SystemConfig';

import { Grid, LinearProgress } from '@mui/material';
import Container from '../components/Shared/Container';
import Title from '../components/Shared/Title';

import FormTextField from '../components/Form/FormTextField';
import FormApiKeys from '../components/Form/FormApiKeys';
import SelectSystem from '../components/Select/SelectSystem';
import ActionButtons from '../components/Buttons/ActionButtons';
import SelectConnectionType from '../components/Select/SelectConnectionType';

import { GetSecureValue } from '../components/Auth/GetSecureValue'
import { PutSecureValue } from '../components/Auth/PutSecureValue'
import { DeleteSecureValue } from '../components/Auth/DeleteSecureValue'
import { v4 as uuidv4 } from 'uuid';

const Connection = () => {
    const createNew = (window.location.pathname.split('/').pop() === "new")
    const { user, setUser } = useUser();
    const params = useParams();
    const dispatch = useDispatch()

    const connections = useSelector(state => state.connections.value) || []
    const cached = connections.find(x => x.Id === params.id) || {}
    const [data, setData] = useState();

    // Connection-specific
    const [credentials, setCredentials] = useState();
    const [secrets, setSecrets] = useState({});

    useEffect(() => {
        user.org && getData()
    }, [user])

    const getData = () => {
        if (createNew) { setData({}); return }
        API.get("ApiDash", `${user.org}/connections/${params.id}`).then(
            (response) => {
                setData(response);

                // update cache if API response is different
                (_.difference(_.values(response), _.values(cached)) || []).length > 0 && updateCache(dispatch, setConnections, [...connections], response, params.id);
            }
        ).catch((error) => {
            dispatch(setSnackbar({ severity: 'error', message: error.message }))
        });
    }

    const handleChange = (e, i) => {
        const newConn = { ...data }
        let value = e.target.value

        if (e.target.name === "SystemKey") {
            newConn.SystemType = null
        }

        // credential change
        if (credentials && credentials.map(x => x.name).includes(e.target.name)) {
            // console.log(credentials)
            // update secret and set value to existing value or generate uuid if new connection
            if (Object.keys(secrets).includes(e.target.name)) {
                // console.log(e.target.name, e.target.value)
                setSecrets({ ...secrets, ...{ [e.target.name]: e.target.value } })
                value = (newConn.Credentials || {})[e.target.name] || makeUuid()
            }
            newConn.Credentials = {
                ...(newConn.Credentials || {}),
                ...{ [e.target.name]: value }
            }
            // non-credential change (Name, Key, Type)
        } else {
            newConn[e.target.name] = e.target.value
        }
        console.log(secrets)

        // standard connection values
        newConn.Type = 'connection'
        setData(newConn)
    }

    const handleDelete = () => {
        dispatch(setModal({
            open: true,
            content: [
                { variant: 'body1', text: `Are you sure you want to delete ${data.Name} ?` }
            ],
            buttons: [
                { label: 'Delete', color: 'error', onClick: () => deleteItem() }
            ]
        }))
    }

    const saveItem = async () => {
        const { PK, SK, LastModified, ...body } = data
        const urlEnd = createNew ? "" : `/${params.id}`
        // console.log(body)
        // return
        dispatch(setSnackbar({ severity: 'info', message: 'Saving Connection...' }))
        API.put(
            'ApiDash',
            `/${user.org}/connections${urlEnd}`,
            { body: body }
        ).then(async response => {
            // save credentials directly to Parameter Store
            dispatch(setSnackbar({ severity: 'info', message: 'Saving Credentials...' }))
            for await (const s of getSecures()) {
                await PutSecureValue(Object.keys(s)[0], Object.values(s)[0] || ' ')
            }

            dispatch(setSnackbar({ severity: 'success', message: 'Changes Saved!' }))
            updateCache(dispatch, setConnections, [...connections], response, response.Id)
            if (createNew) {
                setTimeout(function () {
                    window.location.href = `/connections/${response.Id}`
                }, 1000);
            }
            else {
                setData(response);
            }
            dispatch(setSnackbar({ severity: 'success', message: 'Connection Saved!' }))
        }).catch(error => {
            dispatch(setSnackbar({ severity: 'error', message: error.message }))
        })
    }

    const deleteItem = async () => {
        dispatch(setSnackbar({ severity: 'info', message: 'Deleting Credentials...' }))
        for await (const x of Object.keys(getSecures())) {
            await DeleteSecureValue(x)
        }
        dispatch(setSnackbar({ severity: 'info', message: 'Deleting Connection...' }))
        API.del("ApiDash", `${user.org}/connections/${params.id}`).then(
            (response) => {
                dispatch(setConnections(connections.filter(x => x.Id !== params.id)))
                dispatch(setSnackbar({ severity: 'success', message: 'Connection Deleted!' }))
                dispatch(setModal(null))
                setTimeout(function () {
                    window.location.href = '/connections'
                }, 1000);
            }
        ).catch((error) => {
            dispatch(setSnackbar({ severity: 'error', message: error.message }))
        });
    }

    
    // Connections-specific

    useEffect(() => {
        makeCredentials(data);
    }, [data && data.SystemType]);

    const getSecrets = async (key, value) => {
        let newSecrets = {}
        if (key === 'apikeys') {
            for await (const [k, v] of Object.entries(value)) {
                newSecrets[v] = await GetSecureValue(v)
            }
            return newSecrets
        } else {
            for await (const [k, v] of Object.entries({ [key]: value })) {
                newSecrets[k] = await GetSecureValue(v)
            }
            return newSecrets
        }
    }

    const getSecures = () => {
        return Object.entries(secrets).map(([k, v]) => ({ [data.Credentials[k]]: v }))
    }

    const makeCredentials = async () => {
        if (data === undefined) { return }
        else if (data.SystemType === '') {
            setCredentials([])
            setData({ ...data, ...{ Credentials: {} } })
        } else {
            const creds = (data.SystemKey && Object.keys(SystemConfig[data.SystemKey].types).includes(data.SystemType)) ?
                Object.values(SystemConfig[data.SystemKey].types[data.SystemType].connection).map(x => ({
                    name: x.inputconfig.name,
                    label: x.inputconfig.label,
                    type: x.type
                }))
                :
                []
            let newSecrets = creds.filter(x => ['password', 'apikeys'].includes(x.type)).map(x => x.name).reduce((a, b) => (a[b] = '', a), {})
            if (!createNew) {
                for (let i in creds) {
                    creds[i].value = data.Credentials[creds[i].name] || ''
                    if (['password', 'apikeys'].includes(creds[i].type)) {
                        creds[i].value = (createNew || creds[i].value === "") ? makeUuid() : creds[i].value
                        newSecrets = {
                            ...newSecrets,
                            ...await getSecrets(creds[i].name, creds[i].value)
                        }
                    }
                }
            }
            const newData = {...data, ...{Credentials: creds.reduce((a, b)=> {a[b.name]=b.value; return a}, {})}}
            setSecrets(newSecrets)
            setCredentials(creds)
            setData(newData)
        }
    }

    const makeUuid = () => {
        return `/${user.org}/${data.SystemKey}/${data.SystemType}/${uuidv4()}`
    }

    const handleAddApiKey = () => {
        const newUuid = makeUuid()
        setSecrets({ ...secrets, ...{ [newUuid]: '' } })

        let newCredentials = [...credentials]
        let apikeys = newCredentials.splice(newCredentials.findIndex(x => x.name === "apikeys"), 1)
        apikeys[0].value = { ...apikeys[0].value, ...{ '': newUuid } }
        setCredentials([...newCredentials, ...apikeys])
    }

    return data ?
        <Container>
            <Title
                page="Connections"
                value={data.Name}
                system={data.SystemKey}
                type={data.SystemType}
                handler={handleChange}
            />
            <ActionButtons
                createNew={createNew}
                delete={handleDelete}
                save={saveItem}
            />

            <Grid item xs={12}>
                <SelectSystem
                    name="SystemKey"
                    value={data.SystemKey || ''}
                    handler={handleChange}
                />
            </Grid>

            <Grid item xs={12}>
                <SelectConnectionType
                    name="SystemType"
                    label="Connection Type"
                    system={data.SystemKey || ''}
                    value={data.SystemType || ''}
                    handler={handleChange}
                />
            </Grid>


            {/* Credentials */}
            {credentials && credentials.length > 0 ?
                credentials.map((field, i) => {
                    return (
                        <Grid item xs={12} key={i}>
                            {field.type === 'apikeys' ?
                                <FormApiKeys
                                    {...field}
                                    label="API Keys"
                                    keys={field.value}
                                    secrets={secrets}
                                    handler={(e) => handleChange(e, i)}
                                    add={handleAddApiKey}
                                /> :
                                <FormTextField
                                    {...field}
                                    value={field.type === 'password' ? secrets[field.name] : field.value}
                                    handler={(e) => handleChange(e, i)}
                                />
                            }
                        </Grid>
                    )
                })
                :
                createNew ? null : <Grid item xs={12}><LinearProgress /></Grid>
            }

        </Container >
        :
        <Grid item xs={12}><LinearProgress /></Grid>
}

export default Connection;