import { useState, useEffect } from "react";
import ContainerRow from '../Shared/ContainerRow';
import {
    Grid, Link
} from '@mui/material';
import IconSystem from '../Shared/IconSystem';

const ReportRow = props => {
    const [row, setRow] = useState(props.row)

    useEffect(() => {
        setRow(props.row)
    }, [props])

    const handleRowChange = e => {
        const value = (e.target.name === 'primary_key') ? e.target.checked : e.target.value
        const newRow = { ...row, ...{ [e.target.name]: value } }
        setRow(newRow)
        props.handler(newRow)
    }
    console.log(props)
    return (
        <ContainerRow>
            <Grid item xs={6}>
                <IconSystem
                    system={row.SystemKey}
                    type={row.SystemType}
                    page="Connections"
                />
                <Link href={`/reports/${row.id}`} underline="hover" target="_blank">
                    {row.Name || "_"}
                </Link>
            </Grid>
            <Grid item xs={3}>
                
            </Grid>
            <Grid item xs={3} sx={{ justifyContent: 'flex-end'}}>
                {/* <ButtonJobs
                    disabled={(row.matchJobs.length === 0)}
                    value={row.matchJobs.length}
                />
                <ButtonRows
                    disabled={(Object.keys(row.Fields).length === 0)}
                    value={Object.keys(row.Fields).length}
                /> */}
            </Grid>

        </ContainerRow>
    )
}

export default ReportRow