import Grid from '@mui/material/Grid';
import Container from '../components/Shared/Container';
import logo from '../images/logo-min.svg';

const Error404 = () => (
    <Container>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
            <img src={logo} alt="dash" style={{ maxHeight: '200px', margin: 'auto' }} />
        </Grid>
        <Grid item xs={12} sx={{textAlign: 'center'}}>
            <h1>404 oopsie</h1>
        </Grid>
    </Container>
)
export default Error404;