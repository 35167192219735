import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { setModal } from '../Cache/modalSlice'
import { Box, Grid, Modal, Typography, Button, Link, Divider } from '@mui/material';
import JobEnabled from '../Job/JobEnabled';
import Icon from '../Shared/Icon';
import IconSystem from '../Shared/IconSystem';
import ContainerRow from '../Shared/ContainerRow';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: '8px',
    boxShadow: 2,
    p: 4,
    maxHeight: '400px',
    overflow: 'auto'
}

const DashModal = props => {
    const dispatch = useDispatch();
    const modal = useSelector(state => state.modal.value) || {};

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setModal(null))
    }

    return (
        <Modal
            open={modal.open || false}
            onClose={handleClose}
            aria-labelledby="detail"
            aria-describedby="detail"
        >
            <Box
                sx={modalStyle}
            >
                {modal.content && modal.content.map((item, i) => (
                    <Typography variant={item.variant} key={i} sx={item.sx}>{item.text}</Typography>
                ))}
                {modal.buttons && modal.buttons.map((btn, i) => (
                    <Button
                        key={i}
                        variant='outlined'
                        color={btn.color || 'neutral'}
                        disableElevation
                        sx={{ mt: 2 }}
                        onClick={btn.onClick}
                        href={btn.href || '#'}
                    >
                        {btn.label}
                    </Button>
                ))}
                {modal.matched && <Divider variant='fullWidth' />}
                {modal.matched &&
                    <Box sx={{ mt: 3 }}>
                        {modal.matched.tables && modal.matched.tables.map((item, key) => (
                            <ContainerRow key={key} nomargin>
                                <Grid item xs>
                                    <Icon system={item.split('.')[0]} size={"medium"} />
                                    <Typography sx={{ml: 1}}>{item}</Typography>
                                </Grid>
                            </ContainerRow>
                        ))}
                        {modal.matched.jobs && modal.matched.jobs.map((item, key) => (
                            <ContainerRow key={key} nomargin>
                                <Grid item xs>
                                    <IconSystem
                                        system={item.Input.source.system}
                                        type={item.Input.source.type}
                                        page="Connections"
                                    />
                                    <IconSystem
                                        system={item.Input.target.system}
                                        type={item.Input.target.type}
                                        page="Connections"
                                    />
                                    <Link href={`/jobs/${item.Id}`} underline="hover" target="_blank">
                                        {item.Name || "_"}
                                    </Link>
                                </Grid>
                                {modal.enable && <Grid item xs={1}>
                                    <JobEnabled
                                        cols={1}
                                        status={item.Status}
                                        handler={e => modal.enable(e, item.Id)}
                                        hideLabel
                                    />
                                </Grid>
                                }
                            </ContainerRow>
                        ))}
                    </Box>
                }
            </Box>
        </Modal>
    )
}
export default DashModal;