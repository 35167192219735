import Typography from '@mui/material/Typography';
import Container from '../Shared/Container';
import Icon from '../Shared/Icon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Time from '../Shared/Time';
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';

const ExecutionBlock = props => {
    const theme = useTheme();
    const color = theme.palette[props.status || "neutral"].main

    const system = (
        props.input.name ? props.input.name.split('-')[1] :
            props.input.resource ? props.input.resource.split(':')[2] :
                undefined
    )
    const icon = system ? undefined : (
        props.status === 'success' ? <CheckCircleIcon /> : <CancelIcon />
    )

    const dtBuffer = props.dtBuffer || 2;
    return (
        <Container
            sx={{
                my: 'auto',
                '& .MuiGrid-item': { p: 0 },
                '& .MuiGrid-container': { my: 'auto'},
                '& .MuiTypography-root': {
                    fontSize: '0.875rem',
                    lineHeight: '0.875rem',
                },
            }}
        >
            <Grid item xs={dtBuffer}>
                <Container>
                    <Time date={props.date} start={props.start} cron={props.schedule} />
                </Container>
            </Grid>
            <Grid item xs={12 - dtBuffer - 1}>
                <Container>
                    <Grid
                        item
                        sx={{
                            background: color,
                            color: 'white',
                            minWidth: props.minWidth || 36,
                            height: props.buffer ? '1rem' : '2rem',
                            display: 'inline-flex',
                            borderRadius: '3rem',
                            alignItems: 'center',
                            alignContent: 'center',
                            justifyContent: 'flex-end',
                            cursor: props.href ? 'pointer' : 'auto',
                            width: props.width,
                            ml: (props.buffer * 100) + '%'
                        }}
                        onClick={() => { props.href && window.open(props.href, '_blank', 'noopener,noreferrer') }}
                    >
                        <Icon icon={icon} system={system} color={props.status} size={props.buffer ? "medium" : "large"} />
                    </Grid>
                    <Grid
                        sx={{ mr: -100, ml: 0.25, my: 'auto' }}
                    >
                        <Typography color={color}>{new Date(props.duration).toISOString().slice(14, 19)}</Typography>
                        {props.row_count ? <Typography> {props.row_count} rows</Typography> : null}
                    </Grid>
                </Container>
            </Grid>

            {props.error && <Typography sx={{ color: theme.palette.error.main, fontSize: '0.75rem' }}>{props.error}</Typography>}
        </Container>
    )
}
export default ExecutionBlock;