import React, { useState, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import { Avatar, AvatarGroup, Grid, TextField, Link } from '@mui/material';
import { SystemConfig } from '../Config/SystemConfig';
import Filters from '../Shared/Filters';
import IconSystem from '../Shared/IconSystem';
import logo from '../../images/logo-avatar.svg';
import FormTextField from '../Form/FormTextField';
import Container from '../Shared/Container';

const Title = props => {
    const hasType = () => (Filters[props.page].type ? Filters[props.page].type.find(x => x.value === props.type) || {} : {})
    const [type, setType] = useState(hasType())
    const [title, setTitle] = useState(props.value)
    const theme = useTheme();

    useEffect(() => {
        setType(hasType())
        setTitle(props.value)
    }, [props])

    const handleTitleChange = e => {
        setTitle(e.target.value)
        props.handler(e)
    }

    let css = {
        // display: 'flex',
        // pt: 4,
        '& input, & a': {
            fontSize: '1.5rem',
            fontWeight: props.href ? 'normal' : 'bold'
        },
        // '& .MuiGrid-item': {m: 'auto', border: '1px solid aqua'},
        '& .MuiAvatar-root': { background: 'white' },
        '& .MuiAvatar-root.icon': { borderColor: theme.palette[type.color || 'white'].main },
        '& .MuiInput-underline:before': { borderBottomColor: 'transparent' },
        '& .MuiInput-underline:after': { borderBottomColor: 'gray' },
    }

    return (
        <Grid item xs sx={css}>
            <Container>
                <Grid item>
                    {props.system &&
                        <IconSystem
                            system={props.system}
                            type={props.type}
                            page={props.page}
                            size={props.size || { system: 'large', type: 'medium' }}
                        />
                    }
                    {props.systems && props.systems.map((item, key) => {
                        return (
                            <IconSystem
                                key={key}
                                system={item.system}
                                type={item.type}
                                page={props.page}
                                size={props.size || { system: 'large', type: 'medium' }}
                            />
                        )
                    })}
                </Grid>
                <Grid item xs>
                    {props.href ?
                        <Link href={props.href} underline="hover" target="_blank">
                            {title}
                        </Link>
                        :
                        <FormTextField
                            value={title}
                            name="Name"
                            placeholder="Name"
                            handler={handleTitleChange}
                            disabled={props.disabled}
                        />
                    }
                </Grid>
            </Container>

        </Grid>
    )
}
export default Title;