import React, { useState, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import { FormGroup, FormControlLabel, Grid, InputLabel, Switch } from '@mui/material';

const JobEnabled = props => {
    const [checked, setChecked] = useState(props.status === 'ENABLED' || false);
    const theme = useTheme();

    const handleChange = e => {
        setChecked(e.target.checked)
        let value = e.target.checked ? 'ENABLED' : 'DISABLED'
        props.handler({ target: { name: 'Status', value: value } })
    }
    useEffect(() => {
        setChecked(props.status === 'ENABLED' || false);
    }, [props])

    const css = {
        display: 'flex',
        '& .MuiFormControlLabel-label': {
            color: theme.palette[checked ? 'success' : 'neutral'].main
        }
    }
    return (
        <Grid item xs={props.cols}>
            {!props.hideLabel && <InputLabel shrink >
                Status
            </InputLabel>
            }
            <Switch
                color="success"
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
        </Grid>
    )
}
export default JobEnabled;