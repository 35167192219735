import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import pluralize from 'pluralize';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const Since = props => {
    dayjs.extend(relativeTime);
    pluralize.addIrregularRule('schema', 'schemas');

    const theme = useTheme();

    const type = pluralize(props.type);
    const lastUpdated = useSelector(state => (state[type] || {}).lastUpdated);
    const [since, setSince] = useState(dayjs(lastUpdated).fromNow());
    const seconds = 60;

    useEffect(() => {
        const interval = setInterval(() => {
            setSince(dayjs(lastUpdated).fromNow())
        }, seconds * 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(()=>{
        setSince(dayjs(lastUpdated).fromNow())
    }, [lastUpdated])

    const color = lastUpdated && (dayjs().diff(lastUpdated, 'hour'))
    
    return (
        <Typography sx={{ fontSize: '0.75rem', color: theme.palette[color > 24 ? "error" : color > 12 ? "secondary" : "neutral"].main }}>Last Updated {since}</Typography>
    )
}
export default Since;