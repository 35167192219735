import React, { useState, useEffect } from 'react';
import { IconButton, InputAdornment, TextField, LinearProgress, Grid } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SearchIcon from '@mui/icons-material/Search';

const FormTextField = props => {
    const [showPassword, setShowPassword] = useState(false)
    const [value, setValue] = useState(props.value)
    const [error, setError] = useState(props.required && !props.value)

    useEffect(() => {
        setValue(props.value)
    }, [props.value]);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }
    const handleChange = e => {
        if (e.target.required && e.target.value) {
            setError(false)
        }
        setValue(e.target.value)
        props.handler(e)
    }
    const eye = props.type === "password" ? {
        endAdornment:
            <InputAdornment position="end">
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
            </InputAdornment>
    } : {}
    const search = props.header ? {
        startAdornment:
            <InputAdornment position="start">
                <SearchIcon />
            </InputAdornment>
    } : {}
    const underline = props.disableUnderline ? {
        disableUnderline: true
    } : {}

    return (
        // props.value === undefined ? <Grid item xs={12}><LinearProgress /></Grid> : 
        <TextField
            fullWidth
            variant='standard'
            key={props.name}
            name={props.name}
            placeholder={props.name}
            label={props.label}
            disabled={props.disabled}
            value={value || ''}
            type={props.type === 'password' && !showPassword ? "password" : (props.header ? "search" : "text")}
            // InputProps={props.type === 'password' ? eye : ( props.header ? search : null)}
            InputProps={{...eye, ...search, ...underline}}
            InputLabelProps={ props.header ? { shrink: true } : null}
            required={props.required}
            error={error}
            onChange={handleChange}
        />
    )
}
export default FormTextField;