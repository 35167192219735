import { useDispatch } from 'react-redux';
import {
    Grid, Typography
} from '@mui/material';
import Container from '../Shared/Container';
import Since from '../Shared/Since';
import ButtonRefresh from '../Buttons/ButtonRefresh';
import ButtonNew from '../Buttons/ButtonNew';
import pluralize from 'pluralize';

const Header = props => {
    pluralize.addIrregularRule('schema', 'schemas')
    const dispatch = useDispatch()
    const css = {
        display: 'flex',
        textAlign: 'right',
        alignItems: 'center',
        '& .MuiButton-root': { ml: 1 }
    }
    return (
        <Container>
            <Grid item xs sx={{ml: 2}}>
                <Typography variant="h6">{props.type} Count ({props.data.length})</Typography>
                <Since type={pluralize.singular(props.type.toLowerCase())} />
            </Grid>
            <Grid item sx={css}>
                {!props.hideNew &&
                    <ButtonNew
                        type={props.type}
                        href={`${pluralize(props.type.toLowerCase())}/new`}
                        variant="outlined"
                    />
                }
                <ButtonRefresh
                    setData={() => dispatch(props.setData(null))}
                    variant="outlined"
                />
            </Grid>
        </Container>
    )
}
export default Header