import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ButtonLaunch from '../Buttons/ButtonLaunch';
import ButtonRefresh from '../Buttons/ButtonRefresh';
import ButtonDelete from '../Buttons/ButtonDelete';
import Icon from './Icon';

const Pod = props => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: 'inline-flex',
                background: theme.palette.offwhite.main,
                borderRadius: '1rem',
                alignItems: 'right',
                height: '2rem',
                overflow: 'hidden',
                width: 1,
                my: 0.5,
                pl: 0.5,
            }}
            onClick={props.handler}
        >
            <Grid>
                <Icon icon={props.icon} system={props.system} />
            </Grid>
            <Grid item xs sx={{ px: 0.5, overflow: 'hidden' }}>
                {props.children}
            </Grid>
            <Grid>
                {props.launch && <ButtonLaunch small mini href={props.launch} />}
                {props.refresh && <ButtonRefresh small mini setData={props.setData} />}
                {props.delete && <ButtonDelete small mini delete={props.delete} />}
            </Grid>
        </Box>
    )
}
export default Pod;