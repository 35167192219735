import connection from './connectionConfig';
import acceleratedreadericon from '../../images/systems/acceleratedreader.png';
import apiicon from '../../images/systems/api.png';
import boxicon from '../../images/systems/box.png';
import brivoicon from '../../images/systems/brivo.png';
import canvasicon from '../../images/systems/canvas.png';
import clevericon from '../../images/systems/clever.png';
import deanslisticon from '../../images/systems/deanslist.png';
import dialpadicon from '../../images/systems/dialpad.png';
import edulasticicon from '../../images/systems/edulastic.png';
import edgenuityicon from '../../images/systems/edgenuity.png';
import ellevationicon from '../../images/systems/ellevation.png';
import googleicon from '../../images/systems/google.png';
import googlesheetsicon from '../../images/systems/googlesheets.png';
import greatmindsicon from '../../images/systems/greatminds.png';
import guardianconducticon from '../../images/systems/guardianconduct.png';
import illuminateicon from '../../images/systems/illuminate.png';
import ireadyicon from '../../images/systems/iready.png';
import jazzhricon from '../../images/systems/jazzhr.png';
import lexiaicon from '../../images/systems/lexia.png';
import microsofticon from '../../images/systems/microsoft.png';
import mosyleicon from '../../images/systems/mosyle.png';
import nweamapicon from '../../images/systems/nweamap.png';
import oktaicon from '../../images/systems/okta.png';
import osseicon from '../../images/systems/osse.png';
import pandadocicon from '../../images/systems/pandadoc.png';
import postgresqlicon from '../../images/systems/postgresql.png';
import powerschoolicon from '../../images/systems/powerschool.png';
import qualtricsicon from '../../images/systems/qualtrics.png';
import quickbaseicon from '../../images/systems/quickbase.png';
import rgricon from '../../images/systems/rgr.png';
import s3icon from '../../images/systems/s3.png';
import salesforceicon from '../../images/systems/salesforce.png';
import schoolmessengericon from '../../images/systems/schoolmessenger.png';
import sftpicon from '../../images/systems/sftp.png';
import sledicon from '../../images/systems/sled.png';
import smartrecruitersicon from '../../images/systems/smartrecruiters.png';
import sqlservericon from '../../images/systems/sqlserver.png';
import storedprocedureicon from '../../images/systems/storedprocedure.png';
import tableauservericon from '../../images/systems/tableauserver.png';
import timelyicon from '../../images/systems/timely.png';
import tipwebicon from '../../images/systems/tipweb.png';
import torshicon from '../../images/systems/torsh.png';
import stmathicon from '../../images/systems/stmath.png';
import waterfordicon from '../../images/systems/waterford.png';
import workdayicon from '../../images/systems/workday.png';
import zendeskicon from '../../images/systems/zendesk.png';

import DataObjectIcon from '@mui/icons-material/DataObject';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DatasetIcon from '@mui/icons-material/Dataset';
import AbcIcon from '@mui/icons-material/Abc';

import actions from './actions';
import attributes from './attributes';

// generic connection types
const connectionDefaults = (({ Name, SystemKey, SystemType }) => ({ Name, SystemKey, SystemType }))(connection)
const sftp = (({ url, port, username, password }) => ({ url, port, username, password }))(connection)
const browser = (({ url_auth, url_data, username, password }) => ({ url_auth, url_data, username, password }))(connection)

// default options for dropdowns
const options = {
    file_type: [
        { value: 'csv', label: 'Comma Separated Values (CSV)', icon: <InsertDriveFileOutlinedIcon /> },
        { value: 'txt', label: 'Tab-Delimited File (TXT)', icon: <KeyboardTabIcon /> },
        { value: 'json', label: 'JavaScript Object Notation (JSON)', icon: <DataObjectIcon /> }
    ]
}

// generic system type
const systemsftp = {
    connection: sftp,
    source: {
        get_files: actions.get_files
    },
    target: {
        post_files: actions.post_files
    }
}

const getAction = (item, node) => {
    const base = item.Input[node]
    if (base.system && base.type && base.action) {
        try {
            return SystemConfig[base.system].types[base.type][node][base.action]
        } catch (error) {
            console.log(base, error)
            return {}
        }
    } else {
        return {}
    }

}

// System config object
const SystemConfig = {
    "": {
        label: '',
        icon: '',
        types: {
            "": {
                connection: {},
                source: {
                    "": { attributes: [] }
                },
                target: {
                    "": { attributes: [] }
                }
            }

        }
    },
    acceleratedreader: {
        label: 'Accelerated Reader',
        icon: acceleratedreadericon,
        types: {
            sftp: systemsftp
        }
    },
    api: {
        label: 'Generic API',
        icon: apiicon,
        types: {
            api: {
                connection: (({ url, token }) => ({ url, token }))(connection),
                source: {
                    get_data: {
                        ...actions.get_data, ...{
                            attributes: [
                                attributes.file_type
                            ]
                        }
                    }
                },
                target: {}
            }
        }
    },
    box: {
        label: 'Box',
        icon: boxicon,
        types: {
            sftp: systemsftp
        }
    },
    brivo: {
        label: 'Brivo',
        icon: brivoicon,
        types: {
            api: {
                connection: (({ redirect_uri, client_id, client_secret, apikey, token }) => ({ redirect_uri, client_id, client_secret, apikey, token }))(connection),
                source: {
                    get_data: actions.get_data
                }
            },
        }
    },
    canvas: {
        label: 'Canvas',
        icon: canvasicon,
        types: {
            api: {
                connection: (({ url, token }) => ({ url, token }))(connection),
                source: {
                    get_data: {
                        ...actions.get_data, ...{
                            params: [
                                'include[]',
                                'start_time',
                                'state[]',
                                'student_ids[]'
                            ]
                        }
                    }
                },
                target: {}
            }
        }
    },
    clever: {
        label: 'Clever',
        icon: clevericon,
        types: {
            sftp: systemsftp,
            browser: {
                connection: browser,
                source: {
                    get_data: { ...actions.get_data, ...{ description: 'Get data from the Clever IDM web reports.' } }
                },
                target: {}
            }
        }
    },
    deanslist: {
        label: 'Deanslist',
        icon: deanslisticon,
        types: {
            api: {
                connection: (({ subdomain, apikeys }) => ({ subdomain, apikeys }))(connection),
                source: {
                    get_data: actions.get_data
                },
                target: {
                    load_data: actions.load_data
                }
            },
            sftp: systemsftp
        }
    },
    dialpad: {
        label: "Dialpad",
        icon: dialpadicon,
        types: {
            api: {
                connection: (({ token }) => ({ token }))(connection),
                source: {
                    get_data: actions.get_data
                },
                target: {}
            },
        },
    },
    edgenuity: {
        label: 'Edgenuity',
        icon: edgenuityicon,
        types: {
            sftp: systemsftp,
            browser: {
                connection: browser,
                source: {
                    get_data: actions.get_data
                },
                target: {}
            }
        }
    },
    edulastic: {
        label: "Edulastic",
        icon: edulasticicon,
        types: {
            api: {
                connection: (({ username, password }) => ({ username, password }))(connection),
                source: {
                    get_data: actions.get_data
                },
                target: {}
            },
        }
    },
    ellevation: {
        label: 'ELLevation',
        icon: ellevationicon,
        types: {
            sftp: systemsftp
        }
    },
    google: {
        label: 'Google',
        icon: googleicon,
        types: {
            api: {
                connection: (({ domain, admin_account, credentials }) => ({ domain, admin_account, credentials }))(connection),
                source: {
                    get_data: {
                        ...actions.get_data,
                        ...{
                            params: [
                                'q',
                                'query',
                                'userKey',
                                'applicationName',
                                'startTime',
                                'endTime',
                                'domain'
                            ]
                        }
                    }
                },
                target: {
                    load_data: {
                        ...actions.load_data,
                        ...{
                            attributes: [
                                attributes.actionsGoogle
                            ]
                        }
                    }
                }
            }
        }
    },
    googlesheets: {
        label: 'Google Sheets',
        icon: googlesheetsicon,
        types: {
            api: {
                connection: {},
                source: {
                    read_sheets: actions.read_sheets
                },
                target: {
                    overwrite: {
                        ...actions.overwrite,
                        ...{
                            attributes: [
                                attributes.googlesheetId,
                                attributes.sheet
                            ]
                        }
                    }
                }
            }
        }
    },
    greatminds: {
        label: 'Great Minds',
        icon: greatmindsicon,
        types: {
            sftp: systemsftp
        }
    },
    guardianconduct: {
        label: 'Guardian Conduct',
        icon: guardianconducticon,
        types: {
            sftp: systemsftp
        }
    },
    illuminate: {
        label: 'Illuminate',
        icon: illuminateicon,
        types: {
            sqlserver: {
                connection: (({ server, port, database, schema, username, pwd }) => ({ server, port, database, schema, username, pwd }))(connection),
                source: {
                    query: actions.query,
                    get_table: actions.get_data
                },
                target: {}
            }
        }
    },
    iready: {
        label: 'i-Ready',
        icon: ireadyicon,
        types: {
            sftp: systemsftp
        }
    },
    jazzhr: {
        label: 'JazzHR',
        icon: jazzhricon,
        types: {
            api: {
                connection: (({ url, token }) => ({ url, token }))(connection),
                source: {
                    get_data: actions.get_data
                },
                target: {}
            }
        }
    },
    lexia: {
        label: 'Lexia',
        icon: lexiaicon,
        types: {
            sftp: systemsftp,
            browser: {
                connection: browser,
                source: {
                    get_data: actions.get_data
                },
                target: {}
            }
        }
    },
    microsoft: {
        label: 'Microsoft',
        icon: microsofticon,
        types: {
            api: {
                connection: (({ tenant_id, client_id, client_secret }) => ({ tenant_id, client_id, client_secret }))(connection),
                refresh: "https://aad.portal.azure.com/#view/Microsoft_AAD_IAM/StartboardApplicationsMenuBlade/~/AppAppsPreview",
                source: {
                    get_data: actions.get_data
                },
                target: {}
            }
        }
    },
    mosyle: {
        label: 'Mosyle',
        icon: mosyleicon,
        types: {
            api: {
                connection: (({ username, password, token }) => ({ username, password, token }))(connection),
                source: {
                    get_data: actions.get_data
                },
                target: {}
            }
        }
    },
    nweamap: {
        label: 'NWEA MAP',
        icon: nweamapicon,
        types: {
            api: {
                connection: (({ url, username, password }) => ({ url, username, password }))(connection),
                source: {
                    get_data: actions.get_data
                },
                target: {}
            }
        }
    },
    okta: {
        label: 'Okta',
        icon: oktaicon,
        types: {
            api: {
                connection: (({ url, token }) => ({ url, token }))(connection),
                source: {
                    get_data: {
                        ...actions.get_data, ...{
                            params: [
                                'type'
                            ]
                        }
                    }
                },
                target: {
                    load_data: {
                        ...actions.load_data,
                        ...{
                            attributes: [
                                attributes.actions
                            ]
                        }
                    }
                }
            }
        }
    },
    osse: {
        label: 'OSSE',
        icon: osseicon,
        types: {
            api: {
                connection: (({ username, password }) => ({ username, password }))(connection),
                source: {
                    get_data: actions.get_data
                },
                target: {}
            },
            sftp: systemsftp
        }
    },
    pandadoc: {
        label: 'PandaDoc',
        icon: pandadocicon,
        types: {
            api: {
                connection: (({ url, token }) => ({ url, token }))(connection),
                source: {
                    get_data: {
                        ...actions.get_data, ...{
                            params: [
                                'q',
                                'modified_from'
                            ]
                        }
                    }
                },
                target: {}
            }
        }
    },
    postgresql: {
        label: 'PostgreSQL',
        icon: postgresqlicon,
        types: {
            sqlserver: {
                connection: (({ server, port, database, schema, username, pwd }) => ({ server, port, database, schema, username, pwd }))(connection),
                source: {
                    query: actions.query
                },
                target: {}
            }
        }
    },
    powerschool: {
        label: 'Powerschool',
        icon: powerschoolicon,
        types: {
            api: {
                connection: (({ url, client_id, client_secret, token }) => ({ url, client_id, client_secret, token }))(connection),
                source: {
                    get_data: actions.get_data,
                    get_students_gpa: actions.get_students_gpa,
                    get_global_ids: actions.get_global_ids,
                    get_student_enrollments: actions.get_student_enrollments
                },
                target: {
                    load_data: actions.load_data
                }
            },
            sftp: systemsftp
        }
    },
    qualtrics: {
        label: 'Qualtrics',
        icon: qualtricsicon,
        types: {
            api: {
                connection: (({ subdomain, apikey }) => ({ subdomain, apikey }))(connection),
                source: {
                    get_survey_results: actions.get_survey_results,
                    get_survey_links: actions.get_survey_links
                },
                target: {
                    contact_import_automation: actions.contact_import_automation,
                    sync_mailing_list: actions.sync_mailing_list
                }
            }
        }
    },
    quickbase: {
        label: 'Quickbase',
        icon: quickbaseicon,
        types: {
            api: {
                connection: (({ url, token }) => ({ url, token }))(connection),
                source: {
                    get_data: actions.get_data
                },
                target: {
                    load_data: actions.load_data
                }
            }
        }
    },
    RGR: {
        label: 'RGR',
        icon: rgricon,
        types: {
            browser: {
                connection: browser,
                source: {},
                target: {}
            }
        }
    },
    s3: {
        label: 's3',
        icon: s3icon,
        types: {
            api: {
                connection: (({ folder }) => ({ folder }))(connection),
                source: {
                    convert_files: actions.query,
                    merge_files: actions.query,
                    delete_files: actions.query
                },
                target: {}
            }
        }
    },
    salesforce: {
        label: 'Salesforce',
        icon: salesforceicon,
        types: {
            api: {
                connection: (({ organizationId, username, password, security_token }) => ({ organizationId, username, password, security_token }))(connection),
                source: {
                    get_table: actions.get_table
                },
                target: {}
            }
        }
    },
    schoolmessenger: {
        label: 'SchoolMessenger',
        icon: schoolmessengericon,
        types: {
            sftp: systemsftp
        }
    },
    sled: {
        label: 'SLED',
        icon: sledicon,
        types: {
            sftp: systemsftp,
            browser: {
                connection: browser,
                source: {},
                target: {}
            }
        }
    },
    sftp: {
        label: 'SFTP',
        icon: sftpicon,
        types: {
            sftp: systemsftp
        }
    },
    smartrecruiters: {
        label: 'SmartRecruiters',
        icon: smartrecruitersicon,
        types: {
            sftp: systemsftp,
            api: {
                connection: (({ url, token }) => ({ url, token }))(connection),
                source: {
                    get_data: actions.get_data
                },
                target: {}
            }
        }
    },
    sqlserver: {
        label: 'SQL Server',
        icon: sqlservericon,
        types: {
            sqlserver: {
                connection: (({ server, port, database, schema, username, pwd }) => ({ server, port, database, schema, username, pwd }))(connection),
                source: {
                    query: actions.query,
                    storedprocedure: actions.storedprocedure
                },
                target: {
                    update_insert: actions.update_insert,
                    update_insert_delete: actions.update_insert_delete,
                    append: actions.append,
                    overwrite: actions.overwrite
                }
            }
        }
    },
    stmath: {
        label: 'ST Math',
        icon: stmathicon,
        types: {
            sftp: systemsftp
        }
    },
    storedprocedure: {
        label: 'SQL Server Stored Procedure',
        icon: storedprocedureicon,
        types: {
            storedprocedure: {
                connection: {},
                source: {},
                target: {
                    storedprocedure: actions.storedprocedure
                }
            }
        }
    },
    tableauserver: {
        label: 'Tableau Server',
        icon: tableauservericon,
        types: {
            api: {
                connection: (({ url, sitename, username, password }) => ({ url, sitename, username, password }))(connection),
                source: {
                    get_data: actions.get_data,
                    get_metadata_query: actions.get_metadata_query
                },
                target: {
                    update_user: actions.update_user,
                    load_data: {
                        ...actions.load_data,
                        ...{
                            attributes: [
                                attributes.action
                            ]
                        }
                    }
                }
            }
        }
    },
    timely: {
        label: 'Timely',
        icon: timelyicon,
        types: {
            sftp: systemsftp
        }
    },
    tipweb: {
        label: 'TipWeb',
        icon: tipwebicon,
        types: {
            sftp: systemsftp,
            api: {
                connection: (({ url, client_id, client_secret, token }) => ({ url, client_id, client_secret, token }))(connection),
                source: {
                    get_data: actions.get_data,
                },
            }
        }
    },
    torsh: {
        label: 'Torsh',
        icon: torshicon,
        types: {
            api: {
                connection: (({ consumer_key, consumer_secret, access_token }) => ({ consumer_key, consumer_secret, access_token }))(connection),
                source: {
                    get_data: actions.get_data
                },
                target: {
                    load_data: actions.load_data
                }
            }
        }
    },
    waterford: {
        label: 'Waterford',
        icon: waterfordicon,
        types: {
            sftp: systemsftp
        }
    },
    workday: {
        label: "Workday",
        icon: workdayicon,
        types: {
            api: {
                connection: (({ username, password, url }) => ({ username, password, url }))(connection),
                source: {
                    get_data: actions.get_data,
                    get_report: actions.get_report
                },
                target: {}
            },
        },
    },
    zendesk: {
        label: 'Zendesk',
        icon: zendeskicon,
        types: {
            api: {
                connection: (({ url, username, token }) => ({ url, username, token }))(connection),
                source: {
                    get_data: actions.get_data
                },
                target: {

                }
            }
        },
    }
}
export { SystemConfig, connectionDefaults, getAction };