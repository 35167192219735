import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import {
   FormLabel, Grid, InputAdornment, TextField
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SystemIcons from '../Shared/SystemIcons';
import ControlPanelFilters from './ControlPanelFilters';

const cssControlPanel = { border: '1px solid #ccc', borderRadius: 3, p: 1 }

const ControlPanel = props => {
    const [filter, setFilter] = useState({})
    const handleFilter = e => {
        const newFilter = {...filter, [e.target.name]: e.target.value}
        setFilter(newFilter)
        props.handler(newFilter)
    }

    const data = useSelector(state => (state[props.page.toLowerCase()] || {}).value)

    useEffect(() => {
        setFilter(data === null  ? {} : filter)
    }, [data])

    return <Grid item xs={12}>
        <Grid container sx={cssControlPanel}>
            <Grid item xs={props.hideSystems ? 12 : 6}>
                <Grid container >

                    {/* Search */}
                    <FormLabel>Search</FormLabel>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            variant="standard"
                            type="search"
                            name="search"
                            value={filter.search || ''}
                            onChange={handleFilter}
                            autoComplete='off'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>

                    {/* Filters */}
                    <ControlPanelFilters
                        page={props.page}
                        filter={filter}
                        handler={handleFilter}
                        selects={props.selects}
                        reset={data === null}
                    />
                </Grid>
            </Grid>
            {!props.hideSystems && 
            <Grid item xs={6}>
                <FormLabel>Systems</FormLabel>
                <SystemIcons
                    changed={handleFilter}
                    exclusive={props.exclusive || false}
                    filter={filter}
                />
            </Grid>
}
        </Grid>
    </Grid>
}

export default ControlPanel;