import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { setSnackbar } from '../Cache/snackbarSlice'
import { Snackbar, Alert, Grid, Box } from '@mui/material';
import Container from '../Shared/Container';
import logo from '../../images/logo-min-white.svg';
import { Image } from 'mui-image'

const SnackbarRedux = props => {
    const dispatch = useDispatch();
    const snackbar = useSelector(state => state.snackbar.value);
    const width = (snackbar || {}).severity === "error" ? "800px" : "300px"
    const [seconds, setSeconds] = useState(0);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setSnackbar(null))
    }

    const Logo = () => (
        <Box sx={{
            display: 'inline-block',
            '& #img-logo': {
                height: 16,
                width: 16
            }
        }}><img id="img-logo" src={logo} /></Box>
    )

    useEffect(() => {
        let secs = 0
        if (snackbar && snackbar.timer) {
            const interval = setInterval(() => {
                secs += 1
                setSeconds(secs)
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setSeconds(0)
        }
    }, [snackbar])

    return (
        // <Snackbar />
        <Snackbar
            open={snackbar !== null}
            autoHideDuration={snackbar ? snackbar.duration || 3000 : 3000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            onClose={handleClose}
            sx={{
                width: width,
                minWidth: 400,
                '& .MuiAlert-filledSuccess': { color: 'rgba(255, 255, 255, 1)' } // makes success text white on green
            }}
        >
            <Alert
                variant='filled'
                onClose={handleClose}
                severity={snackbar ? snackbar.severity : 'info'}
                sx={{ width: '100%', overflow: 'auto' }}
            >
                <Container>
                    <Grid item>
                        {snackbar && ((typeof snackbar.message === 'object') ? JSON.stringify(snackbar.message) : snackbar.message || "")}
                        {snackbar && snackbar.timer && ` ${seconds}s`}
                        {snackbar && snackbar.timer && [...Array(seconds).keys()].map(() => (".")).join("")}
                        {snackbar && snackbar.timer && <Logo />}
                        
                    </Grid>
                </Container>
            </Alert>
        </Snackbar>
    )

}
export default SnackbarRedux;