import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'snackbar',
  initialState: {
    value: []
  },
  reducers: {
    setSnackbar: (state, data) => {
      state.value = data.payload
    },
  },
})

export const { setSnackbar } = slice.actions
export default slice.reducer