import React, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import '@aws-amplify/ui-react/styles.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MuiTheme from './MuiTheme';
import AuthContext from './components/Auth/AuthContext';
import awsconfig from './config';

import Navbar from './pages/Navbar';
import Execution from './pages/Execution';
import Executions from './pages/Executions';
import Connections from './pages/Connections';
import Connection from './pages/Connection';
import Jobs from './pages/Jobs';
import Job from './pages/Job';
import Queries from './pages/Queries';
import Query from './pages/Query';
import Reports from './pages/Reports';
import Report from './pages/Report';
import Schemas from './pages/Schemas';
import Schema from './pages/Schema';
import User from './pages/User';
import Users from './pages/Users';
import Error404 from './pages/Error404';

import Modal from './components/Shared/Modal';
import Snackbar from './components/Shared/Snackbar';

Amplify.configure(awsconfig);

export default function App() {
    const [user, setUser] = useState();
    useEffect(() => {
        const getUser = async () => {
            try {
                const authUser = await Auth.currentAuthenticatedUser();
                setUser(authUser);
            } catch (e) {
                setUser(null);
                const params = {
                    identity_provider: awsconfig.Auth.identityProvider,
                    redirect_uri: window.location.origin,
                    response_type: "CODE",
                    client_id: awsconfig.Auth.userPoolWebClientId,
                    scope: awsconfig.Auth.oauth.scope.join(' ')
                }
                const urlParams = new URLSearchParams(params).toString();
                window.location.href = `https://${awsconfig.Auth.oauth.domain}/oauth2/authorize?${urlParams}`;
                // window.location.href = `https://${awsconfig.Auth.oauth.domain}/oauth2/authorize?identity_provider=OktaKIPPDC&redirect_uri=${window.location.origin}&response_type=CODE&client_id=${awsconfig.Auth.userPoolWebClientId}&scope=aws.cognito.signin.user.admin email openid`;

            }
        }
        getUser()
    }, []);

    return (
        <ThemeProvider theme={createTheme(MuiTheme)}>
            {user &&
                <AuthContext>
                    <BrowserRouter>
                        <Snackbar />
                        <Modal />
                        <Navbar>
                            <Routes>
                                <Route path='/' element={<Executions />} />
                                <Route path='/connections' element={<Connections />} />
                                <Route path='/connections/new' element={<Connection />} />
                                <Route path='/connections/:id' element={<Connection />} />
                                <Route path='/executions' element={<Executions />} />
                                <Route path='/executions/new' element={<Execution />} />
                                <Route path='/executions/:id' element={<Execution />} />
                                <Route path='/jobs' element={<Jobs />} />
                                <Route path='/jobs/new' element={<Job />} />
                                <Route path='/jobs/:id' element={<Job />} />
                                <Route path='/queries' element={<Queries />} />
                                <Route path='/queries/new' element={<Query />} />
                                <Route path='/queries/:id' element={<Query />} />
                                <Route path='/reports' element={<Reports />} />
                                <Route path='/reports/new' element={<Report />} />
                                <Route path='/reports/:id' element={<Report />} />
                                <Route path='/schemas/' element={<Schemas />} />
                                <Route path='/schemas/:id' element={<Schema />} />
                                <Route path='/users/' element={<Users />} />
                                <Route path='/users/:id' element={<User />} />
                                <Route path='/settings/users/:id' element={<User />} />
                                <Route path='*' element={<Error404 />} />
                            </Routes>
                        </Navbar>
                    </BrowserRouter>
                </AuthContext>
            }
        </ThemeProvider>
    );
}