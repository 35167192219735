import { useState, useEffect } from 'react';
import {
    Typography, Box, Grid,
    Select, MenuItem, Avatar, FormControl, InputLabel, IconButton, LinearProgress
} from '@mui/material';
import Icon from '../Shared/Icon';
import ButtonLaunch from '../Buttons/ButtonLaunch';

const FormOption = props => {
    const option = props.option || {}
    return (
        <Box
            sx={{
                display: 'flex',
                p: props.selected ? 0 : 'auto',
                '& .MuiTypography-root': { ml: 1 }
            }}
        >
            {option.system && <Icon system={option.system} />}
            {option.icon && option.icon}
            <Typography>{option.label}</Typography>
            {/* {option.link && !props.selected && <ButtonLaunch url={`/${option.link}/${option.value}`} small />} */}
            {/* {option.link && <ButtonLaunch url={`/${option.link}/${option.value}`} small />} */}
        </Box>
    )
}

const FormSelect = props => {
    const [options, setOptions] = useState(props.options)

    useEffect(() => {
        setOptions(props.options)
    }, [props]);

    return (
        <FormControl variant='standard' fullWidth>
            {props.label && <InputLabel shrink={props.header || props.value !== undefined || false}>{props.label}</InputLabel>}

            {options ? //&& options.length > 0 ?
                <Select
                    name={props.name}
                    value={props.value}
                    onChange={props.handler}
                    disabled={props.disabled}
                    disableUnderline={props.disableUnderline}
                    IconComponent={props.hideArrow && (() => { })}
                    renderValue={() => <FormOption option={props.options.find(x => x.value === props.value)} selected />}
                >
                    <MenuItem key={-1} value=''>(none)</MenuItem>
                    {props.options && props.options.map((option, key) => {
                        return (
                            <MenuItem
                                key={key}
                                value={option.value}
                            >
                                <FormOption option={option} />
                            </MenuItem>
                        )
                    })}
                </Select>
                :
                <Grid item xs={12} sx={{'& .MuiLinearProgress-root': {top: '1rem'}}}><LinearProgress /></Grid>
            }
        </FormControl>
    )
}
export default FormSelect;