import { Auth } from 'aws-amplify';

const type = 'okta';
const region = 'us-east-1';

const base = {
    main: {
        identityPoolId: 'us-east-1:b9311500-9d6f-4f41-9c40-c36d322e5867',
        userPoolId: 'us-east-1_zfI1WG6yE',
        userPoolWebClientId: '2afl2oatq9472i5qg9e29n01lq',
        endpoint: 'https://ky1v6gi2bj.execute-api.us-east-1.amazonaws.com/prod/'
    },
    okta: {
        domain: 'dashappgoogleauth.auth.us-east-1.amazoncognito.com',
        identityProvider: "Okta",
        identityPoolId: 'us-east-1:b9311500-9d6f-4f41-9c40-c36d322e5867',
        userPoolId: 'us-east-1_PxaW6KR7f',
        userPoolWebClientId: 'rjl62eluiuauaad8f5ci9rk5e',
        endpoint: 'https://gvahsfn5kd.execute-api.us-east-1.amazonaws.com/prod/'
    },
    okta2: {
        domain: 'integrationsuserpoolprod.auth.us-east-1.amazoncognito.com',
        identityProvider: "OktaKIPPDC",
        identityPoolId: 'us-east-1:51b49497-1ea0-46c0-a0c1-4413f93e24b9',
        userPoolId: 'us-east-1_1ndXwo8xf',
        userPoolWebClientId: '67qn8skbvtlelhukb7dc1t3j1d',
        endpoint: 'https://4guh4jgim9.execute-api.us-east-1.amazonaws.com/prod/'
    }
}

const servers = ["localhost", "dash-dev.kippdcdata.org", "dash.kippdcdata.org"]
const redirect = (servers.includes(window.location.hostname)) ? window.location.origin : null
const config = {
    // socialProviders: type === 'main' ? [] : [type],
    Auth: {
        region: region,
        identityProvider: base[type].identityProvider,
        identityPoolId: base[type].identityPoolId,
        userPoolId: base[type].userPoolId,
        userPoolWebClientId: base[type].userPoolWebClientId,
        oauth: {
            domain: base[type].domain,
            redirectSignIn: redirect,
            redirectSignOut: 'https://kippdc.okta.com',
            scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
            responseType: 'code'
        },
    },
    API: {
        endpoints: [
            {
                name: 'ApiDash',
                endpoint: base[type].endpoint,
                region: region,
                custom_header: async () => {
                    return {
                        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                    }
                }
            }
        ]
    }
};

export default config;