import Grid from '@mui/material/Grid';
const ContainerRow = props => (
    <Grid
        container
        spacing={1}
        sx={{
            borderBottom: props.header ? '2px solid #ddd' : '1px dotted #ddd',
            ml: props.subsection ? 0 : 1,
            minHeight: '2.5rem',
            my: 'auto',
            '& .MuiGrid-root': { py: 0, my: 'auto' },
            '& .MuiTypography-root, & .MuiInputBase-root': { fontSize: '0.875rem' }
        }}
    >
        {props.children}
    </Grid>
)
export default ContainerRow;