import { useState, useEffect, useMemo } from "react";
import { useSelector, } from 'react-redux';

import Subtitle from '../Shared/Subtitle';
import ContainerSub from '../Shared/ContainerSub';
import JobRecordRow from '../Job/JobRecordRow';

import { SystemConfig } from '../Config/SystemConfig';
import { getConfig } from '../Shared/Functions';

const JobRecords = props => {
    // const schemasState = useSelector(state => (state.schemas || {}).value) || []
    const schemasState = useSelector(state => state.schemas)
    const schemas = useMemo(() => (schemasState || {}).value || [], [schemasState])
    const [input, setInput] = useState(props.input)
    const [node, setNode] = useState(props.input.target.system === 'sqlserver' ? 'source' : 'target')
    const [selected, setSelected] = useState([])

    useEffect(() => {
        const newNode = props.input.target.system === 'sqlserver' ? 'source' : 'target'
        setInput(props.input)
        setNode(newNode)
        setSelected(schemas.filter(x => x.GSI1PK.substring(4) === props.input[newNode].system))
    }, [props, schemas])

    const makeParamsOptions = (x) => {
        return [
            ...Object.keys(x.Fields),
            ...getConfig('params', input, node)
        ].sort().map(y => ({
            value: y,
            label: y
        }))
    }
    const attributes = input && SystemConfig[input.source.system] && SystemConfig[input.target.system] && [
        ...((SystemConfig[input.source.system || ''].types[input.source.type || '']['source'][input.source.action || ''] || {}).attributes || []),
        ...((SystemConfig[input.target.system || ''].types[input.target.type || '']['target'][input.target.action || ''] || {}).attributes || [])
    ]

    const handleRecordChange = (e, key) => {
        let newRecords = (input && input.records) ? [...input.records] : []

        if (e.target.name === "record_order") {
            newRecords.splice(key + e.target.value, 0, newRecords.splice(key, 1)[0])
        } else if (e.target.name === "add") {
            newRecords = [...(newRecords || []), ...[{ object: '' }]]
        } else if (e.target.name === "delete") {
            newRecords.splice(key, 1)
        } else {
            newRecords[key] = {
                ...{ ...newRecords[key] },
                ...{ [e.target.name]: e.target.value }
            }
            !newRecords[key].params && delete newRecords[key].params
        }
        setInput({
            ...input,
            ...{ records: newRecords }
        })
        props.handler({ target: { name: 'Input', input: 'records', value: newRecords } })
    }

    return (
        <ContainerSub>
            <Subtitle
                type="Record"
                new
                handler={() => handleRecordChange({ target: { name: "add" } })}
            />

            {/* Records */}
            {input && input.records && input.records.map((item, key) => {
                return (
                    <JobRecordRow
                        key={key}
                        item={item}
                        node={node}
                        attributes={attributes}
                        names={selected.map(x => ({ label: x.Name, value: x.Name }))}
                        params={selected.map(x => ({ [x.Name]: makeParamsOptions(x) })).reduce((a, b) => Object.assign(a, b), {})}
                        handler={e => handleRecordChange(e, key)}
                    />
                )
            })}
        </ContainerSub>

    )
}
export default JobRecords;