import { useEffect, useState } from 'react';
import { useUser } from '../components/Auth/AuthContext';
import { useSelector, useDispatch } from 'react-redux';
import { setExecutions } from '../components/Cache/executionsSlice';
import { setSnackbar } from '../components/Cache/snackbarSlice';
import { API } from '@aws-amplify/api';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';

import Container from '../components/Shared/Container';
import ControlPanel from '../components/Shared/ControlPanel';
import Header from '../components/Shared/Header';
import RowExecution from '../components/Rows/RowExecution';

import Loading from '../components/Shared/Loading';

const Executions = () => {
    dayjs.extend(relativeTime);
    const dispatch = useDispatch()
    const executions = useSelector(state => (state.executions || {}).value)
    const lastUpdated = useSelector(state => (state.executions || {}).lastUpdated)
    const jobs = useSelector(state => (state.jobs || {}).value)

    const [data, setData] = useState(executions || []);
    const [since, setSince] = useState(dayjs(lastUpdated).fromNow());

    // default time range
    const hours = 24

    useEffect(() => {
        setData(executions || [])
        setSince(dayjs(lastUpdated).fromNow())
    }, [executions])

    useEffect(() => {
        // if (dayjs().diff(dayjs(lastUpdated), 'hours') > 24) {
        //     dispatch(setExecutions(null))
        // }
        const interval = setInterval(() => {
            setSince(dayjs(lastUpdated).fromNow())
        }, 60000);
        return () => clearInterval(interval);
    }, []);


    const handleFilter = filter => {
        let newData = executions.filter(x => (
            ((filter.search || '').length > 0 ? x.JobName.toLowerCase().includes(filter.search.toLowerCase()) : true) &&
            ((filter.Status || '').length > 0 ? x.Status === filter.Status : true) &&
            ((filter.system || []).length > 0 ? filter.system.some(system => x.StateMachine.split('-').indexOf(system) >= 0) : true) &&
            ((Date.now() - x.SK) <= 3600000 * (filter.hours || hours))
        ))
        setData(newData)
    }

    return (
        <Container>
            <Header
                type="Execution"
                setData={setExecutions}
                data={data}
                since={since}
                hideNew={true}
            />

            <ControlPanel
                page="Executions"
                handler={handleFilter}
                filter={executions === null}
            />

            {/* Results */}
            {executions === null
                ?
                <Loading />
                :
                <Grid item xs={12}>
                    <Container>
                        {data && data.map((item, key) => {
                            const adds = (({ Name, Input, Schedule }) => ({ Name, Input, Schedule }))((jobs || []).find(x => x.Id === item.JobId) || {Name: item.Name, Input: {}})
                            return (
                                <RowExecution
                                    key={key}
                                    row={{ ...item, ...adds }}
                                    maxWidth={Math.max(...data.map(x => x.Duration))}
                                />
                            )
                        })}

                    </Container>
                </Grid>
            }

        </Container>
    );
}

export default Executions;