import React, { useState, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";


const ButtonEnable = props => {
    const theme = useTheme();
    const [checked, setChecked] = useState(props.enabled);
    useEffect(() => {
        setChecked(props.enabled);
    }, [props])
    const Label = props => (
        <Typography
            sx={{
                color: theme.palette[checked ? 'success' : 'neutral'].main,
                fontSize: '0.875rem'
            }}
        >
            {checked ? "Enabled" : "Disabled"}
        </Typography>
    )
    return (
        <FormControlLabel
            control={<Switch
                color="success"
                checked={checked}
                onChange={props.enable}
            />}
            label={<Label />}
        />

    )
}
export default ButtonEnable