import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';

const ReportTable = props => {
    const params = useParams()
    const dispatch = useDispatch()
    const [data, setData] = useState(props.data);

    useEffect(() => {
        setData(props.data)
    }, [props]);

    const Value = props => (
        <Typography
            sx={{
                height: '1rem',
                maxWidth: '150px',
                fontSize: '0.75rem',
                overflow: 'hidden'
            }}
        >
            {props.value}
        </Typography>
    )

    return (
        <TableContainer sx={{ height: '400px' }}>
            <InputLabel shrink>Results</InputLabel>
            <Table sx={{ overflow: 'auto' }} stickyHeader>
                <TableHead >
                    <TableRow>
                        <TableCell
                            sx={{
                                background: '#eee'
                            }}
                        >
                        </TableCell>
                        {Object.keys(data[0] || []).map((value, i) => (
                            // {Object.keys(reportData[0] || []).map((value, i) => (
                            <TableCell
                                key={i}
                                align="right"
                                sx={{
                                    background: '#eee'
                                }}
                            >
                                <Value value={value} />
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody >
                    {data.map((row, key) => (
                        // {reportData.map((row, key) => (
                        <TableRow
                            key={key}
                            // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell
                                align="right"
                                sx={{
                                    py: 0,
                                    background: '#eee',
                                    color: '#888'
                                }}
                            >
                                <Value value={key + 1} />
                                {/* {key + 1} */}
                            </TableCell>
                            {Object.values(row).map((value, i) => (
                                <TableCell
                                    key={i}
                                    align="right"
                                    sx={{
                                        py: 0
                                    }}
                                >
                                    <Value value={value} />
                                    {/* {value} */}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ReportTable;