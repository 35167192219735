import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import LaunchIcon from "@mui/icons-material/Launch";
import { useTheme } from '@mui/material/styles';

const ButtonLaunch = props => {
    const theme = useTheme();
    const btnProps = {
        variant: props.variant || "text",
        color: props.mini ? "offwhite" : props.small ? "neutral" : props.color || "primary",
        onClick: props.handler,
        href: props.href,
        target: "_blank"
    }
    const sxMini = {
        background: theme.palette.neutral.main,
        color: theme.palette.offwhite.main,
        // mt: 1,
        p: 0.5
    }
    const sxSmall = {
        ml: 0.5,
        p: 0,
        color: '#ccc',
        '& .MuiSvgIcon-root': { height: 20 }
    }

    return props.small
        ?
        <IconButton
            onClick={() => window.open(props.href, '_blank', 'noopener,noreferrer')}
            sx={props.mini ? sxMini : props.small ? sxSmall : {}}
        >
            <LaunchIcon />
        </IconButton>
        :
        <Button
            {...btnProps}
            startIcon={<LaunchIcon />}
        >
            {props.label}
        </Button>
        
}
export default ButtonLaunch