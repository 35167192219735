import React from "react";
import { Grid, TextField } from '@mui/material';

const JobDescription = (props) => {
    let css = {
        '& .MuiInput-root': { color: 'gray' },
        '& .MuiInput-underline:before': { borderBottomColor: 'transparent' },
        '& .MuiInput-underline:after': { borderBottomColor: 'gray' },
    }
    return (
        <TextField
            fullWidth
            multiline
            variant='standard'
            name='Description'
            placeholder='Description'
            defaultValue={props.value}
            onChange={props.handler}
            sx={css}
        />
    )
}
export default JobDescription