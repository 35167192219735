import React from 'react';
import Grid from '@mui/material/Grid';

import ButtonDelete from './ButtonDelete';
import ButtonEnable from './ButtonEnable';
import ButtonRun from './ButtonRun';
import ButtonTest from './ButtonTest';
import ButtonSave from './ButtonSave';
import ButtonNew from './ButtonNew';
import ButtonRefresh from './ButtonRefresh';

const ActionButtons = props => {
    const css = {
        display: 'flex',
        textAlign: 'right',
        alignItems: 'center',
        '& .MuiButton-root': { ml: 1 }
    }
    return (
        <Grid item sx={css}>
            {props.enable  && <ButtonEnable {...props} />}
            {props.delete  && !props.createNew && <ButtonDelete {...props} />}
            {props.run     && <ButtonRun {...props} color="secondary" />}
            {props.test    && <ButtonTest {...props} />}
            {props.save    && <ButtonSave {...props} />}
            {props.refresh && <ButtonRefresh {...props} />}
            {props.new     && <ButtonNew {...props} />}
        </Grid>
    )
}
export default ActionButtons;