import Container from '../Shared/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ExecutionBlock from './ExecutionBlock';
import ButtonDownload from '../Buttons/ButtonDownload';
import { operOptions } from '../Shared/Functions';
import { useTheme } from '@mui/material/styles';

const ExecutionDetailRow = props => {
    const theme = useTheme();
    const records = props.item.data && props.item.data.records
    const block = {
        start: props.item.timestamp,
        duration: props.last ? new Date(props.last.timestamp) - new Date(props.item.timestamp) : 0,
        buffer: ((new Date(props.item.timestamp) - new Date(props.first.timestamp)) / props.range),
        width: props.last ? (new Date(props.last.timestamp) - new Date(props.item.timestamp)) / props.range : 0,
        input: props.item.data,
        status: props.last ? props.maproot(props.last).status : 'error',
        row_count: props.last && props.last.data ? JSON.parse((props.last.data.output != "null" && props.last.data.output) || '{}').row_count : 0,
        error: props.error
    }
    const RowLabel = props => (
        <Grid item xs={12}>
            <Box
                sx={{
                    px: 1,
                    display: 'inline-flex',
                    fontSize: '0.875rem',
                    background: "#eee",
                    borderRadius: '1rem'
                }}
            >
                {props.record.object}
            </Box>
        </Grid>
    )

    const RowLabelParams = props => (
        <Box>
            {props.record.params && props.record.params.map((x, i) => (
                <Grid item xs={12} key={i}>
                    <Box
                        sx={{
                            fontSize: '0.75rem',
                            color: '#888',
                            mt: -0.25,
                            ml: 2
                        }}
                    >
                        {x.field} {operOptions.find(o => o.value === x.operator).label} {x.value}
                    </Box>
                </Grid>
            ))}
        </Box>
    )

    return (
        <Grid item xs={12}>
            <Container>
                <Grid item xs={2} sx={{ overflow: 'hidden' }}>
                    {records && records.map((record, key) => {
                        return (
                            <Container key={key}
                                sx={{
                                    my: records.length > 1 ? 0 : "inherited",
                                    '& .MuiGrid-item': { pt: 0 },

                                }}
                            >
                                <RowLabel record={record} />
                                <RowLabelParams record={record} />
                            </Container>
                        )
                    })}
                </Grid>
                <Grid item xs={10}>
                    <ExecutionBlock {...block} dtBuffer={1} />
                </Grid>
            </Container>
        </Grid>
    )
}
export default ExecutionDetailRow;