import { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

const FormCheckbox = props => {
    const [checked, setChecked] = useState(props.checked)

    useEffect(() => {
        setChecked(props.checked)
    }, [props.checked]);

    return (
        <FormControlLabel
            name={props.name}
            label={props.label}
            labelPlacement="top"
            checked={checked || false}
            onChange={e => props.handler({ target: { name: e.target.name, value: e.target.checked } })}
            control={<Checkbox />}
            sx={{ '& .MuiTypography-root': { fontSize: '0.72rem', color: '#888' } }}
        />
    )
}
export default FormCheckbox;