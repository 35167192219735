import Grid from '@mui/material/Grid';
const Container = props => (
    <Grid
        container
        spacing={1}
        // sx={{
        //     ...{
        //         '& .MuiGrid-item': {py: 0, my: 'auto'}
        //     },
        //     ...props.sx
        // }}
        sx={props.sx}
    >
        {props.children}
    </Grid>
)
export default Container;