import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUsers } from '../components/Cache/usersSlice';
import Grid from '@mui/material/Grid';
import Container from '../components/Shared/Container';
import ActionButtons from '../components/Buttons/ActionButtons';
import FormSelect from '../components/Form/FormSelect';
import FormTextField from '../components/Form/FormTextField';
import UserName from '../components/User/UserName';
import Filters from '../components/Shared/Filters';
import { Auth } from 'aws-amplify'

const User = () => {
    const createNew = (window.location.pathname.split('/').pop() === "new")
    const params = useParams()
    const users = useSelector(state => state.users.value) || []
    const [data, setData] = useState(users.find(x => x.Id === params.id) || [])

    const federated = Auth.user.attributes.identities ? true : false;

    const handleChange = (e, i) => {
        let newData = [...data]
        if (e.target.name === "column_order") {
            newData.splice(i + e.target.value, 0, newData.splice(i, 1)[0])
            newData = newData.map((x, i) => ({ ...x, ...{ column_order: i + 1 } }))
        } else {
            newData[i] = { ...newData[i], ...{ [e.target.name]: e.target.value } }
        }
        setData(newData)
    }

    return (
        <Container>
            <Grid item xs sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                {/* {data.FirstName} {data.LastName} */}
                <UserName
                    {...data}
                    handler={handleChange}
                />
            </Grid>
            {/* Actions */}
            <ActionButtons
                save={() => { console.log('save') }}
            />
            {/* {["Email", "UserPoolId"].map((item, key) => (
                <Grid item xs={12} key={key}>
                    <FormTextField
                        name={item.replace(' ', '')}
                        label={item}
                        value={data[item.replace(' ', '')]}
                        disabled={federated}
                        handler={null}
                    />
                </Grid>
            ))} */}

            <Grid item xs={12}>
                <FormTextField
                    name="Email"
                    label="Email"
                    value={data.Email}
                    disabled={federated}
                    handler={null}
                />
            </Grid>

            <Grid item xs={12}>
                <FormSelect
                    name="Role"
                    label="Role"
                    value={data.Role}
                    options={Filters.Users.Role}
                    handler={null}
                />
            </Grid>
            <Grid item xs={12}>
                Receives:
                <Container>
                    {['Job Failures', 'Thing 2', 'Thing 3'].map((item, key) => (
                        <Grid item xs={1} key={key}>
                            {item}
                            <FormSelect
                                name={item.toLowerCase().replace(' ', '')}
                                // label={item}
                                value={""}
                                options={[]}
                                handler={null}
                            />
                            {/* {item.toLowerCase().replace(' ', '')} */}
                        </Grid>
                    ))}
                </Container>
            </Grid>
            {JSON.stringify(data)}
            {/* {console.log(Auth.user)} */}
        </Container >
    )
}
export default User