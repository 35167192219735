import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Box, IconButton, Tab, Tabs, Badge, CircularProgress } from '@mui/material';
import ReportTable from '../Report/ReportTable';
import FormSQLEditor from '../Form/FormSQLEditor';
import BarChart from '../Chart/Bar';
import TableRowsIcon from '@mui/icons-material/TableRows';
import CodeIcon from '@mui/icons-material/Code';
import BarChartIcon from '@mui/icons-material/BarChart';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVLink, CSVDownload } from "react-csv";

const QueryTabs = props => {
    const createNew = (window.location.pathname.split('/').pop() === "new")
    const params = useParams()
    const [tab, setTab] = useState(
        (window.location.hash === '#data') ? 1 : 0
    );
    const results = useSelector(state => state.results.value)
    // const [data, setData] = useState((results || {})[params.id] || []);
    const [data, setData] = useState([]);
    const [query, setQuery] = useState(props.query);
    const [tables, setTables] = useState(props.tables);

    useEffect(() => {
        console.log(results)
        results && setData(results[params.id] || [])
    }, [results]);

    useEffect(() => {
        setQuery(props.query)
        setTables(props.tables)
    }, [props]);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const LabelData = () => {
        return (
            <Badge
                badgeContent={data ? data.length : <CircularProgress size="1rem" />}
                color={data && data.length > 0 ? "success" : "white"}
                max={Infinity}
            >
                <TableRowsIcon />
            </Badge>
        )
    }

    const LabelCSV = () => (
        <CSVLink
            filename={`${(props.id || "").replace(/[^A-z0-9]/gi, '')}.csv`}
            data={data || []}
        >
            <Tab label={<DownloadIcon />} />
        </CSVLink>
    )
    return (
        <Grid container spacing={2}>
            <Grid item xs>
                {tab == 0 &&
                    <FormSQLEditor
                        query={query}
                        tables={tables}
                        handler={props.handler}
                    />
                }
                {tab == 1 &&
                    <Grid container>
                        <Grid item xs sx={{ width: 0 }}>
                            <ReportTable
                                data={(data || []).slice(0, 100)}
                            />
                        </Grid>
                    </Grid>
                }
                {tab == 2 &&
                    <Grid container>
                        <Grid item xs sx={{ width: 0 }}>
                            <BarChart
                                data={(data || [])}
                            />
                        </Grid>
                    </Grid>
                }
            </Grid>
            <Grid item style={{ display: 'flex' }}>
                <Tabs
                    orientation="vertical"
                    indicatorColor="primary"
                    value={tab}
                    onChange={handleTabChange}
                    sx={{ '& .MuiBadge-badge': { mr: -1 } }}
                >
                    <Tab label={<CodeIcon />} />
                    <Tab label={<LabelData />} />
                    <Tab label={<BarChartIcon />} />
                    {data && data.length > 0 && <LabelCSV />}
                </Tabs>

            </Grid>

        </Grid>
    );
}

export default QueryTabs;