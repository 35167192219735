import { useState, useEffect } from "react";
import { useUser } from '../Auth/AuthContext';
import { useSelector, useDispatch } from 'react-redux';
import { setJobs } from '../Cache/jobsSlice';
import { setModal } from '../Cache/modalSlice';
import { setSnackbar } from '../Cache/snackbarSlice';
import { API } from '@aws-amplify/api';
import { useTheme } from '@mui/material/styles';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import ContainerRow from '../Shared/ContainerRow';
import {
    Grid, Link, LinearProgress, IconButton
} from '@mui/material';

import ButtonJobs from '../Buttons/ButtonJobs';
import ButtonRows from '../Buttons/ButtonRows';
import IconSystem from '../Shared/IconSystem';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Gumball from '../Shared/Gumball';


const RowSchema = props => {
    const theme = useTheme();
    dayjs.extend(relativeTime);
    const dispatch = useDispatch();
    const { user, setUser } = useUser();
    const jobs = useSelector(state => state.jobs.value);
    const executions = useSelector(state => state.executions.value) || [];
    const [row, setRow] = useState(props.row);
    const [matched, setMatched] = useState(props.matched);

    const last = executions.sort((a, b) => b.SK - a.SK).find(x => matched.jobs.map(x => x.Id).includes(x.JobId))
    const lastUpdated = last && new Date(parseInt(last.SK))

    useEffect(() => {
        setRow(props.row)
        setMatched(props.matched)
    }, [props])

    const handleModal = () => {
        dispatch(setModal({
            open: true,
            content: [
                { variant: 'body2', text: 'Jobs that use' },
                { variant: 'h6', text: `${row.SystemKey}.${row.Name}` }
            ],
            matched: matched,
            enable: handleJobEnable
        }))
    }

    const handleJobEnable = (e, jobId) => {
        dispatch(setSnackbar({ severity: 'info', message: 'Saving Changes' }))
        API.put("ApiDash", `${user.org}/jobs/${jobId}`, {
            body: { [e.target.name]: e.target.value }
        }).then(response => {
            let newJobs = [...jobs]
            newJobs[newJobs.findIndex(x => x.Id === jobId)] = response;
            dispatch(setJobs(newJobs))
            dispatch(setSnackbar({ severity: 'success', message: 'Changes Saved!' }))
        }).catch(error => {
            console.log(error);
            dispatch(setSnackbar({ severity: 'error', message: error }))
        });
    }

    return row.matchJobs === null ? <LinearProgress /> :
        <ContainerRow>
            <Grid item xs={7}>
                <IconSystem
                    system={row.SystemKey}
                    type={(row.PK || '').split('#')[1] === 'sys' ? 'system' : 'user'}
                    page="Schemas"
                />
                <Link href={`/schemas/${row.Id}`} underline="hover" target="_blank">
                    {row.Name || "_"}
                </Link>
            </Grid>
            <Grid item xs={2}>
                {last && (
                    <Gumball last={last} lastUpdated={lastUpdated}>
                        <IconButton
                            size="small"
                            color="offwhite"
                            onClick={() => window.open(`/executions/${last.StateMachine}:${last.ExecutionID}`, '_blank', 'noopener,noreferrer')}
                            sx={{
                                height: 24,
                                width: 24,
                                mr: 0.5,
                                background: theme.palette[last.Status === 'Succeeded' ? 'success' : 'error'].main,
                                '& .MuiSvgIcon-root': {
                                    color: 'white'
                                },
                            }}
                        >
                            {last.Status === 'Succeeded' ? <CheckIcon /> : <ClearIcon />}
                        </IconButton>
                        {dayjs(lastUpdated).fromNow(true)}
                    </Gumball>
                )}
            </Grid>
            <Grid item xs={3} sx={{ textAlign: 'right' }}>
                <ButtonJobs
                    disabled={(matched && matched.jobs && matched.jobs.length === 0)}
                    value={matched && matched.jobs && matched.jobs.length}
                    handler={handleModal}
                />
                <ButtonRows
                    disabled
                    value={Object.keys(row.Fields).length}
                />
            </Grid>

        </ContainerRow >

}

export default RowSchema