import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { setReports } from '../components/Cache/reportsSlice';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';

import Container from '../components/Shared/Container';
import ControlPanel from '../components/Shared/ControlPanel';
import Header from '../components/Shared/Header';
import ReportCard from '../components/Report/ReportCard';
import ReportRow from '../components/Report/ReportRow';

const Reports = () => {
    dayjs.extend(relativeTime);
    const reports = useSelector(state => state.reports.value)
    const lastUpdated = useSelector(state => (state.reports || {}).lastUpdated);
    const connections = useSelector(state => state.connections.value)

    const [data, setData] = useState(reports || [])
    const [since, setSince] = useState(dayjs(lastUpdated).fromNow());
    const [filter, setFilter] = useState({});

    useEffect(() => {
        setData(reports || [])
        setSince(dayjs(lastUpdated).fromNow())
    }, [reports])

    const handleFilter = filter => {
        const newData = reports.filter(x => (
            ((filter.search || '').length > 0 ? x.Name.toLowerCase().includes(filter.search.toLowerCase()) : true)
        ))
        setData(newData)
    }
    console.log(data)
    return (
        <Container>
            <Header
                type="Reports"
                setData={setReports}
                data={data}
                since={since}
            />
            {/* Control Panel */}
            <ControlPanel
                page={"Reports"}
                filter={filter}
                handler={f => setFilter(f)}
            />

            {/* Results */}
            {reports === null
                ?
                <Grid item xs={12}><LinearProgress /></Grid>
                :
                <Grid item xs={12}>
                    <Container>
                        {data && data.map((item, key) => {
                            const connection = connections.find(x => x.Id === item.connection)
                            const row = { ...item, ...{ SystemKey: connection.SystemKey, SystemType: connection.SystemType     } }
                            return (
                                <ReportRow
                                    key={key}
                                    row={row}
                                />
                            )
                        })
                        }
                    </Container>
                </Grid>
            }
        </Container>
      
    );
}

export default Reports;