import { useState, useEffect } from "react";
import { useUser } from '../components/Auth/AuthContext';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setQueries } from '../components/Cache/queriesSlice';
import { setModal } from '../components/Cache/modalSlice';
import { setSnackbar } from '../components/Cache/snackbarSlice';
import { API } from "aws-amplify";
import _ from 'lodash';

import { updateCache } from '../components/Shared/Functions';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import ActionButtons from '../components/Buttons/ActionButtons';
import Container from '../components/Shared/Container';
import Title from '../components/Shared/Title';
import SelectConnection from '../components/Select/SelectConnection';
import FormSQLEditor from '../components/Form/FormSQLEditor';
import QueryTabs from '../components/Query/QueryTabs';
import { matchTables } from '../components/Shared/Functions';
import LinearProgress from "@mui/material/LinearProgress";

const Query = props => {
    const createNew = (window.location.pathname.split('/').pop() === "new")
    const { user, setUser } = useUser();
    const params = useParams();
    const dispatch = useDispatch();

    const queries = useSelector(state => (state.queries.value)) || [];
    const cached = queries.find(x => x.Id === params.id) || {}
    const [data, setData] = useState();

    // Query-specific
    const [query, setQuery] = useState("");
    const [tables, setTables] = useState([]);

    useEffect(() => {
        user.org && getData()
    }, [user])

    const getData = () => {
        if (createNew) { setData({}); return }
        API.get("ApiDash", `${user.org}/queries/${params.id}`).then(
            (response) => {
                response = { ...response }
                setData(response);

                // Query-specific
                setQuery(response.query);
                setTables(matchTables(response.query));

                // update cache if API response is different
                (_.difference(_.values(response), _.values(cached)) || []).length > 0 && updateCache(dispatch, setQueries, [...queries], response, params.id);
            }
        ).catch((error) => {
            dispatch(setSnackbar({ severity: 'error', message: error.message }))
        });
    }

    const handleChange = e => {
        const isQuery = e.target.name === "query"
        const tables = isQuery ? matchTables(e.target.value) : data.tables
        setData({ ...data, ...{ [e.target.name]: e.target.value }, ...{ tables: tables } })
        isQuery && setQuery(e.target.value);
        isQuery && setTables(tables);
    }

    const handleDelete = () => {
        dispatch(setModal({
            open: true,
            content: [
                { variant: 'body1', text: `Are you sure you want to delete ${data.Name} ?` }
            ],
            buttons: [
                { label: 'Delete', color: 'error', onClick: () => deleteItem() }
            ]
        }))
    }

    const saveItem = e => {
        e.preventDefault();
        const { PK, SK, LastModified, results, ...body } = { ...data, ...{ Type: "query" } }
        const urlEnd = createNew ? "" : `/${params.id}`
        // return
        API.put(
            'ApiDash',
            `${user.org}/queries${urlEnd}`,
            { body: body }
        ).then(response => {
            updateCache(dispatch, setQueries, [...queries], response, response.Id)
            if (createNew) {
                setTimeout(function () {
                    window.location.href = `/queries/${response.Id}`
                }, 1000);
            }
            else {
                setData(response);
            }
            dispatch(setSnackbar({ severity: 'success', message: 'Query Saved!' }))
        }).catch(error => {
            dispatch(setSnackbar({ severity: 'error', message: error.message }))
        });
    };

    const deleteItem = () => {
        dispatch(setSnackbar({ severity: 'info', message: `Deleting ${data.Name}` }))
        dispatch(setModal(null))
        API.del("ApiDash", `${user.org}/queries/${params.id}`).then(
            (response) => {
                dispatch(setSnackbar({ severity: 'success', message: `Deleted ${data.Name}` }))
                updateCache(dispatch, setQueries, [...queries], response, params.id, true)
                setTimeout(function () {
                    window.location.href = `/queries`
                }, 1000);
            }
        ).catch((error) => {
            dispatch(setSnackbar({ severity: 'error', message: error.message }))
        });
    };

    return data ?
        <Container>
            <Title
                page="Queries"
                value={data.Name}
                handler={handleChange}
            />
            <ActionButtons
                createNew={createNew}
                type="query"
                run={data}
                save={saveItem}
            />
            <Grid item xs={12} sx={{ mb: 2 }}>
                <SelectConnection
                    name="connection"
                    value={data.connection || ''}
                    handler={handleChange}
                    limit={{ SystemType: 'sqlserver' }}
                />
            </Grid>
            <QueryTabs
                id={params.id}
                query={query}
                tables={tables}
                handler={handleChange}
            />
        </Container>
        :
        <Grid item xs={12}><LinearProgress /></Grid>
}

export default Query;
