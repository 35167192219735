import React, { useEffect, useState } from 'react';
import { Avatar, Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { SystemConfig } from '../Config/SystemConfig';

const SystemIcons = (props) => {
    const systems = Object.keys(SystemConfig).filter(x => x !== '')

    const handleSystemChange = (e, value) => {
        props.changed({target: {name: 'system', value: value}})
    };

    return (
        <Box>
            <ToggleButtonGroup
                onChange={handleSystemChange}
                value={props.filter.system}
                exclusive={props.exclusive}
                sx={{
                    flexWrap: 'wrap',
                }}
            >
                {systems.map((item, index) => {
                    return (
                        <ToggleButton
                            key={index}
                            value={item}
                            color='primary'
                            sx={{ flexGrow: 0, p: 1, border: 'none' }}
                        >
                            <Avatar
                                src={SystemConfig[item].icon}
                                sx={{ width: 32, height: 32 }}
                            />
                        </ToggleButton>
                    )
                })}
            </ToggleButtonGroup>
        </Box>
    )
}

export default SystemIcons;