import React, { useState, useEffect } from "react";
import { Grid, InputLabel, Chip, LinearProgress, Typography } from '@mui/material';
import { matchTables } from '../Shared/Functions';
import { useSelector, useDispatch } from 'react-redux';
import Container from '../Shared/Container';
import ContainerRow from '../Shared/ContainerRow';
import Pod from '../Shared/Pod';

import CodeMirror from '@uiw/react-codemirror';
import { sql } from '@codemirror/lang-sql';
import { EditorView } from '@codemirror/view';

const FormSQLEditor = (props) => {
    const schemas = useSelector(state => (state.schemas || {}).value) || []
    const [data, setData] = useState({});
    const [query, setQuery] = useState('');

    useEffect(() => {
        setQuery(props.query)
    }, [props])

    // useEffect(() => {
    //     const delayDebounceFn = setTimeout(() => {
    //       console.log(query && query.length)
    //       // Send Axios request here
    //     //   props.handler({ target: { name: 'query', value: query }})
    //     }, 1000)

    //     return () => clearTimeout(delayDebounceFn)
    //   }, [query])


    return (
        <Grid item xs={12} sx={{ fontSize: '12px', '& .cm-editor': { border: '1px solid #eee', borderRadius: '4px' } }}>
            <InputLabel shrink>Query</InputLabel>
            <CodeMirror
                // value={data.query}
                value={query}
                theme="light"
                minHeight="200px"
                basicSetup={{
                    foldGutter: true
                }}
                extensions={[sql(), EditorView.lineWrapping]}
                // onChange={handleChange}
                // onChange={value => setQuery(value)}
                onChange={value => {
                    props.handler({ target: { name: 'query', value: value } })
                }}
            />
            {props.tables !== undefined && (
                <Container 
                    sx={{
                        mt: 2,
                        '& .MuiGrid-item': {  my: 'auto', px: 0.5 }
                    }}
                >
                    {props.tables.map((item, key) => {
                        const schema = item.split('.').slice(-2)[0]
                        const name = item.split('.').slice(-2)[1]
                        const schemaId = (schemas.find(x => x.SystemKey === schema && x.Name === name) || {}).Id
                        const href = `/schemas/${schemaId}`
                        return (
                            <Grid key={key}>
                                <Pod
                                    system={schema}
                                    handler={() => window.open(href, '_blank', 'noopener,noreferrer')}
                                >
                                    {/* <Typography>{name}</Typography> */}
                                    {name}
                                </Pod>
                            </Grid>
                        )
                    })}
                </Container>
            )}
        </Grid>
    )
}
export default FormSQLEditor;