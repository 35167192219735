import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import logo from '../../images/logo-min.svg';

const Loading = props => {
    const theme = useTheme();
    const [seconds, setSeconds] = useState(0);
    useEffect(() => {
        let secs = 0
        const interval = setInterval(() => {
            secs += 1
            setSeconds(secs)
        }, 50);
        return () => clearInterval(interval);
    }, [])
    return (
        <Grid item xs={12}
        sx={{
            borderRadius: '1rem',
            m: 1,
            // background: theme.palette.offwhite.main,
            color: theme.palette.primary.main,
            // border: '1px solid',
            '& .MuiBox-root': {display: 'inline-flex'},
            '& img': {
                height: 16,
                width: 16,
            }
        }}
        >
            {[...Array(seconds).keys()].map((item, key) => (
                <Box key={key}>.</Box>
            ))}
            <Box>
                <img src={logo} />
            </Box>
            <LinearProgress sx={{mt: -1}} />
        </Grid>
    )
}
export default Loading;