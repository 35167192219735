import React, { useState, useEffect } from "react";
import { useUser } from '../components/Auth/AuthContext';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setJobs } from '../components/Cache/jobsSlice';
import { setModal } from '../components/Cache/modalSlice';
import { setSnackbar } from '../components/Cache/snackbarSlice';
import { API } from "aws-amplify";
import _ from 'lodash';

import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Container from '../components/Shared/Container';
import Title from '../components/Shared/Title';

import { updateCache, makeJobPayload, jobIcons } from '../components/Shared/Functions';

import ActionButtons from '../components/Buttons/ActionButtons';
import JobDescription from '../components/Job/JobDescription';
import JobSchedule from '../components/Job/JobSchedule';
import JobConnections from '../components/Job/JobConnections';
import JobExecutions from '../components/Job/JobExecutions';
import JobRecords from '../components/Job/JobRecords';

const Job = () => {
    const createNew = (window.location.pathname.split('/').pop() === "new")
    const { user, setUser } = useUser();
    const params = useParams();
    const dispatch = useDispatch();

    const jobs = useSelector(state => state.jobs.value) || []
    const cached = jobs.find(x => x.Id === params.id) || {}
    const [data, setData] = useState();
    // const systems = data && data.Input && [
    //     {
    //         system: data.Input.source && data.Input.source.system,
    //         type: data.Input.source && data.Input.source.type
    //     },
    //     {
    //         system: data.Input.target && data.Input.target.system,
    //         type: data.Input.target && data.Input.target.type
    //     }
    // ]

    const systems = data && data.Input && jobIcons(data.Input)

    useEffect(() => {
        user.org && getData()
    }, [user])

    const getData = () => {
        if (createNew) { setData({}); return }
        API.get("ApiDash", `${user.org}/jobs/${params.id}`).then(
            (response) => {
                setData(response);

                // update cache if API response is different
                (_.difference(_.values(response), _.values(cached)) || []).length > 0 && updateCache(dispatch, setJobs, [...jobs], response, params.id);
            }
        ).catch((error) => {
            dispatch(setSnackbar({ severity: 'error', message: error.message }))
        });
    }

    const handleChange = e => {
        let payload = { [e.target.name]: e.target.value }

        // update Input and update Input.jobName if Name changed
        if (e.target.input || e.target.name === 'Name') {
            let key = e.target.name === 'Name' ? 'jobName' : e.target.input;
            let Name = e.target.name === 'Name' ? { 'Name': e.target.value } : {}
            payload = makeJobPayload(data, user.org, key, e.target.value, Name)
        }
        const stateMachine = data.Input && data.Input.source && data.Input.target && data.Input.source.system + '-' + data.Input.target.system

        const newData = {
            ...data,
            ...payload,
            ...{ StateMachine: stateMachine }
        }
        setData(newData)
    }

    const handleDelete = () => {
        dispatch(setModal({
            open: true,
            content: [
                { variant: 'body1', text: `Are you sure you want to delete ${data.Name} ?` }
            ],
            buttons: [
                { label: 'Delete', color: 'error', onClick: () => deleteItem() }
            ]
        }))
    }

    const saveItem = e => {
        e.preventDefault();
        const { PK, SK, LastModified, Executions, ...body } = { ...data, ...{ Type: "job" } }
        const urlEnd = createNew ? "" : `/${params.id}`
        dispatch(setSnackbar({ severity: 'info', message: 'Saving Job...', duration: 20000 }))
        API.put(
            'ApiDash',
            `${user.org}/jobs${urlEnd}`,
            { body: body }
        ).then(response => {
            dispatch(setSnackbar({ severity: 'success', message: 'Changes Saved!' }))
            updateCache(dispatch, setJobs, [...jobs], response, response.Id)
            if (createNew) {
                setTimeout(function () {
                    window.location.href = `/jobs/${response.Id}`
                }, 1000);
            }
            else {
                setData(response);
            }
            dispatch(setSnackbar({ severity: 'success', message: 'Job Saved!' }))
        }).catch(error => {
            dispatch(setSnackbar({ severity: 'error', message: error.message }))
        });
    };

    const deleteItem = () => {
        dispatch(setSnackbar({ severity: 'info', message: `Deleting ${data.Name}` }))
        dispatch(setModal(null))
        API.del("ApiDash", `${user.org}/jobs/${params.id}`).then(
            (response) => {
                dispatch(setSnackbar({ severity: 'success', message: `Deleted ${data.Name}` }))
                updateCache(dispatch, setJobs, [...jobs], response, params.id, true)
                setTimeout(function () {
                    window.location.href = `/jobs`
                }, 1000);
            }
        ).catch((error) => {
            dispatch(setSnackbar({ severity: 'error', message: error.message }))
        });
    };

    // Jobs-specific

    const handleEnable = e => {
        setData({ ...data, ...{ Status: e.target.checked ? "ENABLED" : "DISABLED" } })
    }

    return data ?
        <Container>
            <Grid item xs={12}>
                <Container>
                    <Title
                        page="Connections"
                        value={data.Name}
                        handler={handleChange}
                        systems={systems}
                    />
                    <ActionButtons
                        type="job"
                        createNew={createNew}
                        enabled={data.Status === "ENABLED"}
                        enable={handleEnable}
                        delete={handleDelete}
                        run={data}
                        save={saveItem}
                    />
                </Container>
            </Grid>
            <Grid item xs={12}>
                <JobDescription
                    value={data.Description}
                    handler={handleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <Container>
                    <Grid item xs={6} sx={{ pr: 1 }}>
                        <JobSchedule
                            // cols={12}
                            schedule={data.Schedule || ''}
                            handler={handleChange}
                        />
                        <JobConnections
                            input={data.Input || { source: {}, target: {} }}
                            handler={handleChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <JobExecutions
                            jobId={params.id}
                            executions={data.Executions}
                        />
                    </Grid>
                </Container>
            </Grid>

            <Grid item xs={12}>
                <JobRecords
                    input={data.Input || { source: {}, target: {}, records: [] }}
                    queries={data.Config}
                    handler={handleChange}
                />
            </Grid>

        </Container>
        :
        <Grid item xs={12}><LinearProgress /></Grid>
}

export default Job;