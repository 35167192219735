import React, { useState, useEffect } from "react";
import { useUser } from '../Auth/AuthContext';
import { useSelector, useDispatch } from 'react-redux';
import { setJobs } from '../Cache/jobsSlice';
import { setModal } from '../Cache/modalSlice';
import { setSnackbar } from '../Cache/snackbarSlice';
import { API } from '@aws-amplify/api';

import {
    Grid, Typography, Link
} from '@mui/material';

import ButtonTest from '../Buttons/ButtonTest';
import ButtonJobs from '../Buttons/ButtonJobs';
import ContainerRow from '../Shared/ContainerRow';
import IconSystem from '../Shared/IconSystem';

const RowConnection = props => {
    const dispatch = useDispatch();
    const { user, setUser } = useUser();
    const jobs = useSelector(state => state.jobs.value)
    const [row, setRow] = useState(props.row)
    const [matched, setMatched] = useState(props.matched)

    useEffect(() => {
        setRow(props.row)
        setMatched(props.matched)
    }, [props])

    const handleModal = () => {
        dispatch(setModal({
            open: true,
            content: [
                { variant: 'body2', text: 'Jobs that use' },
                { variant: 'h6', text: row.Name }
            ],
            matched: { jobs: matched },
            enable: handleJobEnable
        }))
    }

    const handleJobEnable = (e, jobId) => {
        dispatch(setSnackbar({ severity: 'info', message: 'Saving Changes' }))
        API.put("ApiDash", `${user.org}/jobs/${jobId}`, {
            body: { [e.target.name]: e.target.value }
        }).then(response => {
            let newJobs = [...jobs]
            newJobs[newJobs.findIndex(x => x.Id === jobId)] = response;
            dispatch(setJobs(newJobs))
            dispatch(setSnackbar({ severity: 'success', message: 'Changes Saved!' }))
        }).catch(error => {
            console.log(error);
            dispatch(setSnackbar({ severity: 'error', message: error }))
        });
    }
    !props.row.Credentials && console.log(props.row)
    return (
        <ContainerRow>
            <Grid item xs={6}>
                <IconSystem
                    system={row.SystemKey}
                    type={row.SystemType}
                    page="Connections"
                />
                <Link href={`/connections/${row.Id}`} underline="hover" target="_blank">
                    {row.Name || "_"}
                </Link>
            </Grid>
            <Grid item xs={3}>
                <Typography>{props.row.Credentials.url}</Typography>
            </Grid>
            <Grid item xs={3} sx={{ textAlign: 'right', '& .MuiButton-root': { ml: 1 } }}>
                <ButtonTest
                    disabled={true}
                    value="Test"
                />
                <ButtonJobs
                    disabled={(matched.length === 0)}
                    value={matched.length}
                    handler={handleModal}
                />
            </Grid>
        </ContainerRow>
    )
}

export default RowConnection;