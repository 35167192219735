import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'queries',
  initialState: {
    value: {}
  },
  reducers: {
    setQueries: (state, data) => {
      state.value = data.payload
      state.lastUpdated = new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString()
    }, 
    setResults: (state, data) => {
      state.results = data.payload
    }
  },
})

export const { setQueries, setResults } = slice.actions
export default slice.reducer