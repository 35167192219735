import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { setModal } from '../Cache/modalSlice';
import { setSnackbar } from '../Cache/snackbarSlice';
import { setExecutions } from '../Cache/executionsSlice';
import { useTheme } from '@mui/material/styles';
import { useUser } from '../Auth/AuthContext';
import { API } from "aws-amplify";
import {
    Grid, Link, IconButton
} from '@mui/material';
import { SystemConfig, getAction } from '../Config/SystemConfig';
import JobEnabled from '../Job/JobEnabled';
import JobExecutions from '../Job/JobExecutions';

import ButtonRows from '../Buttons/ButtonRows';
import ButtonRun from '../Buttons/ButtonRun';
import ContainerRow from '../Shared/ContainerRow';
import IconSystem from '../Shared/IconSystem';
import Time from '../Shared/Time';
import Gumball from '../Shared/Gumball';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { green, red } from '@mui/material/colors';

import Title from '../Shared/Title';
import { jobIcons } from '../Shared/Functions';

import actions from '../Config/actions';

const getTables = row => {
    const tbls = [...new Set((row.Config || []).map(x => [
        ...(x.query || '').matchAll(/(?:(?:from|join)(?:\s|\()+((?:\[*\w*\.\w*)+)(?:\s|\)|$))/gi)
    ].map(x => x[1])).flat())]
    return tbls
}

const RowJob = props => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { user, setUser } = useUser();

    const executions = useSelector(state => state.executions.value) || []

    const [row, setRow] = useState(props.row);
    const [data, setData] = useState(props.executions);
    const [tables, setTables] = useState(getTables(props.row));

    useEffect(() => {
        setRow(props.row)
        setData(props.executions)
        setTables(getTables(props.row))
    }, [props])

    const handleJobEnable = e => {
        dispatch(setSnackbar({ severity: 'info', message: 'Saving changes' }))
        props.enable(e, row.Id)
    }


    const handleJobRun = () => {
        dispatch(setSnackbar({ severity: 'info', message: `Starting ${row.Name}` }))
        props.run(row)
    }

    const handleModal = () => {
        dispatch(setModal({
            open: true,
            content: [
                ...[
                    { variant: 'body2', text: 'Tables referenced in' },
                    { variant: 'h6', text: row.Name },
                ],
                ...tables.map(x => ({ variant: 'body1', text: '' }))
            ],
            matched: {
                tables: tables
            }
        }))
    }

    const getAllExecutions = () => {
        const maxExecutions = 100
        setExecutions(null)
        API.get('ApiDash', `${user.org}/executions/`, {
            queryStringParameters: { jobId: row.Id || '-1' }
        }).then((response) => {
            // add this Job's Executions to cached executions if they aren't theyre already
            // add Name, JobId to values first
            // (they should align but those two fields are missing for some reason that is probably dumb)
            response = response.map(x => ({ ...x, ...{ Name: x.JobName, JobId: row.Id } }))
            console.log(response, row.Id)
            const newExecutions = [
                ...executions,
                ...response.splice(0, maxExecutions).filter(
                    x => !data.map(y => y.ExecutionID).includes(x.ExecutionID)
                )
            ].sort((a, b) => { return b.SK - a.SK })

            dispatch(setExecutions(newExecutions))
        }).catch(
            error => {
                console.log("There's an error: ", error)
            }
        )
    }

    return row.Input ?
        <ContainerRow>
            <Title
                page="Connections"
                value={row.Name || ''}
                systems={jobIcons(row.Input)}
                size={{ system: 'medium', type: 'small' }}
                href={`/jobs/${row.Id}`}
            />
            <Grid item xs={1}>
                {(actions[row.Input.source.action] || {}).icon}
                {(actions[row.Input.target.action] || {}).icon}
                {/* {row.Input.records.filter(x=>(x.queryId !== undefined)).length}
                {row.Input.records.length} */}
            </Grid>
            <JobEnabled
                cols={1}
                status={row.Status}
                handler={handleJobEnable}
                hideLabel
            />
            <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'right', pr: 2 }}>
                {/* {row.Schedule && <Time cron={row.Schedule} />} */}
                <Time cron={row.Schedule} />
            </Grid>
            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'right' }}>
                <Gumball setData={getAllExecutions}>
                    {data && data.map((item, key) => {
                        const shade = Math.floor(5 - (Date.now() - new Date(item.StartTime)) / 86400000 / 2) * 100
                        const url = `/executions/${item.StateMachine}:${item.ExecutionID}`
                        return item && 
                            <IconButton
                                key={key}
                                size="small"
                                color="offwhite"
                                onClick={() => window.open(url, '_blank', 'noopener,noreferrer')}
                                sx={{
                                    height: 24,
                                    width: 24,
                                    mr: 0.5,
                                    background: item.Status === 'Succeeded' ? green[shade] : red[shade],
                                    '& .MuiSvgIcon-root': {
                                        color: 'white'
                                    },
                                }}
                            >
                                {item.Status === 'Succeeded' ? <CheckIcon /> : <ClearIcon />}
                            </IconButton>
                        
                    })}

                </Gumball>
            </Grid>
            <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonRows
                    disabled={!(tables.length)}
                    value={tables.length}
                    handler={handleModal}
                />
            </Grid>
            <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ButtonRun
                    type="job"
                    run={row}
                />
            </Grid>
        </ContainerRow>
        :
        null

}

export default RowJob;