import React, { useState, useEffect } from "react";
import { useUser } from '../components/Auth/AuthContext';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setQueries } from '../components/Cache/queriesSlice';
import { setSnackbar } from '../components/Cache/snackbarSlice';
import { API } from "aws-amplify";

import Grid from '@mui/material/Grid';

import { SystemConfig } from '../components/Config/SystemConfig';
import ActionButtons from '../components/Buttons/ActionButtons';
import Title from '../components/Shared/Title';
import SelectConnection from '../components/Select/SelectConnection';
import ReportTabs from '../components/Report/ReportTabs';

const createNew = (window.location.pathname.split('/').pop() === "new")
const blankNew = (user) => {
    return ({
        Type: 'report',
        Name: "",
        query: "",
        connection: "",
        data: [],
    })
}

const Report = props => {
    const { user, setUser } = useUser();
    const params = useParams()
    const dispatch = useDispatch()
    const connections = useSelector(state => (state.connections.value))

    const [data, setData] = useState(blankNew(user));
    const [isLoading, setIsLoading] = useState(true);

    const options = connections.filter(x => x.SystemType === 'sqlserver').map(x => ({
        value: x.Id,
        label: x.Name,
        system: x.SystemKey,
        type: x.SystemType,
        icon: SystemConfig[x.SystemKey || ''].icon
    }))

    useEffect(() => {
        if (user.org && createNew) {
            setData(blankNew(user));
            setIsLoading(false);
        } else if (user.org) {
            getData()
        }
    }, [user]);

    const getData = () => {
        if (createNew) { setData({}); return }
        API.get("ApiDash", `${user.org}/reports/${params.id}`)
            .then((response) => {
                // Response is a list with metadata item
                console.log('Report: ', response)
                setData(response)
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("There's an error: ", error);
            });
    }

    const handleChange = e => {
        console.log(e.target.name, e.target.value)
        setData({...data, ...{[e.target.name]: e.target.value}})
    }

    const saveChanges = e => {
        e.preventDefault();
        const { PK, SK, LastModified, ...body } = data
        API.put(
            'ApiDash',
            `${user.org}/reports/${params.id || ''}`,
            { body: body }
        ).then(
            response => {
                if (createNew) {
                    // redirect to new job page
                    window.location.href = `/reports/${response.id}`
                }
                else {
                    setData(response);
                }
                console.log("Saved report: ", response)
                dispatch(setSnackbar({ severity: 'success', message: 'Report Saved!' }))
            }
        ).catch(
            error => {
                console.log("There's an error: ", error)
            }
        )
    };

    const handleRunQuery = e => {
        e.preventDefault();
        dispatch(setSnackbar({ severity: 'info', message: 'Report Running!' }))
        // const {data, ...newData} = data
        // console.log(newData.data)
        console.log(data)
        API.post(
            'ApiDash',
            `${user.org}/queries`,
            { body: data }
        ).then(
            response => {
                setData({...data, ...{data: response}})
                dispatch(setQueries({ [params.id]: response }))
                dispatch(setSnackbar({ severity: 'success', message: 'Report Finished!' }))
            }
        ).catch(
            error => {
                console.log("There's an error: ", error, typeof error)
                dispatch(setSnackbar({ severity: 'error', message: JSON.stringify(error), duration: null }))
            }
        )
    }

    return isLoading ? null :
        <Grid container spacing={2}>
            <Title
                page={"Reports"}
                value={data.Name}
                handler={handleChange}
            />
            <ActionButtons
                cols={4}
                // delete={handleDelete}
                run={handleRunQuery}
                save={saveChanges}
            />

            <Grid item xs={12}>
                <SelectConnection
                    name="connection"
                    value={data.connection || ''}
                    handler={handleChange}
                    limit={{SystemKey: 'sqlserver'}}
                />
            </Grid>
            <Grid item xs={12}>
                <ReportTabs 
                    id={params.id}
                    data={data}
                    handler={handleChange}
                    // run={handleRunQuery}
                />
            </Grid>
        </Grid>
}

export default Report;
