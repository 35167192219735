import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setModal } from '../Cache/modalSlice';
import ContainerRow from '../Shared/ContainerRow';
import {
    Grid, Link, LinearProgress, Typography, Box
} from '@mui/material';

import ButtonJobs from '../Buttons/ButtonJobs';
import ButtonRows from '../Buttons/ButtonRows';
import IconSystem from '../Shared/IconSystem';
import pluralize from 'pluralize';

const Row = props => {
    const dispatch = useDispatch();
    const [row, setRow] = useState(props.row)
    const [matched, setMatched] = useState(props.matched)

    useEffect(() => {
        setRow(props.row)
        setMatched(props.matched)
    }, [props])

    const handleRowChange = e => {
        const value = (e.target.name === 'primary_key') ? e.target.checked : e.target.value
        const newRow = { ...row, ...{ [e.target.name]: value } }
        setRow(newRow)
        props.handler(newRow)
    }

    const handleModal = () => {
        dispatch(setModal({
            open: true,
            content: [
                { variant: 'body2', text: 'Jobs that use' },
                { variant: 'h6', text: row.Name }
            ],
            matched: { jobs: matched.jobs }
        }))
    }

    return (
        <ContainerRow>
            <Grid item xs={6}>
                {matched && matched.connection &&
                    <IconSystem
                        system={matched.connection.SystemKey}
                        type={matched.connection.SystemType}
                        color={matched.connection.SystemType}
                        page="Connections"
                    />
                }
                <Link href={`/${pluralize(row.Type || '')}/${row.Id}`} underline="hover" target="_blank">
                    {row.Name || "_"}
                </Link>
            </Grid>
            <Grid item xs={3}>
                {matched && matched.tables &&
                    Object.entries(matched.tables).map(([system, tables], key) => (
                        <IconSystem
                            key={key}
                            system={system}
                            type={tables.length}
                            page="Connections"
                        // size={{ system: 'large', type: 'medium' }}
                        />
                    ))
                }
            </Grid>
            <Grid item xs={3} sx={{ textAlign: 'right' }}>
                {matched && matched.results &&
                    <ButtonRows
                        disabled={(matched.results.length === 0)}
                        value={matched.results.length}
                        handler={handleModal}
                    />

                }
                {matched && matched.jobs &&
                    <ButtonJobs
                        disabled={(matched.jobs.length === 0)}
                        value={matched.jobs.length}
                        handler={handleModal}
                    />
                }
                {/* <ButtonRows
                    disabled={(Object.keys(row.Fields).length === 0)}
                    value={Object.keys(row.Fields).length}
                /> */}
            </Grid>

        </ContainerRow>
    )
}

export default Row