import React, { useState, useEffect } from "react";
import { useUser } from '../Auth/AuthContext';
import { useSelector, useDispatch } from 'react-redux';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { store } from '../Cache/store';
import ContainerRow from '../Shared/ContainerRow';

import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';
import { sortResponse } from '../Shared/Functions';

import RefreshIcon from '@mui/icons-material/Refresh';

import { setConnections } from '../Cache/connectionsSlice';
import { setExecutions } from '../Cache/executionsSlice';
import { setJobs } from '../Cache/jobsSlice';
import { setQueries } from '../Cache/queriesSlice';
import { setResults } from '../Cache/resultsSlice';
import { setSchemas } from '../Cache/schemasSlice';
import { setUsers } from '../Cache/usersSlice';

import { setSnackbar } from '../Cache/snackbarSlice';

import CircularProgress from "@mui/material/CircularProgress";

import Config from '../../config';
import { API } from '@aws-amplify/api';
import { useTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';


export default function CacheMenu() {
    const theme = useTheme();
    const { user, setUser } = useUser();

    const dispatch = useDispatch()
    dayjs.extend(relativeTime);
    dayjs.extend(updateLocale);
    dayjs.updateLocale('en', {
        relativeTime: {
            future: "in %s",
            past: "%s ago",
            s: "%ds",
            m: "1m",
            mm: "%dm",
            h: "1h",
            hh: "%dh",
            d: "1d",
            dd: "%dd",
            M: "1m",
            MM: "%dm",
            y: "1y",
            yy: "%dy"
        }
    })

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const state = store.getState()

    const connections = useSelector(state => state.connections);
    const executions = useSelector(state => state.executions);
    const jobs = useSelector(state => state.jobs);
    const queries = useSelector(state => state.queries);
    const schemas = useSelector(state => state.schemas);
    const users = useSelector(state => state.users);

    const UserPoolId = Config.Auth.userPoolId

    const fn = {
        connections: setConnections,
        executions: setExecutions,
        jobs: setJobs,
        queries: setQueries,
        results: setResults,
        schemas: setSchemas,
        users: setUsers
    }

    useEffect(() => {
        user.org && getConnections();
        user.org && getExecutions();
        user.org && getJobs();
        user.org && getQueries();
        user.org && getSchemas();
        user.org && getUsers();
    }, [connections, executions, jobs, queries, schemas, users]);

    const getConnections = () => {
        if (connections.value && connections.value.length > 0) { return }
        API.get('ApiDash', `${user.org}/connections/`).then(
            response => {
                response = sortResponse(response, "Name", "alpha")
                dispatch(setConnections(response))
            }
        ).catch(
            error => {
                console.log("There's an error: ", error)
                dispatch(setSnackbar({ severity: 'error', message: JSON.stringify(error), duration: 20000 }))
            }
        )
    }

    const getExecutions = () => {
        // theshold may be the thing triggering these infinite execution request loops
        // const threshold = (dayjs().diff(dayjs((executions || {}).lastUpdated), 'hours') > 24)
        // if (executions.value && executions.value.length > 0 && !threshold) { return }
        if (executions.value && executions.value.length > 0) { return }
        API.get('ApiDash', `${user.org}/executions?hours=24`)
            .then(response => {
                // map JobName and JobId from execution details for link to work in RowExecution
                // filter out Initiated (i.e. unfinished) for now
                response = response.map(x => ({ ...x, ...{ Name: x.JobName }, ...{ JobId: (x.GSI1PK || '').substring(4) } })).filter(x => x.Status !== 'Initiated')
                response = sortResponse(response, "SK", "number")
                dispatch(setExecutions(response))
            })
            .catch(error => {
                dispatch(setSnackbar({ severity: 'error', message: JSON.stringify(error), duration: 20000 }))
            });
    }

    const getJobs = () => {
        if (jobs.value && jobs.value.length > 0) { return }
        API.get("ApiDash", `${user.org}/jobs`)
            .then((response) => {
                response = sortResponse(response, "Name", "alpha")
                dispatch(setJobs(response))
            })
            .catch((error) => {
                dispatch(setJobs([]))
                dispatch(setSnackbar({ severity: 'error', message: JSON.stringify(error), duration: 20000 }))
            });
    }

    const getQueries = () => {
        if (queries.value && queries.value.length >= 0) { return }
        API.get('ApiDash', `${user.org}/queries`)
            .then(response => {
                response = sortResponse(response, "Name", "alpha").map(x => ({ ...x, ...{ results: [] } }))
                dispatch(setQueries(response))
            })
            .catch(error => {
                dispatch(setSnackbar({ severity: 'error', message: JSON.stringify(error), duration: 20000 }))
            });
    }

    const getSchemas = () => {
        if (schemas.value && schemas.value.length > 0) { return }
        API.get('ApiDash', `${user.org}/schemas`).then(
            response => {
                response = sortResponse(response, "Name", "alpha")
                console.log(response)
                dispatch(setSchemas(response))
            }
        ).catch(
            error => {
                console.log("There's an error: ", error)
                dispatch(setSnackbar({ severity: 'error', message: JSON.stringify(error), duration: 20000 }))
            }
        )
    }

    const getUsers = () => {
        if (users.value && users.value.length > 0) { return }
        API.get('ApiDash', `${user.org}/users`)
            .then(response => {
                const users = response.map(x => ({ ...x, Username: x.Email })).filter(x => x.UserPoolId === UserPoolId)
                dispatch(setUsers(users))
            })
            .catch(error => {
                console.log(error)
                dispatch(setSnackbar({ severity: 'error', message: JSON.stringify(error), duration: 20000 }))
            });
    }

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Cache Status">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {Object.keys(fn).map((type, key) => {
                    const item = state[type] || {}
                    const color = item.lastUpdated && (dayjs().diff(item.lastUpdated, 'hour'))

                    return (
                        <MenuItem key={key} sx={{ '& .MuiGrid-root': { px: 0.5, justifyContent: 'center' } }}>
                            <ContainerRow>
                                <Grid>
                                    <IconButton
                                        onClick={() => dispatch(fn[type](null))}
                                    >
                                        <Badge
                                            badgeContent={item.lastUpdated && dayjs(item.lastUpdated).fromNow(true)}
                                            color={color > 24 ? "error" : color > 12 ? "secondary" : "offwhite"}
                                            anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                                        >
                                            <RefreshIcon />
                                        </Badge>
                                    </IconButton>
                                </Grid>
                                <Grid item xs>
                                    {type}
                                </Grid>
                                <Grid sx={{ minWidth: 48 }}>
                                    {item.value === null ?
                                        <CircularProgress size={20} onClick={() => dispatch(fn[type]([]))} /> :
                                        <Chip label={item.value.length || Object.keys(item.value).length} size="small" />
                                    }
                                </Grid>
                            </ContainerRow>
                        </MenuItem>
                    )
                }
                )}
            </Menu>
        </React.Fragment>
    );
}