import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from '@mui/material/styles';

const ButtonNew = props => {
    const theme = useTheme();
    const btnProps = {
        variant: props.variant || "text",
        color: props.mini ? "offwhite" : props.small ? "neutral" : props.color || "primary",
        onClick: props.handler,
        href: props.href,
        target: "_blank",
    }
    const sx = {
        background: theme.palette[props.color || 'neutral'].main,
        p: 0.5
    }

    return props.small
        ?
        <IconButton {...btnProps} sx={props.mini && sx}>
            <AddIcon />
        </IconButton>
        :
        <Button
            {...btnProps}
            startIcon={<AddIcon />}
        >
            New {props.type || ''}
        </Button>
}
export default ButtonNew