import Grid from '@mui/material/Grid';
const ContainerSub = props => (
    <Grid
        container
        spacing={1}
        sx={{
            border: '1px solid #ddd',
            '& .MuiGrid-item': { my: 'auto' },
            borderRadius: '1rem',
            pb: 1,
            my: 2
        }}
    >
        {props.children}
    </Grid >
)
export default ContainerSub;