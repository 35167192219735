import { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, LabelList } from 'recharts';
import Grid from '@mui/material/Grid';
import Container from '../Shared/Container';
import FormSelect from '../Form/FormSelect';

const Barchart = props => {
    const [data, setData] = useState([]);
    const [dims, setDims] = useState([]);
    const [dim, setDim] = useState("");
    const [facts, setFacts] = useState([]);
    const [fact, setFact] = useState("");

    const _reduce = filter => {
        console.log(filter)
        return [...props.data.map(
            x => Object.keys(x).filter(y => typeof (x[y]) === (filter ? filter : typeof (x[y])))
        ).reduce((a, b) => new Set([...a, ...b]), [])]
    }

    useEffect(() => {
        const newFacts = _reduce("number")
        setData(props.data)
        setDims(_reduce().filter(x => !newFacts.includes(x)))
        setFacts(newFacts)
        // const keys = [...props.data.map(x => Object.keys(x)).reduce((a, b) => new Set([...a, ...b]), [])]
        // setFacts([
        //     ...props.data.map(
        //         x => Object.keys(x).filter(y => typeof (x[y]) === 'number')
        //     ).reduce((a, b) => new Set([...a, ...b]), [])]
        // )
    }, [props]);
    console.log(data, facts, dims)
    return (
        <Container sx={{ height: '75vh' }}>
            <Grid item xs={2}>
                <Container>
                    <Grid item xs={12}>
                        <FormSelect 
                            label="Dims"
                            value={dim}
                            options={dims.map(x => ({ value: x, label: x }))}
                            handler={e=>setDim(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormSelect
                        label="Facts"
                        value={fact}
                        options={facts.map(x => ({ value: x, label: x }))}
                        handler={e=>setFact(e.target.value)}
                    />
                    </Grid>
                </Container>
            </Grid>
            <Grid item xs={10}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart width={150} height={40} data={props.data} margin={{ top: 30 }}>
                        <XAxis dataKey={dim} angle={-60} textAnchor='end' height={300} label={dim} />
                        <YAxis label={fact} />
                        <Bar dataKey={fact} fill="#8884d8">
                            <LabelList dataKey={fact} position="top" />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </Grid>
        </Container>
    )
};

export default Barchart;