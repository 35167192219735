import Container from '../Shared/Container';
import ContainerSub from '../Shared/ContainerSub';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import ExecutionDetailRow from './ExecutionDetailRow';
import Subtitle from '../Shared/Subtitle';
import { executionStepPayload } from '../Shared/Functions';
import emap from './ExecutionDetailMap';

const ExecutionDetails = props => {
    const data = props.data
    const maproot = x => (emap[x.type] || {})

    const makeBlock = first => {
        var steps = [first]
        var i = 1
        while (true) {
            const step = data.filter(x => x.previousEventId === steps[i - 1].id)
            if (step.length > 0) {
                i += 1
                steps = [...steps, ...step]
            } else {
                i = 1
                break
            }
        }
        return steps
    }

    const makeSections = data => {
        var sections = {}
        data.map(x => {
            sections[maproot(x).parent || "Other"] = [
                ...(sections[maproot(x).parent || "Other"] || []),
                ...[{
                    ...x,
                    ...{
                        data: {
                            ...executionStepPayload(x).payload,
                            ...x[maproot(x).key]
                        }
                    }
                }]
            ]
        })
        return sections
    }

    const iterations = data.filter(x => x.type === "MapIterationStarted").map(x => makeBlock(x))
    const range = new Date(data.slice(-1)[0].timestamp) - new Date(data[0].timestamp)
    // console.log(props.output)
    
    return (
        <Container>
            <Grid item xs={12}>
                <ContainerSub>
                    <Subtitle type="Execution" />
                    <ExecutionDetailRow
                        item={makeSections([data[0]]).Executions[0]}
                        first={data[0]}
                        last={data.slice(-1)[0]}
                        range={range}
                        maproot={m => maproot(m)}
                        error={props.output && props.output.error && props.output.error}
                    />
                </ContainerSub>
            </Grid>
            
            <Grid item xs={12}>
                <ContainerSub>
                    <Subtitle type="Step" />
                    {iterations.map((iteration, key) => {
                        const sections = makeSections(iteration)
                        const tasks = Object.entries(sections).filter(([k, v]) => k === 'Tasks')
                        return (
                            <Grid key={key} item xs={12}
                                sx={{ borderBottom: '1px dotted #ccc' }}
                            >
                                {tasks.map(([type, items], key) => {
                                    const starts = items.filter(x => maproot(x).type === 'start')
                                    const ends = items.filter(x => maproot(x).type === 'end')
                                    return (
                                        <Container key={key}>
                                            {starts.map((x, i) => (
                                                <ExecutionDetailRow
                                                    key={i}
                                                    item={x}
                                                    first={data[0]}
                                                    last={ends[i]}
                                                    range={range}
                                                    maproot={m => maproot(m)}
                                                />
                                            ))}
                                        </Container>
                                    )
                                }
                                )}
                            </Grid>
                        )
                    })}
                </ContainerSub>
            </Grid>
        </Container>
    )
}
export default ExecutionDetails;