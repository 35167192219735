import { Box, Typography, Link } from '@mui/material';

const SectionHeader = props => {
    return (
    <Box style={{ margin: 'auto', display: 'flex' }} >
        <img src={props.logo} alt="dash" style={{ maxHeight: '45px' }} />
        <Link
            variant="h5"
            underline="none"
            href={"/" + props.href}
            sx={{ lineHeight: '45px', fontWeight: 'bolder' }}
        >
            {(props.pages.find(x => x.path === '/' + props.href) || {}).page}
        </Link>

    </Box>
)
    };

export default SectionHeader;