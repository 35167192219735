import { useState, useEffect } from 'react';
import { useUser } from '../Auth/AuthContext';
import { API } from '@aws-amplify/api';
import { useSelector, useDispatch } from 'react-redux';
import { setResults } from '../Cache/resultsSlice';
import { setSnackbar } from '../Cache/snackbarSlice';
import Button from "@mui/material/Button";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const ButtonRun = props => {
    const dispatch = useDispatch()
    const { user, setUser } = useUser();
    const results = useSelector(state => state.results.value)

    const handleRunJob = () => {
        const job = { ...props.run }
        const body = {
            StateMachine: job.StateMachine,
            Input: { ...job.Input, jobId: job.Id, jobName: job.Name, jobId: job.Id || job.JobId }
        };
        dispatch(setSnackbar({ severity: 'info', message: `Starting ${job.Name}...` }))
        API.post("ApiDash", `${user.org}/executions`, { body: body }).then(
            (response) => {
                dispatch(setSnackbar({ severity: 'success', message: `Started ${job.Name}!` }))
            }
        ).catch((error) => {
            dispatch(setSnackbar({ severity: 'error', message: error.message, duration: null }))
        });
    }

    const updateQueries = data => {
        const key = props.run.Id
        const newData = {...results}
        newData[key] = data
        console.log(newData)
        dispatch(setResults(newData))
        // try {
        //     dispatch(setResults(newData))
        // } catch (error) {
        //     dispatch(setSnackbar({ severity: 'error', message: JSON.stringify(error), duration: 20000 }))
        // }
       
    }

    const handleRunQuery = () => {
        updateQueries()
        dispatch(setSnackbar({ severity: 'info', message: 'Query Running', duration: 30000, timer: true }))
        API.post(
            'ApiDash',
            `${user.org}/queries`,
            { body: props.run }
        ).then(
            response => {
                dispatch(setSnackbar({ severity: 'success', message: 'Query Finished!' }))
                updateQueries(response)
            }
        ).catch(
            error => {
                console.log(error.response.data)
                dispatch(setSnackbar({ severity: 'error', message: error.response.data.errorMessage, duration: 60000 }))
            }
        )
    }

    const handleRun = () => {
        const type = props.type;
        console.log(type);
        type === "job" && handleRunJob();
        type === "query" && handleRunQuery();
    };

    return (
        <Button
            variant={props.variant || "text"}
            color={props.color || "primary"}
            disableElevation
            startIcon={<PlayArrowIcon />}
            onClick={handleRun}
        >
            Run
        </Button>
    )
}
export default ButtonRun