import { useState, useEffect } from 'react';
import { Grid, Checkbox, InputLabel, Typography, InputAdornment } from '@mui/material';
import FormSelect from '../Form/FormSelect';
import FormTextField from '../Form/FormTextField';
import FormCheckbox from '../Form/FormCheckbox';
import Container from '../Shared/Container';
import ContainerRow from '../Shared/ContainerRow';
import ButtonOrder from '../Buttons/ButtonOrder';
import ButtonDelete from '../Buttons/ButtonDelete';

const options = [
    { value: 'string', label: 'string' },
    { value: 'integer', label: 'integer' },
    { value: 'float', label: 'float' },
    { value: 'date', label: 'date' },
    { value: 'datetime', label: 'datetime' },
    { value: 'boolean', label: 'boolean' }
]

const SchemaField = props => {
    const [item, setItem] = useState(props.item)

    useEffect(() => {
        setItem(props.item)
    }, [props.item])

    return (
        <ContainerRow header={props.header} subsection>
            <Grid item xs={1} sx={{ textAlign: 'center', visibility: props.header && 'hidden' }}>
                <ButtonOrder
                    order={item.column_order}
                    handler={props.handler}
                    name="column_order"
                />
            </Grid>
            <Grid item xs={7}>
                <FormTextField
                    name='name'
                    label={props.header && 'Field Name'}
                    value={item.name || ''}
                    handler={props.handler}
                    header={props.header}
                    disableUnderline
                />
            </Grid>
            <Grid item xs={2}>
                <FormSelect
                    name='type'
                    label={props.header && 'Type'}
                    value={item.type || ''}
                    options={options}
                    handler={props.handler}
                    header={props.header}
                    disableUnderline
                />
            </Grid>
            <Grid item xs={1} sx={{ textAlign: 'right', '& .MuiCheckbox-root': { py: 0.5 } }}>
                <FormCheckbox
                    name="primary_key"
                    label={props.header && "PK"}
                    checked={item.primary_key || false}
                    handler={props.handler}
                />
            </Grid>
            {!props.header &&
                <Grid item xs={1} sx={{ justifyContent: 'right' }}>
                    <ButtonDelete
                        small
                        delete={() => props.handler({ target: { name: "delete" } })}
                    />
                </Grid>
            }

        </ContainerRow>
    )
}
export default SchemaField