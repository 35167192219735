import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';

import Grid from '@mui/material/Grid';
import SettingsIcon from '@mui/icons-material/Settings';

import { SystemConfig } from '../Config/SystemConfig';
import Container from '../Shared/Container';
import SelectConnection from '../Select/SelectConnection';
import FormSelect from '../Form/FormSelect';


const JobConnections = props => {
    const connections = useSelector(state => state.connections.value) || []
    const [input, setInput] = useState(props.input);
    const [actionsSource, setActionsSource] = useState([])
    const [actionsTarget, setActionsTarget] = useState([])

    useEffect(() => {
        props.input.source && makeOptions('source', props.input.source.id)
        props.input.target && makeOptions('target', props.input.target.id)
        setInput(props.input)
    }, [props])

    const upper = type => (type.charAt(0).toUpperCase() + type.slice(1));

    const makeOptions = (node, value) => {
        const c = connections.find(x => x.Id === value) || []
        const types = SystemConfig[c['SystemKey'] || ""].types[c['SystemType'] || ""][node]

        // update options if connection has changed
        if (value !== input[node].value) {
            const newOpts = Object.entries(types).map(x => ({
                value: x[0],
                label: x[1].label,
                icon: x[1].icon || <SettingsIcon />//(filterTypes.type.find(y => y.value === x[0]) || {}).icon || <SettingsIcon />
            }))
            node === 'source' && setActionsSource(newOpts)
            node === 'target' && setActionsTarget(newOpts)
        }

        // return matched connection and types
        return [c, types]
    }

    const handleChange = (e, node) => {
        // const id = (e.target.name === 'id') ? e.target.value : input[node].id
        const id = (e.target.name === 'connection') ? e.target.value : input[node].id
        const action = (e.target.name === 'action') ? e.target.value : input[node].action
        const [c, types] = makeOptions(node, id)
        const newInput = {
            ...input, ...{
                [node]: {
                    id: c.Id,
                    connectionName: c.Name,
                    system: c.SystemKey,
                    type: c.SystemType,
                    action: Object.keys(types).includes(input[node].action) ? action : Object.keys(types)[0]
                }
            }
        }
        setInput(newInput)
        props.handler({ target: { name: 'Input', value: newInput } })
    }

    return (
        <Container>
            {['source', 'target'].map((node, key) => (
                <Grid item xs={6} key={key}>
                    <Container>
                        <Grid item xs={12}>
                            <SelectConnection
                                name="connection"
                                label={upper(node)}
                                value={(input[node] || '').id}
                                handler={e => handleChange(e, node)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSelect
                                name='action'
                                label={`${upper(node)} Action`}
                                value={input[node].action || ''}
                                options={(node === 'source' ? actionsSource : actionsTarget) || []}
                                handler={e => handleChange(e, node, 'action')}
                            />
                        </Grid>
                    </Container>
                </Grid>
            ))}
        </Container>
    )
}

export default JobConnections;