import { useState, useEffect } from "react";
import { useUser } from '../components/Auth/AuthContext';
import { useSelector, useDispatch } from 'react-redux';
import { setJobs } from '../components/Cache/jobsSlice';
import { setModal } from '../components/Cache/modalSlice';
import { setSnackbar } from '../components/Cache/snackbarSlice';
import { API } from '@aws-amplify/api';

import Grid from '@mui/material/Grid';
import Loading from '../components/Shared/Loading';

import Container from '../components/Shared/Container';
import ControlPanel from '../components/Shared/ControlPanel';
import Header from '../components/Shared/Header';
import RowJob from '../components/Rows/RowJob';

import BoltIcon from "@mui/icons-material/Bolt";
import StorageIcon from "@mui/icons-material/Storage";

import actions from '../components/Config/actions';

const Jobs = () => {
    const dispatch = useDispatch()

    const { user, setUser } = useUser();
    const jobs = useSelector(state => state.jobs.value)
    const executions = useSelector(state => state.executions.value)
    const queries = useSelector(state => state.queries.value) || []

    const [data, setData] = useState(jobs || []);
    const [filter, setFilter] = useState({});
    // const [tables, setTables] = useState([...new Set(queries.map(x=>x.tables).flat())]);

    //     const optionsActions = Object.entries(Object.keys(actions).sort().reduce(
    //         (obj, key) => {
    //             obj[key] = actions[key];
    //             return obj;
    //         },
    //         {}
    //     )).map(([name, item]) => ({
    //         label: item.label,
    //         value: name,
    //         icon: item.icon
    //     }))
    // }
    const options = {
        // action: Object.entries(Object.keys(actions).sort().reduce(
        //     (obj, key) => {
        //         obj[key] = actions[key];
        //         return obj;
        //     },
        //     {}
        // )).map(([name, item]) => ({
        //     label: item.label,
        //     value: name,
        //     icon: item.icon
        // }))
        action: Object.entries(actions).map(([name, item]) => ({
            label: item.label,
            value: name,
            icon: item.icon
        }))
    }

    // }

    // useEffect(() => {
    // dispatch(setModal(null))
    // user.org && getData()
    // }, [user, jobs])

    useEffect(() => {
        setData(jobs || [])
    }, [jobs])

    useEffect(() => {
        handleFilter()
    }, [filter])


    const handleJobEnable = (e, jobId) => {
        API.put("ApiDash", `${user.org}/jobs/${jobId}`, {
            body: { [e.target.name]: e.target.value }
        }).then(response => {
            let newJobs = [...jobs]
            newJobs[newJobs.findIndex(x => x.Id === jobId)] = response;
            dispatch(setJobs(newJobs))
            setData(newJobs)
            dispatch(setSnackbar({ severity: 'success', message: 'Changes Saved!' }))
        }).catch(error => {
            console.log(error);
            dispatch(setSnackbar({ severity: 'error', message: error }))
        });
    }

    const matchQueryJobs = records => {
        records.map(x=>x.queryId)
    }

    // const handleFilter = filter => {
    const handleFilter = () => {
        let newData = ([...jobs] || []).filter(x => (
            ((filter.search || '').length > 0 ? (x.Name || '').toLowerCase().includes(filter.search.toLowerCase()) : true) &&
            ((filter.Status || '').length > 0 ? x.Status === filter.Status : true) &&
            ((filter.SQLServer || '').length > 0 ? x.StateMachine && x.StateMachine.split('-')[filter.SQLServer === 'source' ? 0 : 1] === "sqlserver" : true) &&
            ((filter.action || '').length > 0 ? [x.Input.source.action, x.Input.target.action].includes(filter.action) : true) &&
            // ((filter.table || '').length > 0 ? x.Config.some(x => x.tables.includes(filter.table)) : true) &&
            // ((filter.table || '').length > 0 ? x.Input.records.map(x=>queries.filter(x=>x.tables.includes(filter.table)).map(x=>x.id).includes(x.queryId)) : true) &&
            ((filter.system || []).length > 0 ? filter.system.some(system => x.StateMachine.split('-').indexOf(system) >= 0) : true)
        ))
        setData(newData)
    }

    return (
        <Container>
            <Header
                type="Job"
                setData={setJobs}
                data={data}
            />

            {/* Control Panel */}
            {console.log(queries)}
            <ControlPanel
                page="Jobs"
                handler={f => setFilter(f)}
                selects={[
                    { name: 'action', label: 'Action', icon: <BoltIcon />, options: options.action },
                    { name: 'table', label: 'Table', icon: <StorageIcon />, options: [...new Set(queries.map(x=>x.tables).flat())].map(x => ({ label: x, value: x })) }
                ]}
            />

            {/* Results */}
            {jobs === null
                ?
                <Loading />
                :
                <Grid item xs={12}>
                    <Container>
                        {data && data.map((item, key) => {
                            return (
                                <RowJob
                                    key={key}
                                    row={item}
                                    executions={item.Executions || (executions || []).filter(x => x.JobId === item.Id).slice(0, 5)}
                                    enable={handleJobEnable}
                                    // run={handleJobRun}
                                    snackbar={msg => setSnackbar(msg)}
                                />
                            )
                        })}
                    </Container>
                </Grid>
            }
        </Container >
    );
};

export default Jobs;
