import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setSchemas } from '../components/Cache/schemasSlice';
import { setSnackbar } from '../components/Cache/snackbarSlice';
import { setModal } from '../components/Cache/modalSlice';
import { useUser } from '../components/Auth/AuthContext';
import { API } from "aws-amplify";
import _ from 'lodash';

import Filters from '../components/Shared/Filters';

import { updateCache } from '../components/Shared/Functions';
import ActionButtons from '../components/Buttons/ActionButtons';
import Container from '../components/Shared/Container';
import ContainerSub from '../components/Shared/ContainerSub';
import Title from '../components/Shared/Title';
import Subtitle from '../components/Shared/Subtitle';
import Grid from '@mui/material/Grid';
import FormSelect from '../components/Form/FormSelect';
import SchemaField from '../components/Schema/SchemaField';
import SelectSystem from '../components/Select/SelectSystem';
import LinearProgress from "@mui/material/LinearProgress";

const fieldsToArray = fields => {
    return Object.entries(fields).map(([name, col]) => ({ ...{ name: name }, ...col })).sort((a, b) => a.column_order - b.column_order) || []
}

const arrayToFields = fields => {
    let newFields = {}
    fields.map(x => newFields[x.name] = { ...{ type: x.type, column_order: x.column_order }, ...x.primary_key ? { primary_key: true } : {} })
    return newFields
}

const Schema = () => {
    const createNew = (window.location.pathname.split('/').pop() === "new")
    const { user, setUser } = useUser();
    const params = useParams();
    const dispatch = useDispatch();


    const schemas = useSelector(state => (state.schemas.value)) || [];
    const cached = schemas.find(x => x.Id === params.id) || {}
    const [data, setData] = useState();

    // Schema-specific
    const [filter, setFilter] = useState({});
    const [fields, setFields] = useState([]);
    const [fieldsFiltered, setFieldsFiltered] = useState([]);

    useEffect(() => {
        user.org && getData()
    }, [user])

    const getData = () => {
        if (createNew) { setData({}); return }
        API.get("ApiDash", `${user.org}/schemas/${params.id}`).then(
            (response) => {
                setData(response);

                // Schema-specific
                setFields(fieldsToArray(response.Fields || {}));
                setFieldsFiltered(fieldsToArray(response.Fields || {}));

                // update cache if API response is different
                (_.difference(_.values(response), _.values(cached)) || []).length > 0 && updateCache(dispatch, setSchemas, [...schemas], response, params.id);
            }
        ).catch((error) => {
            dispatch(setSnackbar({ severity: 'error', message: error.message }))
        });
    }

    const handleChange = (e, i) => {
        let newData = { ...data }
        let newFields = [...fields]

        console.log(i, e.target.name)

        // Field change
        if (i !== undefined) {
            if (e.target.name === "column_order") {
                newFields.splice(i + e.target.value, 0, newFields.splice(i, 1)[0])
                newFields = newFields.map((x, i) => ({ ...x, ...{ column_order: i + 1 } }))
            } else if (e.target.name === "delete") {
                newFields.splice(i, 1)
            } else if (i === -1) {
                newFields = [...newFields, ...[{ name: '', type: '', column_order: newFields.length + 1 }]]
            } else {
                newFields[i] = { ...newFields[i], ...{ [e.target.name]: e.target.value } }
            }
            // Name, SystemKey, SchemaType change
        } else {
            newData = { ...newData, ...{ [e.target.name]: e.target.value } }
        }

        newData = {
            ...newData,
            ...{
                Fields: arrayToFields(newFields),
                GSI1PK: `sys#${newData.SystemKey}`,
                GSI1SK: `sch#${newData.Name}`
            }
        }
        setData(newData)
        setFields(newFields)
        setFieldsFiltered(newFields)
    }

    const handleDelete = () => {
        dispatch(setModal({
            open: true,
            content: [
                { variant: 'body1', text: `Are you sure you want to delete ${data.Name} ?` }
            ],
            buttons: [
                { label: 'Delete', color: 'error', onClick: () => deleteItem() }
            ]
        }))
    }

    const saveItem = e => {
        e.preventDefault();
        const { PK, SK, LastModified, ...body } = { ...data, ...{ Type: "schema" } }
        const urlEnd = createNew ? "" : `/${params.id}`
        // console.log(body)
        // return 
        dispatch(setSnackbar({ severity: 'info', message: 'Saving Schema...' }))
        API.put(
            'ApiDash',
            `${user.org}/schemas${urlEnd}`,
            { body: body }
        ).then(response => {
            updateCache(dispatch, setSchemas, [...schemas], response, response.Id)
            if (createNew) {
                setTimeout(function () {
                    window.location.href = `/schemas/${response.Id}`
                }, 1000);
            }
            else {
                setData(response);
            }
            dispatch(setSnackbar({ severity: 'success', message: 'Schema Saved!' }))
        }).catch(error => {
            dispatch(setSnackbar({ severity: 'error', message: error.message }))
        });
    };

    const deleteItem = () => {
        dispatch(setSnackbar({ severity: 'info', message: `Deleting ${data.Name}` }))
        dispatch(setModal(null))
        API.del("ApiDash", `${user.org}/schemas/${params.id}`).then(
            (response) => {
                dispatch(setSnackbar({ severity: 'success', message: `Deleted ${data.Name}` }))
                updateCache(dispatch, setSchemas, [...schemas], response, params.id, true)
                setTimeout(function () {
                    window.location.href = `/schemas`
                }, 1000);
            }
        ).catch((error) => {
            dispatch(setSnackbar({ severity: 'error', message: error.message }))
        });
    };

    // Schema-specific 

    useEffect(() => {
        handleFilter()
    }, [filter])

    const handleFilter = () => {
        let newFields = fields && fieldsToArray(fields).filter(x => (
            ((filter.name || '').length > 0 ? x.name.toLowerCase().includes(filter.name.toLowerCase()) : true) &&
            ((filter.type || '').length > 0 ? x.type === filter.type : true) &&
            (filter.primary_key ? (x.primary_key === true) : true)
        ))
        setFieldsFiltered(newFields)
    }

    // console.log(data)

    return data ?
        <Container>
            <Title
                page="Schemas"
                value={data.Name}
                system={data.SystemKey}
                type={data.SchemaType}
                handler={handleChange}
            />
            <ActionButtons
                createNew={createNew}
                delete={handleDelete}
                save={saveItem}
            />

            <Grid item xs={12}>
                <SelectSystem
                    name="SystemKey"
                    value={data.SystemKey}
                    handler={e => handleChange(e)}
                />
            </Grid>
            <Grid item xs={12}>
                <FormSelect
                    name="SchemaType"
                    label="Schema Type"
                    value={data.SchemaType || ''}
                    options={Filters.Schemas.type}
                    disabled={!createNew}
                    handler={e => handleChange(e)}
                />
            </Grid>
            <Grid item xs={12}>
                <ContainerSub>
                {/* <Container sx={{ mt: 4, border: '1px solid #ddd', borderRadius: '1rem', pb: 1 }}> */}
                    {/* <Grid item xs={12} sx={{ background: "#eee", borderRadius: '1rem 1rem 0 0' }}> */}
                        <Subtitle
                            type="Field"
                            new
                            handler={e => handleChange(e, -1)}
                        />
                    {/* </Grid> */}

                    {/* Filter */}
                    {fields && fields.length > 0 &&
                        <SchemaField
                            header
                            handler={e => setFilter({ ...filter, ...{ [e.target.name]: e.target.value } })}
                            item={filter}
                        />
                    }
                    {/* Fields */}
                    {fieldsFiltered.map((item, key) => {
                        return (
                            <SchemaField
                                item={item}
                                key={key}
                                handler={e => handleChange(e, key)}
                            />
                        )
                    })}
                {/* </Container> */}
                </ContainerSub>
            </Grid>
        </Container>

        :
        <Grid item xs={12}><LinearProgress /></Grid>
}

export default Schema;