import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import ButtonRefresh from '../Buttons/ButtonRefresh';
import Container from '../Shared/Container';


const Gumball = props => {
    const theme = useTheme();
    return (
        <Container
            sx={{
                my: 'auto',
                fontSize: '0.875rem',
                // color: theme.palette[status].main,
                background: theme.palette.offwhite.main,
                borderRadius: '1rem',
                alignItems: 'right',
                '& .MuiGrid-root': { pt: 0 }
            }}
        >
            <Grid item xs>
                {props.children}
            </Grid>
            <Grid>
                <ButtonRefresh
                    setData={props.setData}
                    small mini
                />
            </Grid>
        </Container>
    )
}
export default Gumball;