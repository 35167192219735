import { useState, useEffect } from "react";
import { useUser } from '../components/Auth/AuthContext';
import { useSelector, useDispatch } from 'react-redux'
import { setConnections } from '../components/Cache/connectionsSlice'
import { setJobs } from '../components/Cache/jobsSlice'
import { setSnackbar } from '../components/Cache/snackbarSlice'

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import Grid from '@mui/material/Grid';
import Loading from '../components/Shared/Loading';

import Container from '../components/Shared/Container';
import ControlPanel from '../components/Shared/ControlPanel';
import Header from '../components/Shared/Header';
import RowConnection from '../components/Rows/RowConnection';

const Connections = () => {
    const { user, setUser } = useUser();
    const jobs = useSelector(state => state.jobs.value)
    const connections = useSelector(state => state.connections.value)
    const lastUpdated = useSelector(state => (state.connections || {}).lastUpdated)
    const dispatch = useDispatch()
    dayjs.extend(relativeTime);

    const [data, setData] = useState(connections || []);
    const [since, setSince] = useState(dayjs(lastUpdated).fromNow());

    useEffect(() => {
        setData(connections || [])
        setSince(dayjs(lastUpdated).fromNow())
    }, [connections])

    useEffect(() => {
        const interval = setInterval(() => {
            setSince(dayjs(lastUpdated).fromNow())
        }, 60000);

        return () => clearInterval(interval);
    }, []);


    const handleFilter = filter => {
        let newData = connections.filter(x => (
            ((filter.search || '').length > 0 ? x.Name.toLowerCase().includes(filter.search.toLowerCase()) : true) &&
            ((filter.type || '').length > 0 ? x.SystemType === filter.type : true) &&
            ((filter.system || []).length > 0 ? filter.system.includes(x.SystemKey) : true)
        ))
        setData(newData)
    }
    
    return (
        <Container>
            <Header
                type="Connection"
                setData={setConnections}
                data={data}
                since={since}
            />

            <ControlPanel
                page="Connections"
                handler={handleFilter}
            />

            {/* Results */}
            {connections === null
                ?
                <Loading />
                :
                <Grid item xs={12}>
                    <Container>
                        {data && data.map((item, key) => {
                            const matched = (jobs || []).filter(x => x.Input && (x.Input.source.id === item.Id || x.Input.target.id === item.Id))
                            return (
                                <RowConnection
                                    key={key}
                                    row={item}
                                    matched={matched}
                                    // jobs={(jobs || []).filter(x => x.Input && (x.Input.source.id === item.Id || x.Input.target.id === item.Id))}
                                />
                            )
                        })
                        }
                    </Container>
                </Grid>
            }
        </Container >
    );
}

export default Connections;