const connectionConfig = {
    Name: {
        type: 'input',
        inputconfig: {
            name: 'Name',
            label: 'Connection Name'
        }
    },
    SystemKey: {
        type: 'select',
        inputconfig: {
            name: 'SystemKey',
            label: 'System'
        }
    },
    SystemType: {
        type: 'select',
        inputconfig: {
            name: 'SystemType',
            label: 'Connection Type'
        }
    },
    host: {
        type: 'input',
        inputconfig: {
            name: 'host',
            label: 'Host URL'
        }
    },
    url: {
        type: 'input',
        inputconfig: {
            name: 'url',
            label: 'URL'
        }
    },
    url_auth: {
        type: 'input',
        inputconfig: {
            name: 'url_auth',
            label: 'URL (Authentication)'
        }
    },
    url_data: {
        type: 'input',
        inputconfig: {
            name: 'url_data',
            label: 'URL (Data)'
        }
    },
    redirect_uri: {
        type: 'input',
        inputconfig: {
            name: 'redirect_uri',
            label: 'Redirect URI'
        }
    },
    port: {
        type: 'input',
        inputconfig: {
            name: 'port',
            label: 'Port'
        }
    },
    folder: {
        type: 'input',
        inputconfig: {
            name: 'folder',
            label: 'Folder'
        }
    },
    username: {
        type: 'input',
        inputconfig: {
            name: 'username',
            label: 'Username'
        }
    },
    password: {
        type: 'password',
        inputconfig: {
            name: 'password',
            label: 'Password'
        }
    },
    pwd: {
        type: 'password',
        inputconfig: {
            name: 'pwd',
            label: 'Password'
        }
    },
    server: {
        type: 'input',
        inputconfig: {
            name: 'server',
            label: 'Host'
        }
    },
    database: {
        type: 'input',
        inputconfig: {
            name: 'database',
            label: 'Database'
        }
    },
    schema: {
        type: 'input',
        inputconfig: {
            name: 'schema',
            label: 'Default Schema (Optional)'
        }
    },
    domain: {
        type: 'input',
        inputconfig: {
            name: 'domain',
            label: 'Domain'
        }
    },
    subdomain: {
        type: 'input',
        inputconfig: {
            name: 'subdomain',
            label: 'Subdomain'
        }
    },
    sitename: {
        type: 'input',
        inputconfig: {
            name: 'sitename',
            label: 'Site Name'
        }
    },
    apikey: {
        type: 'password',
        inputconfig: {
            name: 'apikey',
            label: 'API Key'
        }
    },
    apikeys: {
        type: 'apikeys',
        inputconfig: {
            name: 'apikeys',
            label: 'API Keys',
            disabled: true
        }
    },
    token: {
        type: 'password',
        inputconfig: {
            name: 'token',
            label: 'API Key'
        }
    },
    tenant_id: {
        type: 'password',
        inputconfig: {
            name: 'tenant_id',
            label: 'Tenant ID'
        }
    },
    client_id: {
        type: 'password',
        inputconfig: {
            name: 'client_id',
            label: 'Client ID'
        }
    },
    client_secret: {
        type: 'password',
        inputconfig: {
            name: 'client_secret',
            label: 'Client Secret'
        }
    },
    security_token: {
        type: 'password',
        inputconfig: {
            name: 'security_token',
            label: 'API Key'
        }
    },
    organizationId: {
        type: 'input',
        inputconfig: {
            name: 'organizationId',
            label: 'Organization ID'
        }
    },
    admin_account: {
        type: 'input',
        inputconfig: {
            name: 'admin_account',
            label: 'Admin Account'
        }
    },
    credentials: {
        type: 'password',
        inputconfig: {
            name: 'credentials',
            label: 'Credentials',
            multiline: true
        }
    },
    consumer_key: {
        type: 'password',
        inputconfig: {
            name: 'consumer_key',
            label: 'Consumer Key'
        }
    },
    consumer_secret: {
        type: 'password',
        inputconfig: {
            name: 'consumer_secret',
            label: 'Consumer Secret'
        }
    },
    access_token: {
        type: 'password',
        inputconfig: {
            name: 'access_token',
            label: 'Access Token'
        }
    },
    service_account: {
        type: 'input',
        inputconfig: {
            name: 'service_account',
            label: 'Service Account'
        }
    },
    delegate_account: {
        type: 'input',
        inputconfig: {
            name: 'delegate_account',
            label: 'Delegate Account'
        }
    }
}

export default connectionConfig;