import Grid from '@mui/material/Grid';
import Container from '../Shared/Container';
import FormSelect from '../Form/FormSelect';
import FormTextField from '../Form/FormTextField';
import { operOptions } from '../Shared/Functions';
import Pod from '../Shared/Pod';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const JobRecordRowParameter = props => {
    return (
        <Pod
            icon={<ChevronRightIcon />}
            delete={() => props.handler({ target: { name: "delete" } })}
        >
            <Container >
                <Grid item xs={3}>
                    <FormSelect
                        name='field'
                        value={props.field || ''}
                        options={props.params || []}
                        disableUnderline
                        handler={props.handler}
                    />
                </Grid>
                <Grid item xs={1}>
                    <FormSelect
                        name='operator'
                        value={props.operator || ''}
                        options={operOptions}
                        disableUnderline
                        handler={props.handler}
                    />
                </Grid>
                <Grid item xs>
                    <FormTextField
                        name='value'
                        value={props.value || ''}
                        disableUnderline
                        handler={props.handler}
                    />
                </Grid>
            </Container>
            {/* <Grid>
                <ButtonDelete small mini delete={() => props.handler({ target: { name: "delete" } })} />
            </Grid> */}
        </Pod>
    )
}
export default JobRecordRowParameter;