import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import FormTextField from '../Form/FormTextField';
import IconSystem from '../Shared/IconSystem';
import Filters from '../Shared/Filters';

const Name = props => {
    const [name, setName] = useState(props.Name)
    const [FirstName, setFirstName] = useState(props.FirstName);
    const [LastName, setLastName] = useState(props.LastName);

    useEffect(() => {
        setName(props.Name)
    }, [props])
    console.log(props)
    return (
        <Grid item xs
            sx={{
                display: 'flex',
                mb: 2,
                '& .MuiInputBase-input': { fontSize: '1.5rem', fontWeight: 'bold' }
            }}
        >
            <IconSystem
                icon={Filters.Users.Role.find(x=>x.value ===props.Role).icon}
                type="Role"
                page="Users"
                size={{ system: 'large', type: 'medium' }}
            />
            {["FirstName", "LastName"].map((item, key) => (
                <FormTextField
                    key={key}
                    name={item}
                    value={eval(item)}
                    handler={props.handler}
                    disabled={props.disabled}
                />
            ))}
        </Grid>
    )
}
export default Name