import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'results',
  initialState: {
    value: {}
  },
  reducers: {
    setResults: (state, data) => {
      state.value = data.payload
    }
  },
})

export const { setResults } = slice.actions
export default slice.reducer