import { blue, orange, purple, teal, red } from '@mui/material/colors';
const theme = {
  background: {
    paper: '#fff',
    // default: '#F8FBFD'
    default: '#999'
  },
  typography: {
    // fontFamily: 'Roboto',
    fontSize: 14
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        root: {
          fontSize: 10  
        } 
      }
    }
  },
  palette: {
    primary: {
      main: blue[700],
      light: '#E27425',
      dark: '#18345B',
      contrastText: '#fff',
    },
    secondary: {
      main: orange[500],//'#E27425',
      light: '#E27425',
      dark: '#E27425',
      contrastText: '#fff',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    paramchip: {
      main: '#ccc',
      contrastText: '#888',
    },
    success: {
      main: '#4caf50',
      contrastText: '#fff'
    },
    error: {
      main: '#f44336',
      contrastText: '#fff'
    },
    browser: {
      main: purple[800],
      contrastText: '#fff'
    },
    sftp: {
      main: teal[800],
      contrastText: '#fff'
    },
    sqlserver: {
      main: red[800],
      contrastText: '#fff'
    },
    white: {
      main: '#fff',
      contrastText: '#000'
    },
    offwhite: {
      main: '#eee',
      contrastText: '#000'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  }
};

export default theme;