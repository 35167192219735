import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import ContainerRow from "../Shared/ContainerRow";
import ButtonRun from '../Buttons/ButtonRun';
import ExecutionBlock from '../Execution/ExecutionBlock';
import Title from '../Shared/Title';
import { jobIcons } from '../Shared/Functions';

const RowExecution = props => {
    const [row, setRow] = useState(props.row)

    useEffect(() => {
        setRow(props.row)
    }, [props])
    // console.log(row)
    return (
        <ContainerRow>
            <Title
                page="Connections"
                value={row.JobName || ''}
                systems={jobIcons(row.Input)}
                size={{ system: 'medium', type: 'small' }}
                href={`/jobs/${row.JobId}`}
            />
            <Grid item xs={6.25}
                // sx={{ '& .MuiGrid-container': { my: 'auto' } }}
            >
                <ExecutionBlock
                    name={row.Name}
                    duration={row.Duration * 1000}
                    input={row.Input}
                    start={row.StartTime}
                    status={row.Status === 'Succeeded' ? 'success' : 'error'}
                    schedule={row.Schedule}
                    width={row.Duration / props.maxWidth}
                    href={`/executions/${row.StateMachine}:${row.ExecutionID}`}
                />
                {/* <ExecutionBlock
                    {...row}
                    status={row.Status === 'Succeeded' ? 'success' : 'error'}
                    width={row.Duration/props.maxWidth}
                    href={`/executions/${row.StateMachine}:${row.ExecutionID}`}
                /> */}
            </Grid>
            <Grid item xs={0.75} sx={{ justifyContent: 'flex-end' }}>
                {row.Status === "Failed" && <ButtonRun type="job" run={row} />}
            </Grid>
        </ContainerRow>
    )
}

export default RowExecution;