import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { setConnections } from '../components/Cache/connectionsSlice';
import { setExecutions } from '../components/Cache/executionsSlice';
import { setJobs } from '../components/Cache/jobsSlice';
import { setQueries } from '../components/Cache/queriesSlice';
import { setSnackbar } from '../components/Cache/snackbarSlice';

import logo from '../images/logo.svg';
import logosmall from '../images/logo-min.svg';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import RecyclingIcon from '@mui/icons-material/RecyclingOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import ViewListIcon from '@mui/icons-material/ViewList';
import JoinLeftIcon from '@mui/icons-material/JoinLeft';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SchemaIcon from '@mui/icons-material/Schema';
import PowerIcon from '@mui/icons-material/Power';

import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import CableIcon from '@mui/icons-material/Cable';

import SectionHeader from '../components/Navbar/SectionHeader';
import CacheMenu from '../components/Navbar/CacheMenu';
import AccountMenu from '../components/Navbar/AccountMenu';

const drawerWidth = 240;

const pages = [
  { page: 'Executions', path: '/executions', icon: <DashboardRoundedIcon /> },
  { page: 'Jobs', path: '/jobs', icon: <WorkHistoryOutlinedIcon /> },
  { page: 'Connections', path: '/connections', icon: <PowerIcon /> },
  { page: 'Queries', path: '/queries', icon: <JoinLeftIcon /> },
  // { page: 'Reports', path: '/reports', icon: <AssessmentOutlinedIcon /> },
  { page: 'Schemas', path: '/schemas', icon: <SchemaIcon /> },
  { page: 'Users', path: '/users', icon: <PeopleAltIcon /> },
  // { page: 'Settings', path: '/settings', icon: <SettingsRoundedIcon /> }
]

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Navbar(props) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme()
  const dispatch = useDispatch()

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleCacheClear = () => {
    dispatch(setConnections(null))
    dispatch(setExecutions(null))
    dispatch(setJobs(null))
    dispatch(setQueries(null))
    dispatch(setSnackbar(null))
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} color="default">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <SectionHeader
            logo={logo}
            pages={pages}
            href={window.location.pathname.split('/')[1]}
          />
          <IconButton onClick={handleCacheClear}>
            {/* <RefreshIcon /> */}
          </IconButton>
          <CacheMenu/>
          <AccountMenu />
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img src={logosmall} alt="dash" style={{ maxHeight: '20px', margin: 'auto' }} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {pages.map((page, index) => (
            <ListItem key={page.page} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  borderRight: '3px solid',
                  borderColor: (window.location.pathname === page.path) ? theme.palette.primary.main : 'transparent'
                }}
                component="a"
                href={page.path}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center'
                  }}
                >
                  {page.icon}
                </ListItemIcon>
                <ListItemText primary={page.page} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {props.children}
      </Box>
    </Box>
  );
}