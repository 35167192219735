import Filters from './Filters';
import exMap from '../Execution/ExecutionDetailMap';
import { SystemConfig } from '../Config/SystemConfig';

const matchTables = query => {
    return [...new Set([...query.matchAll(/(?:(?:from|join)(?:\s|\()+((?:\[*\w*\.\w*)+)(?:\s|\)|$))/gi)].map(x => x[1]))].sort()
}

const matchTablesObject = query => {
    return matchTables(query).map(x => x.split('.').splice(-2)).reduce((obj, item) => (obj[item[0]] = [...(obj[item[0]] || []), ...[item[1]]], obj), {})
}

const iconType = (page, type) => {
    // console.log(Filters[page], type)
    return (((Filters[page] || {}).type || []).find(x => x.value === type) || {})
}

const capitalize = item => {
    return item.charAt(0).toUpperCase() + item.slice(1)
}

const getConfig = (key, input, node) => {
    return input[node] && (SystemConfig[input[node].system].types[input[node].type][node][input[node].action] || {})[key] || []
}

const sortResponse = (response, key, type) => {
    type === "alpha" && response.sort((a, b) => { return ((a[key] || "").toUpperCase() < (b[key] || "").toUpperCase()) ? -1 : 1 })
    type === "number" && response.sort((a, b) => { return b[key] - a[key] })
    return response
}

const updateCache = (dispatch, fn, data, payload, Id, del = null) => {
    const ix = data.findIndex(x => x.Id === Id)
    if (ix < 0) {
        data = [...data, ...[payload]]
    } else {
        data[ix] = payload
    }
    if (del) {
        data.splice(ix, 1)
    }
    data = sortResponse(data, "Name", "alpha")
    dispatch(fn(data))
}

const makeConnectionOptions = system => {
    return SystemConfig[system] ? Filters.Connections.type.filter(x => Object.keys(SystemConfig[system].types).includes(x.value)) : []
}

const makeJobPayload = (data, org, key, value, Name) => {
    return ({
        ...{
            Input: {
                ...{
                    org: org,
                    jobName: data.Name,
                    source: data.Input ? data.Input.source : {},
                    target: data.Input ? data.Input.target : {},
                    records: data.Input ? data.Input.records : []
                },
                ...{ [key]: value }
            }
        },
        ...Name
    })
}

const operOptions = [
    { value: 'lt', label: '<' },
    { value: 'le', label: '<=' },
    { value: 'eq', label: '=' },
    { value: 'ge', label: '>=' },
    { value: 'gt', label: '>' },
    { value: 'co', label: 'contains' }
]

const jobIcons = input => {
    return input && [
        {
            system: input.source && input.source.system,
            type: input.source && input.source.type
        },
        {
            system: input.target && input.target.system,
            type: input.target && input.target.type
        }
    ]
}


const executionStepPayload = step => {
    const root = exMap[step.type] || {}
    let payload = root.key ? step[root.key][root.itemKey] : {}
    try {
        payload = JSON.parse(payload)
    } catch (e) {
        payload = {errorMessage: payload}
    }

    // calculate peripherals
    var row_count = 0
    payload.records && payload.records.map(x => row_count += (x ? x.row_count || 0 : 0))

    return {
        payload: payload,
        row_count: row_count,
        error: payload.errorMessage && payload.errorMessage
    }
}



export {
    matchTables,
    matchTablesObject,
    iconType,
    capitalize,
    getConfig,
    sortResponse,
    updateCache,
    makeConnectionOptions,
    makeJobPayload,
    operOptions,
    jobIcons,
    executionStepPayload
};