import { useState, useEffect } from "react";
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid';
import FormSelect from '../Form/FormSelect';
import Container from '../Shared/Container';

const SelectConnection = props => {
    const connections = useSelector(state => state.connections.value || []);
    const [value, setValue] = useState(props.value);

    const matchedConnections = props.limit ? 
        Object.entries(props.limit).map(([k, v])=>connections.filter(y=>y[k]===v)).flat() :
        connections || []
    const options = matchedConnections.map(x => ({
        value: x.Id,
        system: x.SystemKey,
        label: x.Name,
        link: 'connections'
    }))
    
    useEffect(()=>{
        setValue(props.value)
    }, [props])

    return (
        <Container>
            <Grid item xs={12}>
                <FormSelect
                    name={props.name || "system"}
                    label="Connection"
                    options={options}
                    value={value || ''}
                    handler={props.handler}
                />
            </Grid>
        </Container>
    )
}

export default SelectConnection;