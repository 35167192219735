import DataObjectIcon from '@mui/icons-material/DataObject';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import SchoolIcon from '@mui/icons-material/School';
import BadgeIcon from '@mui/icons-material/Badge';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import MergeIcon from '@mui/icons-material/Merge';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import JoinLeftIcon from '@mui/icons-material/JoinLeft';
import CodeIcon from '@mui/icons-material/Code';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import TableViewIcon from '@mui/icons-material/TableView';
import PollIcon from '@mui/icons-material/Poll';
import LinkIcon from '@mui/icons-material/Link';
import SyncIcon from '@mui/icons-material/Sync';
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FolderIcon from "@mui/icons-material/Folder";
import DatasetIcon from '@mui/icons-material/Dataset';
import AbcIcon from '@mui/icons-material/Abc';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';

const options = {
    // file_type: [
    //     { value: 'csv', label: 'Comma Separated Values (CSV)', icon: <InsertDriveFileOutlinedIcon /> },
    //     { value: 'txt', label: 'Tab-Delimited File (TXT)', icon: <KeyboardTabIcon /> },
    //     { value: 'json', label: 'JavaScript Object Notation (JSON)', icon: <DataObjectIcon /> }
    // ],
    file_type: [
        { value: 'csv', label: 'Comma Separated Values (CSV)' },
        { value: 'txt', label: 'Tab-Delimited File (TXT)' },
        { value: 'json', label: 'JavaScript Object Notation (JSON)' }
    ],
    actions: [
        { value: 'POST', label: 'Add', icon: <AddIcon /> },
        { value: 'DELETE', label: 'Delete', icon: <ClearIcon /> },
        { value: 'PUT', label: 'Update', icon: <ArrowUpwardIcon /> }
    ],
    actionsGoogle: [
        { value: 'insert', label: 'Add', icon: <AddIcon /> },
        { value: 'delete', label: 'Delete', icon: <ClearIcon /> },
        { value: 'update', label: 'Update', icon: <ArrowUpwardIcon /> }
    ],
    qualtricsSurveyTypes: [
        { value: 'Student', label: 'Student' },
        { value: 'Staff', label: 'Staff' },
        { value: 'Other', label: 'Other' }
    ]
}

const attributes = {
    object_key: { name: 'object_key', type: 'input', label: 'File Path', icon: <InsertDriveFileOutlinedIcon /> },
    file_type: { name: 'file_type', type: 'select', label: 'File Type', icon: <InsertDriveFileOutlinedIcon />, options: options.file_type, required: true },
    folder: { name: 'folder', type: 'input', label: 'Folder', icon: <FolderIcon /> },
    query: { name: 'query', type: 'sqleditor', label: 'SQL Server Query', icon: <JoinLeftIcon />, launch: '/queries/' },
    querygql: { name: 'query', type: 'sqleditor', label: 'GraphQL Query', icon: <JoinLeftIcon /> },
    googlesheetId: { name: 'googlesheetId', label: 'Google Sheet ID', required: true, type: 'input', icon: <DatasetIcon />, launch: 'https://drive.google.com/open?id='  },
    sheet: { name: 'sheet', label: 'Sheet Name', required: true, type: 'input', icon: <AbcIcon /> },
    surveyId: { name: 'surveyId', type: 'input', label: 'Survey ID', required: true, icon: <PollOutlinedIcon /> },
    distributionChannel: { name: 'DistributionChannel', type: 'input', label: 'Distribution Channel', required: true, icon: <AbcIcon /> },
    surveyType: { name: 'surveyType', type: 'select', label: 'Survey Type', options: options.qualtricsSurveyTypes },
    directoryId: { name: 'directoryId', type: 'input', label: 'Directory ID', required: true },
    automationId: { name: 'automationId', type: 'input', label: 'Automation ID', required: true },
    mailingListId: { name: 'mailingListId', type: 'input', label: 'Mailing List ID', required: true },
    actions: { name: 'action', type: 'select', label: 'Action', options: options.actions },
    actionsGoogle: { name: 'action', type: 'select', label: 'Action', options: options.actionsGoogle }

}

export default attributes;