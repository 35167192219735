import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setUsers } from '../components/Cache/usersSlice';
import { API } from '@aws-amplify/api';

import { Grid, Link, Badge, Button } from '@mui/material';
import Loading from '../components/Shared/Loading';
import Container from '../components/Shared/Container';
import Header from '../components/Shared/Header';

import RowUser from '../components/Rows/RowUser';
import ControlPanel from '../components/Shared/ControlPanel';


const Users = () => {
    const users = useSelector(state => (state.users || {}).value)
    const [data, setData] = useState(users || []);
    const [filter, setFilter] = useState({});

    // filter
    useEffect(() => {
        handleFilter()
    }, [filter])

    useEffect(() => {
        setData(users || [])
    }, [users])

    // handlers
    const handleFilter = () => {
        let newData = (users || []).filter(x => (
            ((filter.search || '').length > 0 ? (x.Name || '').toLowerCase().includes(filter.search.toLowerCase()) : true) &&
            ((filter.system || []).length > 0 ? filter.system.some(system => x.SK.split('#')[0] === system) : true)
        ))
        setData(newData)
    }

    console.log(data)

    return (
        <Container>
            <Header
                type="Users"
                setData={setUsers}
                data={data}
            />
            {/* Control Panel */}
            <ControlPanel
                page="Users"
                filter={filter}
                // filterItems={schemaTypes}
                handler={f => setFilter(f)}
            />

            {/* Results */}
            {users === null
                ?
                <Loading />
                :
                <Grid item xs={12}>
                    <Container>
                        {data && data.map((item, key) => {
                            return (
                                <RowUser
                                    key={key}
                                    row={item}
                                    // matched={matched}
                                />
                            )
                        })
                        }
                    </Container>
                    {/* </Grid> */}
                </Grid>
            }
        </Container>
    )
}

export default Users;