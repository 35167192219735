import { Grid, Typography } from '@mui/material';
import ButtonNew from '../Buttons/ButtonNew';
import ButtonRefresh from '../Buttons/ButtonRefresh';
import Container from './Container';
import pluralize from 'pluralize';

const Subtitle = props => (
    <Grid item xs={12} sx={{ background: "#eee", borderRadius: '1rem 1rem 0 0' }} >
        <Container>
            <Grid item xs sx={{ fontSize: '1.25rem', fontWeight: 500 }}>
                {pluralize(props.type)}
            </Grid>
            <Grid sx={{ my: 'auto' }}>
                {props.new &&
                    <ButtonNew
                        type={props.type}
                        handler={props.handler}
                    />
                }
                {props.refresh &&
                    <ButtonRefresh
                        type={props.type}
                        setData={props.handler}
                    />
                }
            </Grid>
        </Container>
    </Grid>
)

export default Subtitle;