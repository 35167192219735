import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import StorageIcon from '@mui/icons-material/Storage';

const ButtonRows = props => {
    return (
        <Button
            onClick={props.handler}
            size="small"
            disabled={props.disabled}
            sx={{ textAlign: 'left' }}
        >
            <StorageIcon />
            <Typography sx={{ minWidth: '24px', textAlign: 'center', fontSize: '0.825rem', fontWeight: 500 }}>{props.value}</Typography>
        </Button>
    )
}
export default ButtonRows