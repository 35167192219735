import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from '@mui/icons-material/Save';

const ButtonSave = props => (
    <Button
        variant={props.variant || "text"}
        color={props.color || "success"}
        disableElevation
        startIcon={props.createNew ? <AddIcon /> : <SaveIcon />}
        onClick={props.save}
    >
        {props.createNew ? 'Create' : 'Save'}
    </Button>
)
export default ButtonSave