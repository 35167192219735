import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from '@mui/material/styles';

const ButtonDelete = props => {
    const theme = useTheme();
    const btnProps = {
        name: 'delete',
        variant: props.variant || "text",
        color: props.mini ? "offwhite" : props.small ? "neutral" : props.color || "error",
        onClick: props.delete
    }
    const sx={
        background: theme.palette.neutral.main,
        p: 0.5
    }
    return props.small
        ?
        <IconButton {...btnProps} sx={props.mini && sx}>
            <DeleteIcon />
        </IconButton>
        :
        <Button
            {...btnProps}
            startIcon={<DeleteIcon />}
        >
            Delete
        </Button>
}
export default ButtonDelete;