import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const Time = props => {
    const cronArray = (props.cron || "").replace(/[cron()]/g, "").split(" ");
    const date = new Date()
    date.setUTCFullYear(props.start ? props.start.substring(0, 4) : new Date().getFullYear())
    date.setUTCMonth(props.start ? props.start.substring(5, 7) - 1 : new Date().getMonth())
    date.setUTCDate(props.start ? props.start.substring(8, 10) : new Date().getDate())
    date.setUTCHours(props.start ? props.start.substring(11, 13) : parseInt(cronArray[1], 10))
    date.setUTCMinutes(props.start ? props.start.substring(14, 16) : parseInt(cronArray[0], 10))
    date.setUTCSeconds(props.start ? props.start.substring(17, 19) : 0)

    const hourly = ((cronArray[1] || "").indexOf("/") > -1)

    return (
        <Badge
            badgeContent={hourly ? "H" : null}
            color="primary"
            sx={{
                // display: 'block',
                display: props.date ? 'block' : 'inline-block',
                // '& .MuiTypography-root': {display: 'block'},
                '& .MuiBadge-badge': {
                    right: -8,
                    top: 2,
                    padding: '0 4px',
                }
            }}
        >
            {props.date && <Typography>{date.toLocaleDateString()}</Typography>}
            <Typography>
                {date.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                })}
            </Typography>
        </Badge>
    )
}
export default Time;