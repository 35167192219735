import { useState, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { setModal } from '../Cache/modalSlice';
import ContainerRow from '../Shared/ContainerRow';
import {
    Grid, Link, LinearProgress, Typography, Box, Avatar
} from '@mui/material';

import Filters from '../Shared/Filters';
import pluralize from 'pluralize';



const Row = props => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [row, setRow] = useState(props.row)
    const [matched, setMatched] = useState(props.matched)
    const role = Filters.Users.Role.find(x => x.value === row.Role)

    useEffect(() => {
        setRow(props.row)
        setMatched(props.matched)
    }, [props])

    const handleRowChange = e => {
        const value = (e.target.name === 'primary_key') ? e.target.checked : e.target.value
        const newRow = { ...row, ...{ [e.target.name]: value } }
        setRow(newRow)
        props.handler(newRow)
    }

    const handleModal = () => {
        dispatch(setModal({
            open: true,
            content: [
                { variant: 'body2', text: 'Jobs that use' },
                { variant: 'h6', text: row.Name }
            ],
            matched: { jobs: matched.jobs }
        }))
    }

    const sxAvatar = {
        width: 32,
        height: 32,
        mr: 1,
        background: "white",
        border: '1px solid',
        borderColor: theme.palette[role.color].main
    }

return (
    <ContainerRow>
        <Grid item xs={6}>
            <Avatar
                sx={sxAvatar}
            >
                {Filters.Users.Role.find(x => x.value === row.Role).icon}
            </Avatar>

            <Link href={`/${pluralize(row.Type || '')}/${row.Id}`} underline="hover" target="_blank">
                {row.FirstName || "_"} {row.LastName || ""}
            </Link>
        </Grid>
        <Grid item xs={3}>
            {row.Email}
        </Grid>
        <Grid item xs={3} sx={{ justifyContent: 'flex-end' }}>

        </Grid>

    </ContainerRow>
)
}

export default Row