import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setQueries } from '../components/Cache/queriesSlice';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';

import { matchTablesObject } from '../components/Shared/Functions';
import Container from '../components/Shared/Container';
import ControlPanel from '../components/Shared/ControlPanel';
import Header from '../components/Shared/Header';
import RowQuery from '../components/Rows/RowQuery';

import Loading from '../components/Shared/Loading';

const Queries = () => {
    dayjs.extend(relativeTime);
    const queries = useSelector(state => state.queries) || {};
    const results = useSelector(state => state.results.value) || {};
    const jobs = useSelector(state => state.jobs.value);
    const connections = useSelector(state => state.connections.value);

    const [data, setData] = useState(queries.value || [])
    const [since, setSince] = useState(dayjs(queries.lastUpdated).fromNow());
    const [filter, setFilter] = useState({});

    useEffect(() => {
        setData(queries.value || [])
        setSince(dayjs(queries.lastUpdated).fromNow())
    }, [queries])


    const handleFilter = () => {
        const newData = (queries.value || []).filter(x => (
            ((filter.search || '').length > 0 ? x.Name.toLowerCase().includes(filter.search.toLowerCase()) : true) &&
            ((filter.system || []).length > 0 ? filter.system.some(system => Object.keys(matchTablesObject(x.query)).includes(system)) : true)
        ))
        setData(newData)
    }

    useEffect(() => {
        handleFilter()
    }, [filter])

    return (
        <Container>
            <Header
                type="Query"
                setData={setQueries}
                data={data}
                since={since}
            />

            <ControlPanel
                page={"Queries"}
                filter={filter}
                handler={f => setFilter(f)}
            />

            {/* Results */}
            {queries.value === null
                ?
                <Loading />
                :
                <Grid item xs={12}>
                    <Container>
                        {data && data.map((item, key) => {
                            return (
                                <RowQuery
                                    key={key}
                                    row={item}
                                    matched={{
                                        connection: connections.find(x => x.Id === item.connection),
                                        tables: matchTablesObject(item.query),
                                        results: results[item.Id] || [],
                                        jobs: jobs.filter(x => x.Input && x.Input.records && x.Input.records.find(y => y.queryId === item.Id))
                                    }}
                                />
                            )
                        })
                        }
                    </Container>
                </Grid>
                
                
            }
        </Container>

    );
}

export default Queries;