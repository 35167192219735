import { useState, useEffect } from "react";
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import StorageIcon from '@mui/icons-material/Storage';
import logo from '../../images/logo-avatar-black.svg';

const Icon = props => {
    const theme = useTheme();
    const [icon, setIcon] = useState()
    const [image, setImage] = useState()
    const size = props.size || 'medium'
    const sizeMap = {
        small: 16,
        medium: 24,
        large: 36,
        xlarge: 48
    }
    const height = sizeMap[size]
    const width = sizeMap[size]
    const sx = {
        height: height,
        width: width,
        color: theme.palette[props.color || "neutral"].main || 'auto',
        background: 'white',
        border: 'none',
        '& .MuiSvgIcon-root': {
            height: height,
            width: width,
            color: theme.palette[props.color || "neutral"].main || 'auto',
        }
    }

    useEffect(() => {
        props.icon && setIcon(props.icon);
        props.system && import(`../../images/systems/${props.system}.png`).then(x => setImage(x.default)).catch(error => { setIcon(<StorageIcon />); setImage(null) });
    }, [props]);
    return (
        <Avatar
            alt={props.system}
            src={!icon ? (image || logo) : null}
            sx={sx}
        >
            {icon}
        </Avatar>
    )
}

export default Icon