import { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import FormSelect from '../Form/FormSelect';
import Container from '../Shared/Container';
import Filters from '../Shared/Filters';
import { makeConnectionOptions } from '../Shared/Functions';

const SelectConnectionType = props => {
    const [value, setValue] = useState(props.value);
    const [options, setOptions] = useState(makeConnectionOptions(props.system))
    
    useEffect(() => {      
        setValue(props.value)
        setOptions(makeConnectionOptions(props.system))
    }, [props])

    return (
        <Container>
            <Grid item xs={12}>
                <FormSelect
                    name={props.name || "system"}
                    label="System"
                    options={options}
                    value={value || ''}
                    handler={props.handler}
                />
            </Grid>
        </Container>
    )
}

export default SelectConnectionType;