import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTheme } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux'
import { setExecutions } from '../Cache/executionsSlice';

const ButtonRefresh = props => {
    const theme = useTheme();
    const btnProps = {
        variant: props.variant || "text",
        color: props.mini ? "offwhite" : props.small ? "neutral" : props.color || "primary",
        onClick: props.setData
    }
    const sx={
        background: theme.palette.neutral.main,
        p: 0.5,
        '&:hover': {color: theme.palette.neutral.main}
    }

    // const getAllExecutions = () => {
    //     const maxExecutions = 100
    //     setExecutions(null)
    //     API.get('ApiDash', `${user.org}/executions/`, {
    //         queryStringParameters: { jobId: row.Id || '-1' }
    //     }).then((response) => {
    //         // add this Job's Executions to cached executions if they aren't theyre already
    //         // add Name, JobId to values first
    //         // (they should align but those two fields are missing for some reason that is probably dumb)
    //         response = response.map(x => ({ ...x, ...{ Name: x.JobName, JobId: row.Id } }))
    //         console.log(response, row.Id)
    //         const newExecutions = [
    //             ...executions,
    //             ...response.splice(0, maxExecutions).filter(
    //                 x => !data.map(y => y.ExecutionID).includes(x.ExecutionID)
    //             )
    //         ].sort((a, b) => { return b.SK - a.SK })

    //         dispatch(setExecutions(newExecutions))
    //     }).catch(
    //         error => {
    //             console.log("There's an error: ", error)
    //         }
    //     )
    // }

    return props.small
        ?
        <IconButton {...btnProps} sx={props.mini && sx}>
            <RefreshIcon />
        </IconButton>
        :
        <Button
            {...btnProps}
            startIcon={<RefreshIcon />}
        >
           Refresh
        </Button>

}
export default ButtonRefresh