import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'executions',
  initialState: {
    value: [],
  },
  reducers: {
    setExecutions: (state, data) => {
      state.value = data.payload
      state.lastUpdated = new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString()
    },
  },
})

export const { setExecutions } = slice.actions
export default slice.reducer