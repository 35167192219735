import Container from '../Shared/Container';
import JobRecordRowAttribute from './JobRecordRowAttribute';

const JobRecordRowAttributes = props => {
    return (
        <Container>
            {props.attributes.map((attr, key) => {
                return (
                    <JobRecordRowAttribute
                        key={key}
                        width={12 / props.attributes.length}
                        attr={attr}
                        item={props.item}
                        handler={props.handler}
                    />
                )
            })}
        </Container>
    )
}
export default JobRecordRowAttributes