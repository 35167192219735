import { Auth } from 'aws-amplify';
import { SSMClient, GetParameterCommand } from '@aws-sdk/client-ssm';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';

import awsconfig from '../../config';

const GetSecureValue = async (value) => {
    const ssm = new SSMClient({
        region: awsconfig.Auth.region,
        credentials: fromCognitoIdentityPool({
            client: new CognitoIdentityClient({ region: awsconfig.Auth.region }),
            identityPoolId: awsconfig.Auth.identityPoolId,
            logins: {
                [`cognito-idp.us-east-1.amazonaws.com/${awsconfig.Auth.userPoolId}`]: (await Auth.currentSession()).getIdToken().getJwtToken()
            },
        })
    });
    let command = new GetParameterCommand({
        Name: value,
        WithDecryption: true
    });
    let result = await ssm.send(command).then(
        (data) => {
            return data.Parameter.Value
        },
        (error) => {
            console.log(value, 'error', error)
            return ""
        }
    )
    return result

}

export { GetSecureValue }