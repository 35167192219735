import React, { useState, useEffect } from "react";
import { useUser } from '../Auth/AuthContext';
import { useTheme } from '@mui/material/styles';
import { Auth } from 'aws-amplify';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';

import Filters from '../Shared/Filters';

export default function AccountMenu() {
    const { user, setUser } = useUser();
    const [initial, setInitial] = useState();
    const [name, setName] = useState();
    const [role, setRole] = useState({});
    const [picture, setPicture] = useState();
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const signOut = async () => {
        try {
            await Auth.signOut({ global: true });
        } catch (error) {
            console.log('error signing out: ', error);
        }
    };
    useEffect(() => {
        if (user && Object.keys(user).length > 0) {
            const attr = {
                given_name: '',
                family_name: '',
                picture: '',
                'custom:role': '',
                ...user.cognitoUser.attributes
            }
            setInitial(attr.given_name.substring(0, 1))
            setName(attr.given_name + ' ' + attr.family_name)
            setPicture(attr.picture)
            setRole(Filters.Users.Role.find(x => x.value === attr['custom:role']))
        }
    }, [user]);
    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar
                            src={picture}
                            imgProps={{ referrerPolicy: "no-referrer" }}
                            sx={{ width: 32, height: 32, background: theme.palette.primary.main }}
                        >
                            {!picture && initial}
                        </Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem>
                    {name}


                </MenuItem>
                <MenuItem>
                    {Object.keys(role || {}).length > 0 && <Chip
                        size="small"
                        variant={"outlined"}
                        icon={role.icon || ''}
                        label={role.label || ''}
                        color={role.color || ''}
                        sx={{ ml: 1 }}
                    />
                    }
                </MenuItem>
                <Divider />
                <MenuItem onClick={signOut}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}