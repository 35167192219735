import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import DriveFolderUploadTwoToneIcon from '@mui/icons-material/DriveFolderUploadTwoTone';
import StorageTwoToneIcon from '@mui/icons-material/StorageTwoTone';
import WebTwoToneIcon from '@mui/icons-material/WebTwoTone';
import ComputerIcon from '@mui/icons-material/Computer';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IntegrationInstructionsTwoToneIcon from '@mui/icons-material/IntegrationInstructionsTwoTone';

const Filters = {
    Executions: {
        Status: [
            {
                label: 'Succeeded',
                value: 'Succeeded',
                color: 'success',
                icon: <CheckIcon />
            },
            {
                label: 'Failed',
                value: 'Failed',
                color: 'error',
                icon: <ClearIcon />
            }
        ]
    },
    Jobs: {
        Status: [
            {
                label: 'Enabled',
                value: 'ENABLED',
                color: 'success',
                icon: <CheckIcon />
            },
            {
                label: 'Disabled',
                value: 'DISABLED',
                color: 'neutral',
                icon: <DoNotDisturbIcon />
            }
        ],
        SQLServer: [
            {
                label: 'Source',
                value: 'source',
                color: 'neutral',
                icon: <StorageTwoToneIcon />
            },
            {
                label: 'Target',
                value: 'target',
                color: 'neutral',
                icon: <StorageTwoToneIcon />
            }
        ]
    },
    Connections: {
        type: [
            {
                label: 'API',
                value: 'api',
                color: 'primary',
                icon: <IntegrationInstructionsTwoToneIcon color="primary" />
            },
            {
                label: 'Browser',
                value: 'browser',
                color: 'browser',
                icon: <WebTwoToneIcon color="browser" />
            },
            {
                label: 'SFTP',
                value: 'sftp',
                color: 'secondary',
                icon: <DriveFolderUploadTwoToneIcon color="secondary" />
            },
            {
                label: 'SQL Server',
                value: 'sqlserver',
                color: 'neutral',
                icon: <StorageTwoToneIcon color="neutral" />
            }
        ]
    },
    Schemas: {
        type: [
            {
                label: 'System',
                value: 'system',
                color: 'neutral',
                icon: <ComputerIcon color="neutral" />
            },
            {
                label: 'User',
                value: 'user',
                color: 'primary',
                icon: <AccountCircleIcon color="primary" />
            }
        ]
    },
    SchemaRows: {
        column_type: [
            {
                label: 'string',
                value: 'string',
                color: 'neutral',
                // icon: <WebTwoToneIcon />
            },
            {
                label: 'integer',
                value: 'integer',
                color: 'neutral',
                // icon: <SettingsEthernetTwoToneIcon />
            },
            {
                label: 'float',
                value: 'float',
                color: 'neutral',
                // icon: <WebTwoToneIcon />
            },
            {
                label: 'date',
                value: 'date',
                color: 'neutral',
                // icon: <WebTwoToneIcon />
            },
            {
                label: 'datetime',
                value: 'datetime',
                color: 'neutral',
                // icon: <DriveFolderUploadTwoToneIcon />
            },
            {
                label: 'boolean',
                value: 'boolean',
                color: 'neutral',
                // icon: <WebTwoToneIcon />
            }
        ],
        primary_key: [
            {
                label: 'primary key',
                value: true,
                color: 'neutral',
                // icon: <DriveFolderUploadTwoToneIcon />
            }
        ]
    },
    Users: {
        Role: [
            {
                label: 'Admin',
                value: 'Admin',
                color: 'primary',
                icon: <VerifiedUserIcon color="primary" />
            },
            {
                label: 'User',
                value: 'User',
                color: 'secondary',
                icon: <PersonIcon color="secondary" />
            },
            {
                label: 'Viewer',
                value: 'Viewer',
                color: 'neutral',
                icon: <VisibilityIcon />
            },
        ]
    },
    Reports: {
        type: [{}]
    },
    Queries: {
        // type: [{}]
    }
}
export default Filters;