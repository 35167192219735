import React, { useState, useEffect } from 'react';
import { Grid, Box, Button, Tab, Tabs, Badge } from '@mui/material';
import ReportTable from './ReportTable';
import FormSQLEditor from '../Form/FormSQLEditor';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const ReportTabs = props => {
    const [tab, setTab] = useState(
        (window.location.hash === '#data') ? 1 : 0
    );
    const [data, setData] = useState(props.data);

    useEffect(() => {
        setData(props.data)
    }, [props]);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };
    console.log(data)

    return (
        <Grid container spacing={2}>

            <Grid item xs={12} style={{ display: 'flex' }}>
                <Tabs
                    indicatorColor="primary"
                    value={tab}
                    onChange={handleTabChange}
                >
                    <Tab label="Query" />
                    <Tab label={`Data ${data.data.length > 0 && "(" + data.data.length + ")"}`} />
                </Tabs>
            </Grid>

            <Grid item xs={12}>
                {tab == 0 &&
                    <div>
                        {/* <Button onClick={props.run} startIcon={<PlayArrowIcon />}>Run</Button> */}
                        <FormSQLEditor
                            query={data.query || ''}
                            handler={props.handler}
                        />

                    </div>
                }
                {tab == 1 &&
                    <ReportTable
                        data={data.data}
                        id={props.id}
                    />

                }

            </Grid>

        </Grid>
    );
}

export default ReportTabs;