import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';
import PowerTwoToneIcon from '@mui/icons-material/PowerTwoTone';

const ButtonTest = props => (
    <Button
        onClick={props.handler}
        size="small"
        disabled={props.disabled}
        sx={{ textAlign: 'left' }}
    >
        <PowerTwoToneIcon />
        <Typography sx={{ minWidth: '24px', textAlign: 'center', fontSize: '0.825rem', fontWeight: 500 }}>{props.value}</Typography>
    </Button>
)

export default ButtonTest