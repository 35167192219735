import { configureStore, combineReducers } from '@reduxjs/toolkit';
import connectionsReducer from './connectionsSlice';
import executionsReducer from './executionsSlice';
import jobsReducer from './jobsSlice';
import modalReducer from './modalSlice';
import queriesReducer from './queriesSlice';
import reportsReducer from './reportsSlice';
import resultsReducer from './resultsSlice';
import schemasReducer from './schemasSlice';
import snackbarReducer from './snackbarSlice';
import usersReducer from './usersSlice';

import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  connections: connectionsReducer,
  executions: executionsReducer,
  jobs: jobsReducer,
  modal: modalReducer,
  queries: queriesReducer,
  results: resultsReducer,
  reports: reportsReducer,
  schemas: schemasReducer,
  snackbar: snackbarReducer,
  users: usersReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
})

export const persistor = persistStore(store)
